import React, { useEffect, useState } from "react";
import Button from "../Button";
import { LuBook } from "react-icons/lu";
import { MdOutlineReviews } from "react-icons/md";
import { RiGraduationCapLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import heart from "../../assets/heart.png";
import event_shap from "../../assets/event_shape.png";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { MyAPI, truncateText } from "../../utils/MyAPI";
import { toast } from "react-toastify";

const Mentor = ({ cardNum }) => {
  const navigate = useNavigate();
  const [allTeachers, setAllTeachers] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const fetchTeachers = async () => {
    try {
      setDataLoading(true);
      let res = await MyAPI.GET(`/home/teachers?&page=1&limit=20`);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setAllTeachers(apiData.teachers);
      } else {
        toast.error(message || "Error fetching teachers.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  return (
    <div className="mentor p-md-5 p-3">
      <div className="row">
        <div className="col-md-3 pe-md-5 px-md-0 px-3 col1_mentor">
          <div className="badch_c mb-3">
            <p className="m-0 py-1 px-3">Meet Our Mentors</p>
          </div>
          <h4 className="fw-bold">Meet Our Mentors</h4>
          <p>unlock personalized Brilliance: guided by the best teachers.</p>
          <div className="mt-4" onClick={() => navigate("/find/mentor")}>
            <Button text="Find My Teacher" />
          </div>
        </div>
        <div className="col-md-9 mt-md-0 mt-5 col2_mentor">
          <div className="event_shap">
            <img src={event_shap} alt="" />
          </div>
          <div className="back rounded-3">
            <div className="row">
              <Swiper
                slidesPerView={cardNum && window.innerWidth > 768 ? cardNum : 1}
                spaceBetween={cardNum === 2 ? 40 : 20}
                modules={[Navigation, Autoplay]}
                grabCursor={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5 px-0 pe-5"
              >
                {!dataLoading &&
                  allTeachers &&
                  allTeachers.length > 0 &&
                  allTeachers.map((teacher, index) => (
                    <SwiperSlide key={index}>
                      <Card
                        key={index}
                        name={teacher?.visibleName || teacher?.firstName}
                        about={teacher.about}
                        image={teacher.image_key}
                        redirect={`/teacher/profile/${teacher._id}`}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentor;

const Card = ({ name, image, about, redirect }) => {
  const navigate = useNavigate();
  return (
    <Link to={redirect} className="card p-3 rounded-3 overflow-hidden">
      <div className="d-flex gap-2">
        <div className="user_image">
          <img
            className="border"
            src={
              image ||
              "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
            }
            alt={name}
          />
        </div>
        <div className="d-flex flex-column">
          <h6 className="mb-1">{name}</h6>
          <span>
            <LuBook /> IIT Preparation Crash Course
          </span>
          <span>
            <MdOutlineReviews /> 39 Review ( 4.7 )
          </span>
          <span>
            <RiGraduationCapLine /> 62 Students
          </span>
          <span></span>
        </div>
      </div>
      <p className="m-0 mt-3">{truncateText(about, 20)}</p>

      <div className="heart">
        <img src={heart} alt="" />
      </div>
    </Link>
  );
};
