import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const ParentPaymentGraph = ({ paymentData, isTeacher }) => {
  const [viewOption, setViewOption] = useState("weekly");

  useEffect(() => {
    if (isTeacher) {
      setViewOption("monthly");
    } else {
      setViewOption("weekly");
    }
  }, [isTeacher]);

  useEffect(() => {
    const chartDom = document.getElementById("payment-chart");
    const myChart = echarts.init(chartDom);

    // Dummy data for demonstration
    const weeklyData = [500, 700, 400, 600, 800, 900, 1000]; // Example weekly payment amounts
    const monthlyData = [
      1500, 1800, 2000, 2500, 2200, 2100, 2300, 2400, 2600, 2700, 2800, 2900,
    ]; // Example monthly payment amounts
    const yearlyData = [10000, 12000, 15000, 18000, 20000]; // Example yearly payment amounts

    // Function to generate xAxis data based on view option
    const generateXAxisData = (option) => {
      switch (option) {
        case "weekly":
          return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        case "monthly":
          return Array.from(
            { length: monthlyData.length },
            (_, i) => `Month ${i + 1}`
          );
        case "yearly":
          return Array.from(
            { length: yearlyData.length },
            (_, i) => `Year ${i + 1}`
          );
        default:
          return [];
      }
    };

    const xAxisData = generateXAxisData(viewOption);
    const seriesData = getSeriesData(viewOption);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ["Total Payment"],
      },
      xAxis: [
        {
          type: "category",
          data: xAxisData,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Payment Amount",
          axisLabel: {
            formatter: "{value} $",
          },
        },
      ],
      series: [
        {
          name: "Total Payment",
          type: "bar",
          data: seriesData,
        },
      ],
    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose(); // Dispose chart instance on unmount
    };
  }, [viewOption]);

  const getSeriesData = (option) => {
    switch (option) {
      case "weekly":
        return [500, 700, 400, 600, 800, 900, 1000];
      case "monthly":
        return [
          1500, 1800, 2000, 2500, 2200, 2100, 2300, 2400, 2600, 2700, 2800,
          2900,
        ];
      case "yearly":
        return [10000, 12000, 15000, 18000, 20000];
      default:
        return [];
    }
  };

  const handleViewChange = (e) => {
    setViewOption(e.target.value);
  };

  return (
    <div>
      <div className="view-options ps-3">
        <select value={viewOption} onChange={handleViewChange}>
          {!isTeacher && <option value="weekly">Weekly</option>}
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <div id="payment-chart" style={{ height: "400px" }}></div>
    </div>
  );
};

export default ParentPaymentGraph;
