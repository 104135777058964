import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { MyAPI } from '../../utils/MyAPI';
import { useSelector } from 'react-redux';

const MemberAddModal = ({ show, searchScop, groupId, setShow }) => {
    const token = useSelector((state) => state.token.value)
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])  // Store selected users as array of objects

    // Fetch users based on search criteria
    const fetchUsers = async (searchScop, search, token) => {
        try {
            let res = await MyAPI.GET(`/chat/users/${searchScop}?keyword=${search}`, token)
            let { res_type, message, data } = res.data || res;
            if (res_type === 'success') {
                setUsers([])
                setUsers(data)
            } else {
                toast.error(message || 'Error - user fetching..!')
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    // Add selected member to the group
    const handleAddMember = async () => {
        try {
            setLoadingBtn(true)

            const members = selectedUsers.map(user => ({
                member_id: user._id,
                member_role: searchScop  // Assigning member_role based on searchScop
            }))

            let res = await MyAPI.PATCH(`/chat/group?id=${groupId}`, {
                // "member_role": searchScop,
                "action": "ADD",
                "members": members  // Members array in the correct format
            }, token)

            let { res_type, message, data } = res.data || res;
            if (res_type === 'success') {
                toast.success('Member Added.')
                setSelectedUsers([])  // Reset selected users
                setShow(false)
            } else {
                toast.error(message || 'Error - Member Not Added.')
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoadingBtn(false)
        }
    }

    // Fetch users when search term or search scope changes
    useEffect(() => {
        setUsers([])
        if (searchScop && search && token) {
            fetchUsers(searchScop, search, token)
        }
    }, [searchScop, search, token])

    // Handle checkbox click to select/deselect users
    const handleCheckboxChange = (user) => {
        setSelectedUsers(prevSelected => {
            const isSelected = prevSelected.find(selected => selected._id === user._id)

            if (isSelected) {
                // If already selected, remove it from the array
                return prevSelected.filter(selected => selected._id !== user._id)
            } else {
                // Otherwise, add it to the array with member_role
                return [...prevSelected, { _id: user._id, member_role: searchScop }]
            }
        });
    };

    return (
        <Modal className='mt-3' show={show} onHide={() => setShow(false)} centered>
            <Modal.Body>
                <Col md={12} className="forward-message-container px-3 py-3">
                    <div className="forward-message-header">
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <FaSearch />
                            </InputGroup.Text>
                            <FormControl
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                placeholder={`Search for ${searchScop.toLowerCase()}...`} // Corrected method
                            />
                            <Button variant="light" className="border" onClick={() => setShow(false)}>
                                <FaTimes />
                            </Button>
                        </InputGroup>
                    </div>
                    <div className="forward-message-content" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <div className="user-list">
                            {
                                users && users.length === 0 && (
                                    <p>No Users Found.</p>
                                )
                            }
                            {users && users.length > 0 && users.map((user, index) => (
                                <div key={index} className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                                    <div className="user-details">
                                        <img
                                            width={35}
                                            height={35}
                                            src="https://t3.ftcdn.net/jpg/05/87/76/66/360_F_587766653_PkBNyGx7mQh9l1XXPtCAq1lBgOsLl6xH.jpg" // Replace with actual user image URL
                                            alt="User"
                                            className="user-image rounded-5 border"
                                        />
                                        <span className="user-name ms-3 text-bold">{user.name || user.visibleName || user.firstName}</span> {/* Dynamic username */}
                                    </div>
                                    <input
                                        type="checkbox"
                                        className="user-checkbox"
                                        checked={!!selectedUsers.find(selected => selected._id === user._id)}  // Checkbox reflects the state
                                        onChange={() => handleCheckboxChange(user)}  // Toggle selected users
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        disabled={selectedUsers.length === 0 || loadingBtn}  // Disable if no user selected or loading
                        className="new-button mt-3"
                        onClick={handleAddMember}
                    >
                        {loadingBtn ? 'Adding...' : 'Add'}
                    </button>
                </Col>
            </Modal.Body>
        </Modal>
    );
};

export default MemberAddModal;
