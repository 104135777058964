import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Form, Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import axios from "../../utils/axios.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RegistrationNext = () => {
  const [validated, setValidated] = useState(false);
  const [reffredBy, setReffredBy] = useState(null);
  const [comfortableLanguages, setComfortableLanguages] = useState([]);
  const [facebookLink, setFacebookLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [veryficationDocs, setVeryficationDocs] = useState({
    name: "",
    file: null,
  });
  const [workingWithAcademy, setWorkingWithAcademy] = useState(false);
  const [haveLaptop, setHaveLaptop] = useState(false);
  const [havePentab, setHavePentab] = useState(false);
  const [haveStableWifi, setHaveStableWifi] = useState(false);
  const [goodWithEnglish, setGoodWithEnglish] = useState(false);
  const [taughtInternationalStudents, setTaughtInternationalStudents] =
    useState(false);
  const [numberOfStudentsTaught, setNumberOfStudentsTaught] = useState(null);
  const [understood, setUnderstood] = useState(false);
  const [haveOwnNotes, setHaveOwnNotes] = useState(false);
  const [willingToMakePpts, setWillingToMakePpts] = useState(false);
  const [willingToProvideHwAndTests, setWillingToProvideHwAndTests] =
    useState(false);
  const [willingToTakePtm, setWillingToTakePtm] = useState(false);

  const [certificates, setCertificates] = useState([
    {
      certificate: null,
      name: "",
    },
  ]);
  const token = useSelector((state) => state.token.value);
  const [teacherData, setTeacherData] = useState({});
  const [allBoards, setAllBoards] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [allExams, setAllExams] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [allQualifications, setAllQualifications] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const { data } = await axios.get("/teacher/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.res_type === "success") {
          setTeacherData(data.data.teacher);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeacherData();
  }, []);

  useEffect(() => {
    if (teacherData?.status === "sign-up") {
      navigate("/teacher/registration");
    } else if (teacherData?.status === "reg-one") {
      navigate("/teacher/registration-next");
    } else if (teacherData?.status === "reg-two") {
      navigate("/teacher/dashboard/pages/under-review");
    }
  }, [teacherData?.status, navigate]);

  const fetchRecords = async () => {
    try {
      const board = await axios.get(
        `/common/records?title=&record_type=board&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const course = await axios.get(
        `/common/records?title=&record_type=course&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const classes = await axios.get(
        `/common/records?title=&record_type=class&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const exam = await axios.get(
        `/common/records?title=&record_type=exam&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const subjects = await axios.get(
        `/common/records?title=&record_type=subject&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const qualifications = await axios.get(
        `/common/records?title=&record_type=qualification&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const languages = await axios.get(
        `/common/records?title=&record_type=languages&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const categories = await axios.get(
        `/common/records?title=&record_type=category&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const grades = await axios.get(
        `/common/records?title=&record_type=grade&status=active`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (board.data.res_type === "success") {
        setAllBoards(board.data.data);
      }

      if (course.data.res_type === "success") {
        setAllCourses(course.data.data);
      }

      if (classes.data.res_type === "success") {
        setAllClasses(classes.data.data);
      }

      if (exam.data.res_type === "success") {
        setAllExams(exam.data.data);
      }

      if (subjects.data.res_type === "success") {
        setAllSubjects(subjects.data.data);
      }

      if (qualifications.data.res_type === "success") {
        setAllQualifications(qualifications.data.data);
      }

      if (languages.data.res_type === "success") {
        setAllLanguages(languages.data.data);
      }

      if (categories.data.res_type === "success") {
        setAllCategories(categories.data.data);
      }

      if (grades.data.res_type === "success") {
        setAllGrades(grades.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [token]);

  const [qualifications, setQualifications] = useState([
    {
      level: "",
      degreeName: "",
      passingYear: "",
      instituteName: "",
      percentage: "",
      certificate: null,
    },
  ]);

  const handleAddQualification = () => {
    setQualifications([
      ...qualifications,
      {
        level: "",
        degreeName: "",
        passingYear: "",
        instituteName: "",
        percentage: "",
        certificate: null,
      },
    ]);
  };

  const handleRemoveQualification = (index) => {
    const newQualifications = qualifications.filter((_, i) => i !== index);
    setQualifications(newQualifications);
  };

  // handle change for qualification form with file

  const handleChange = (index, event) => {
    const { name, value, files } = event.target;
    const newQualifications = qualifications.map((qualification, i) =>
      i === index
        ? { ...qualification, [name]: files ? files[0] : value }
        : qualification
    );
    setQualifications(newQualifications);
  };

  const [subjects, setSubjects] = useState([
    { grade: "", board: "", subject: "" },
  ]);

  const [achievements, setAchievements] = useState([
    { achievement: "", certificate: null },
  ]);

  const handleAddSubject = () => {
    setSubjects([...subjects, { grade: "", board: "", subject: "" }]);
  };

  const handleAddCertificate = (e) => {
    e.preventDefault();
    setCertificates([
      ...certificates,
      {
        certificate: null,
        name: "",
      },
    ]);
  };

  const handleAddAchievement = (e) => {
    e.preventDefault();
    setAchievements([...achievements, { achievement: "", certificate: null }]);
  };

  const handleRemoveSubject = (index) => {
    const newSubjects = subjects.filter((_, i) => i !== index);
    setSubjects(newSubjects);
  };

  const handleRemoveCertificate = (index) => {
    const newCertificates = certificates.filter((_, i) => i !== index);
    setCertificates(newCertificates);
  };

  const handleRemoveAchievement = (index) => {
    const newAchievements = achievements.filter((_, i) => i !== index);
    setAchievements(newAchievements);
  };

  const handleChangeSubject = (index, event) => {
    const { name, value } = event.target;
    const newSubjects = subjects.map((subject, i) =>
      i === index ? { ...subject, [name]: value } : subject
    );
    setSubjects(newSubjects);
  };

  const [schools, setSchools] = useState([
    {
      schoolName: "",
      designation: "",
      subject: "",
      grade: "",
      board: "",
      starYear: "",
      endYear: "",
      certificate: null,
    },
  ]);

  const handleAddSchool = () => {
    setSchools([
      ...schools,
      {
        schoolName: "",
        designation: "",
        subject: "",
        grade: "",
        board: "",
        starYear: "",
        endYear: "",
        certificate: null,
      },
    ]);
  };

  const handleRemoveSchool = (index) => {
    const newSchools = schools.filter((_, i) => i !== index);
    setSchools(newSchools);
  };

  const handleChangeSchool = (index, event) => {
    const { name, value, files } = event.target;
    const newSchools = schools.map((school, i) =>
      i === index
        ? {
            ...school,
            [name]: files ? files[0] : value,
          }
        : school
    );
    setSchools(newSchools);

    // Preview file (image)
    if (files && files[0] && name === "certificate") {
      const reader = new FileReader();
      reader.onload = () => {
        const previewImage = reader.result;
        setImage(previewImage);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleChangeAchievement = (index, event) => {
    const { name, value, files } = event.target;
    const newAchievement = achievements.map((ach, i) =>
      i === index
        ? {
            ...ach,
            [name]: files ? files[0] : value,
          }
        : ach
    );
    setAchievements(newAchievement);
  };

  const handleChangeCertificate = (index, event) => {
    const { name, value, files } = event.target;
    const newCertificate = certificates.map((cer, i) =>
      i === index
        ? {
            ...cer,
            [name]: files ? files[0] : value,
          }
        : cer
    );
    setCertificates(newCertificate);
  };

  const handleAddComfortableLanguages = (event) => {
    // check that if checkbox is checked then add languages in state in form of array
    if (event.target.checked) {
      setComfortableLanguages([
        ...comfortableLanguages,
        event.target.nextSibling.textContent,
      ]);
    } else {
      // if checkbox is unchecked then remove language from state
      const newLanguages = comfortableLanguages.filter(
        (language) => language !== event.target.nextSibling.textContent
      );
      setComfortableLanguages(newLanguages);
    }
  };

  const handleVeryficationDocsChange = (event) => {
    setVeryficationDocs({
      ...veryficationDocs,
      file: event.target.files[0],
    });
  };

  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [viewButtonVisible, setViewButtonVisible] = useState(false);

  const handlePreviewImage = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setViewButtonVisible(true);
    }
  };

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();

    qualifications.forEach((qualification, index) => {
      formData.append(`qualifications[${index}][level]`, qualification.level);
      formData.append(
        `qualifications[${index}][degree_name]`,
        qualification.degreeName
      );
      formData.append(
        `qualifications[${index}][passing_year]`,
        qualification.passingYear
      );
      formData.append(
        `qualifications[${index}][institute_name]`,
        qualification.instituteName
      );
      formData.append(
        `qualifications[${index}][percentage]`,
        qualification.percentage
      );
      formData.append(`qualifications_certificates`, qualification.certificate);
    });

    subjects.forEach((subject, index) => {
      formData.append(`grades[${index}][grade]`, subject.grade);
      formData.append(`grades[${index}][board]`, subject.board);
      formData.append(`grades[${index}][subject]`, subject.subject);
    });

    schools.forEach((school, index) => {
      formData.append(`pre_schools[${index}][name]`, school.schoolName);
      formData.append(`pre_schools[${index}][designation]`, school.designation);
      formData.append(`pre_schools[${index}][subject]`, school.subject);
      formData.append(`pre_schools[${index}][grade]`, school.grade);
      formData.append(`pre_schools[${index}][board]`, school.board);
      formData.append(`pre_schools[${index}][start_year]`, school.starYear);
      formData.append(`pre_schools[${index}][end_year]`, school.endYear);
      formData.append(`pre_schools_certificates`, school.certificate);
    });

    achievements.forEach((achievement, index) => {
      formData.append(`achievements[${index}][title]`, achievement.achievement);
      formData.append(`achievements_certificates`, achievement.certificate);
    });

    certificates.forEach((certificate, index) => {
      formData.append(`certificates[${index}][title]`, certificate.name);
      formData.append(`certificates`, certificate.certificate);
    });

    comfortableLanguages.forEach((language, index) => {
      formData.append(`languages`, language);
    });

    formData.append("referred_by", reffredBy);
    formData.append("facebook_link", facebookLink);
    formData.append("linkedin_link", linkedinLink);
    formData.append("youtube_link", youtubeLink);
    formData.append("instagram_link", instagramLink);
    formData.append("document_for_verification_file", veryficationDocs.file);
    formData.append("document_for_verification_name", veryficationDocs.name);

    formData.append("is_working_in_academy", workingWithAcademy);
    formData.append("is_laptop", haveLaptop);
    formData.append("is_pentab", havePentab);
    formData.append("is_wifi", haveStableWifi);
    formData.append("is_good_english", goodWithEnglish);
    formData.append("is_taught_international", taughtInternationalStudents);
    formData.append("student_taught_before", 1000);
    formData.append("is_no_other_academy", understood);
    formData.append("is_notes", haveOwnNotes);
    formData.append("is_ppt", willingToMakePpts);
    formData.append("is_hw_test", willingToProvideHwAndTests);
    formData.append("is_ptm", willingToTakePtm);

    const sendData = async () => {
      try {
        setLoading(true);
        const res = await axios.put("/teacher/reg-two", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        // console.log(res.data);

        if (res.data.res_type === "success") {
          toast.success(res.data.message);
          navigate("/teacher/dashboard/pages/under-review");
        } else if (
          res.data.res_type === "bad_request" &&
          res.data.message === "Profile status embiquity"
        ) {
          toast.error("You have already submitted your profile");
        } else {
          toast.error("something went wrong");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    sendData();
  };

  return (
    <TeacherLayout>
      <h3>Professional details</h3>

      <div className="container mt-4 bg-white rounded-5 shadow py-4 px-3">
        <Form onSubmit={handleSubmit}>
          {qualifications.map((qualification, index) => (
            <div key={index} className="mb-4 position-relative">
              <h6>Qualification</h6>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId={`qualification-level-${index}`}>
                    <Form.Label>Level</Form.Label>
                    <Form.Control
                      as="select"
                      name="level"
                      value={qualification.level}
                      onChange={(e) => handleChange(index, e)}
                      required
                    >
                      <option value="">Select Level</option>
                      {allQualifications.map((quali, index) => (
                        <option key={index} value={quali?.title}>
                          {quali?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`degree-name-${index}`}>
                    <Form.Label>Degree Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="degreeName"
                      value={qualification.degreeName}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId={`passing-year-${index}`}>
                    <Form.Label>Passing Year</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="2010"
                      name="passingYear"
                      value={qualification.passingYear}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`institute-name-${index}`}>
                    <Form.Label>Institute Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="instituteName"
                      value={qualification.instituteName}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="fw-semibold">Percentage </Form.Label>
                    <Form.Control
                      type="number"
                      name="percentage"
                      placeholder="Percentage"
                      value={qualification.percentage}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="fw-semibold">
                      Upload Certificate{" "}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      required
                      name="certificate"
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <button
                onClick={() => handleRemoveQualification(index)}
                className="btn btn-sm btn-danger"
                type="button"
              >
                Remove
              </button>
            </div>
          ))}

          <Button size="sm" variant="primary" onClick={handleAddQualification}>
            Add Qualification
          </Button>
          <hr />
          {subjects.map((subject, index) => (
            <div key={index} className="mb-4">
              <h6>Grades You Can Teach</h6>
              <Row className="align-items-center mb-3">
                <Col>
                  <Form.Group controlId={`grade-${index}`}>
                    <Form.Label>Grade</Form.Label>
                    <Form.Control
                      as="select"
                      name="grade"
                      value={subject.grade}
                      onChange={(e) => handleChangeSubject(index, e)}
                      required
                    >
                      <option value="">Select Grade</option>
                      {allGrades.map((c, index) => (
                        <option key={index} value={c?.title}>
                          {c?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`board-${index}`}>
                    <Form.Label>Board</Form.Label>
                    <Form.Control
                      as="select"
                      name="board"
                      value={subject.board}
                      onChange={(e) => handleChangeSubject(index, e)}
                      required
                    >
                      <option value="">Select Board</option>
                      {allBoards.map((board, index) => (
                        <option key={index} value={board?.title}>
                          {board?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`subject-${index}`}>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      name="subject"
                      value={subject.subject}
                      onChange={(e) => handleChangeSubject(index, e)}
                      required
                    >
                      <option value="">Select Subject</option>
                      {allSubjects.map((s, index) => (
                        <option key={index} value={s?.title}>
                          {s?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    className="mt-4"
                    variant="danger"
                    onClick={() => handleRemoveSubject(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button size="sm" variant="primary" onClick={handleAddSubject}>
            Add Subject
          </Button>

          <hr />

          {schools.map((school, index) => (
            <div key={index} className="mb-4">
              <h6>Schools you have taught</h6>
              <Row className="align-items-center mb-3 mt-3">
                <Col md={4}>
                  <Form.Group controlId={`school-name-${index}`}>
                    <Form.Label>School Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="schoolName"
                      placeholder="Enter School Name..."
                      value={school.schoolName}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`designation-${index}`}>
                    <Form.Label>Your Designation</Form.Label>
                    <Form.Control
                      type="text"
                      name="designation"
                      placeholder="Enter School Name..."
                      value={school.designation}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`subject-${index}`}>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      name="subject"
                      value={school.subject}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    >
                      <option value="">Select Subject</option>
                      {allSubjects.map((s, index) => (
                        <option key={index} value={s?.title}>
                          {s?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col>
                  <Form.Group controlId={`grade-${index}`}>
                    <Form.Label>Grade</Form.Label>
                    <Form.Control
                      as="select"
                      name="grade"
                      value={school.grade}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    >
                      <option value="">Select Grade</option>
                      {allGrades.map((c, index) => (
                        <option key={index} value={c?.title}>
                          {c?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`board-${index}`}>
                    <Form.Label>Board</Form.Label>
                    <Form.Control
                      as="select"
                      name="board"
                      value={school.board}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    >
                      <option value="">Select Board</option>
                      {allBoards.map((board, index) => (
                        <option key={index} value={board?.title}>
                          {board?.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col md={4}>
                  <Form.Group controlId={`year-${index}`}>
                    <Form.Label>Start Year</Form.Label>
                    <Form.Control
                      type="text"
                      name="starYear"
                      placeholder="2020"
                      value={school.starYear}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-4">
                  <Form.Group controlId={`year-${index}`}>
                    <Form.Label>End Year</Form.Label>
                    <Form.Control
                      type="text"
                      name="endYear"
                      placeholder="2024"
                      value={school.endYear}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    />

                    {/* currently working */}
                    <Form.Check
                      className="mt-1"
                      type="checkbox"
                      label="Currently working"
                      name="year"
                      // value={school.year}
                      // onChange={(e) => handleChangeSchool(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`certificate-${index}`}>
                    <Form.Label>Certificate</Form.Label>
                    <Form.Control
                      type="file"
                      name="certificate"
                      // onChange={handleImageChange}
                      onChange={(e) => handleChangeSchool(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    className="mt-4"
                    variant="danger"
                    onClick={() => handleRemoveSchool(index)}
                  >
                    Remove
                  </Button>
                </Col>
                <Col xs="auto">
                  {viewButtonVisible && (
                    <Button
                      className="btn btn-sm btn-primary mt-4"
                      onClick={handlePreviewImage}
                    >
                      View
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ))}
          <Button size="sm" variant="primary" onClick={handleAddSchool}>
            Add School
          </Button>

          <hr />

          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="6"
              className="mt-3"
              controlId="validationCustom01"
            >
              <Form.Label className="fw-semibold">Referred by</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setReffredBy(e.target.value)}
                value={reffredBy}
                required
              >
                <option>Select</option>
                <option value="Parent">Parent</option>
                <option value="Teacher">Teacher</option>
                <option value="Social Media">Social Media</option>
                <option value="Online Marketing">Online Marketing</option>
                <option value="Offline Marketing">Offline Marketing</option>
                <option value="Google Search">Google Search</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              md="6"
              className="mt-3"
              controlId="validationCustom03"
            >
              <Form.Label className="fw-semibold">
                {" "}
                Languages you are comfortable with
              </Form.Label>
              <Dropdown className="d-flex align-items-center">
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    color: "black",
                  }}
                  className="w-100 border text-start d-flex justify-content-between align-items-center"
                >
                  Select
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ width: 230, position: "absolute" }}
                  className="px-3"
                >
                  {allLanguages.map((language, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center flex-shrink-0 mt-3"
                    >
                      <input
                        type="checkbox"
                        className="me-2 filter_check"
                        onChange={handleAddComfortableLanguages}
                      />
                      <label>{language?.title}</label>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Row>

          <>
            {achievements.map((achievement, index) => (
              <Row key={index} className="mb-3">
                <Form.Group
                  as={Col}
                  md="6"
                  className="mt-3"
                  controlId={`validationCustom03_${index}`}
                >
                  <Form.Label className="fw-semibold">
                    Awards/Special achievement {index + 1}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Awards/Special achievement"
                    required
                    name="achievement"
                    onChange={(e) => handleChangeAchievement(index, e)}
                    value={achievement.achievement}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  className="mt-3"
                  controlId={`validationCustom03_certificate_${index}`}
                >
                  <Form.Label className="fw-semibold">
                    Certificate For Awards/Special achievement {index + 1}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Awards/Special achievement"
                    required
                    name="certificate"
                    onChange={(e) => handleChangeAchievement(index, e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
                {index > 0 && (
                  <Col md={3} className="d-flex mt-3 align-items-center ">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRemoveAchievement(index)}
                    >
                      Remove Achievement
                    </button>
                  </Col>
                )}
              </Row>
            ))}
            <Col md={4} className="d-flex mt-2 align-items-center ">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleAddAchievement}
              >
                Add One More Achievement
              </button>
            </Col>
          </>

          <Row className="mb-3">
            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Facebook link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Facebook link"
                onChange={(e) => setFacebookLink(e.target.value)}
                value={facebookLink}
                required
              />
            </Form.Group>

            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Linkedin link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Linkedin link"
                onChange={(e) => setLinkedinLink(e.target.value)}
                value={linkedinLink}
                required
              />
            </Form.Group>

            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Youtube link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Youtube link"
                onChange={(e) => setYoutubeLink(e.target.value)}
                value={youtubeLink}
                required
              />
            </Form.Group>

            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Instagram link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Instagram link"
                onChange={(e) => setInstagramLink(e.target.value)}
                value={instagramLink}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="4"
              className="mt-3"
              controlId="validationCustom03"
            >
              <Form.Label className="fw-semibold">
                Choose Document For Verification
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) =>
                  setVeryficationDocs({
                    name: e.target.value,
                  })
                }
                value={veryficationDocs.name}
                required
              >
                <option value="">Select</option>
                <option value="Aadhar Card">Aadhar Card</option>
                <option value="PAN Card">PAN Card</option>
                <option value="Passport">Passport</option>
              </Form.Select>
            </Form.Group>

            {/* if user select for any docs then show file input */}

            {veryficationDocs && (
              <Form.Group as={Col} md="4" className="mt-3">
                <Form.Label className="fw-semibold">
                  Upload {veryficationDocs.name}
                </Form.Label>
                <Form.Control
                  type="file"
                  required
                  onChange={handleVeryficationDocsChange}
                />
              </Form.Group>
            )}
          </Row>

          <Row className="mb-3">
            {certificates.map((certificate, index) => (
              <>
                <Col md={6} key={index + certificates.length}>
                  <Form.Group className="mt-3 w-100">
                    <Form.Label className="fw-semibold">
                      Upload Certificate {index + 1}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Certificate Title"
                      name="name"
                      value={certificate.name}
                      onChange={(e) => handleChangeCertificate(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                >
                  <Form.Group className="mt-3 w-100">
                    <Form.Label className="fw-semibold">
                      Upload Certificate {index + 1}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      required
                      name="certificate"
                      onChange={(e) => handleChangeCertificate(index, e)}
                    />
                  </Form.Group>

                  {index > 0 && (
                    <button
                      className="btn btn-sm btn-danger ms-2 mt-5"
                      onClick={() => handleRemoveCertificate(index)}
                    >
                      Remove
                    </button>
                  )}
                </Col>
              </>
            ))}

            <Col md={4}>
              <button
                className="btn btn-sm btn-primary mt-5"
                onClick={handleAddCertificate}
              >
                Add One More Certificate
              </button>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group className="mb-3 mt-3">
                <Form.Check
                  label="Working in academy ? (we don't accept teacher with another Academy )"
                  onChange={(e) => setWorkingWithAcademy(e.target.checked)}
                  value={workingWithAcademy}
                />
              </Form.Group>
              {workingWithAcademy && (
                <Form.Group className="mt-3">
                  <Form.Label className="fw-semibold">
                    Working in academy
                  </Form.Label>
                  <Form.Control type="text" placeholder="Working in academy" />
                </Form.Group>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="have laptop ?"
                onChange={(e) => setHaveLaptop(e.target.checked)}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="have pentab ?"
                onChange={(e) => setHavePentab(e.target.checked)}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="have stable wifi connection ?"
                onChange={(e) => setHaveStableWifi(e.target.checked)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-4">
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="are you good with English ?"
                onChange={(e) => setGoodWithEnglish(e.target.checked)}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="have you taught international students before ?"
                onChange={(e) =>
                  setTaughtInternationalStudents(e.target.checked)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="number of students taught before ?"
                onChange={(e) => setNumberOfStudentsTaught(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-4">
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="Whether you understood that you cannot join another academic program."
                onChange={(e) => setUnderstood(e.target.checked)}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="do you have you own notes ?"
                onChange={(e) => setHaveOwnNotes(e.target.checked)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-4">
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="are you willing to make ppts to teach child ?"
                onChange={(e) => setWillingToMakePpts(e.target.checked)}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="are you willing to provide hw and tests ?"
                onChange={(e) =>
                  setWillingToProvideHwAndTests(e.target.checked)
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                label="are you willing to take ptm ?"
                onChange={(e) => setWillingToTakePtm(e.target.checked)}
              />
            </Form.Group>
          </Row>
          <button
            onClick={() => navigate("/teacher/registration")}
            className="border-0 bg-yellow px-4 py-2 rounded-3 me-3 mt-3 text-white"
            type="submit"
          >
            Back
          </button>
          <button
            className="border-0 bg-yellow px-4 py-2 rounded-3 mt-3 text-white"
            type="submit"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </Form>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {image && <img src={image} alt="preview" style={{ width: "100%" }} />}
        </Modal.Body>
      </Modal>
    </TeacherLayout>
  );
};

export default RegistrationNext;
