import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button, Chip } from "@mui/material";
import { MdOutlineFileDownload, MdReplyAll } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
import moment from "moment";
const ProfilePic = require("../../assets/parent/profile-user.jpg");

const ComplaintPage = (props) => {
  const [complaints, setComplaints] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [complaintId, setComplaintId] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [admin, setAdmin] = useState({});
  const [loading1, setLoading1] = useState(false);
  const token = useSelector((state) => state.token.value);

  const fetchComplaints = async () => {
    try {
      const res = await axios.get(
        `/complaint/?keyword=&status=${status}&complaint_type=${complaintType}&recieved=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.res_type === "success") {
        setComplaints(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("Error fetching complaints", error);
      toast.error(error.response.data.message);
    }
  };

  const fetchAdmin = async () => {
    try {
      const res = await axios.get("/chat/users/ADMIN", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.res_type === "success") {
        setAdmin(res.data.data[0]);
      }
    } catch (error) {
      console.log("Error fetching admin", error);
    }
  };

  useEffect(() => {
    fetchComplaints();
  }, [token, status, complaintType]);

  useEffect(() => {
    fetchAdmin();
  }, [token]);

  // console.log("Complaints", complaints);
  // console.log("Admin", admin);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleShowModal = (compaintId) => {
    setComplaintId(compaintId);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleRaiseComplaint = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("description", description);
      formData.append("attachment", file);

      const res = await axios.patch(
        `/complaint/reply/${complaintId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("Raise Complaint", res.data);

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        fetchComplaints();
        handleCloseModal();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("Error raising complaint", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForwardToAdmin = async (complainID) => {
    try {
      setLoading1(true);

      const res = await axios.patch(
        `/complaint/forward/${complainID}`,
        {
          respondents: [
            {
              id: admin._id,
              role: "ADMIN",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("Forward to Admin", res.data);

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        fetchComplaints();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("Error forwarding to admin", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading1(false);
    }
  };

  return (
    <OperationLayout>
      <h5>Complain</h5>
      {/* <OperationLocation path={history} /> */}

      <Col className="d-flex align-items-center justify-content-between">
        <Col md={3}>
          <Col>
            <Form.Group controlId="selectOption">
              <Form.Select
                className="shadow rounded-5 mt-3"
                onChange={(e) => setComplaintType(e.target.value)}
              >
                <option value="">Complaint type</option>
                <option value="Teacher Related">Teacher Related</option>
                <option value="Previous Class Related">
                  Previous Class Related
                </option>
                <option value="Live Related">Live Related</option>
                <option value="Technical Issue">Technical Issue</option>
                <option value="Offer and Coupons">Offer and Coupons</option>
                <option value="Learniee mall">Learniee mall</option>
                <option value="General Faq">General Faq</option>
                <option value="Account Related">Account Related</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Col>
        <Col className="d-flex align-items-center justify-content-end" md={6}>
          <Form.Group controlId="selectOption">
            <Form.Select
              className="shadow rounded-5 mt-3"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Filter</option>
              <option value="open">Open</option>
              <option value="close">Closed</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Col>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply Complaint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="complaintDescription">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="complaintfILE" className="mt-3">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white border-0"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            variant="outlined"
            onClick={handleRaiseComplaint}
          >
            {loading ? "Loading..." : "Reply"}
          </Button>
        </Modal.Footer>
      </Modal>

      {complaints && complaints.length > 0 ? (
        complaints.map((complaint, index) => (
          <Col key={index} className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
            <Row className="p-2 m-0">
              <Col
                md={4}
                className="d-flex align-items-center justify-content-start p-0 m-0"
                style={{ gap: "10px" }}
              >
                {" "}
                <div className="parent-complain-image">
                  <img src={ProfilePic} alt="userImg" />{" "}
                </div>{" "}
                <div>
                  <h5>{complaint?.complainant_model}</h5>
                  {/* <p>help#90818mnjn</p> */}
                </div>{" "}
              </Col>
              <Col
                md={8}
                className="d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive"
                style={{ fontSize: "18px" }}
              >
                Subject: {complaint?.title}
              </Col>
            </Row>
            <Col className="d-flex align-items-center justify-content-between p-2">
              <Col md={6}>
                {moment(complaint?.created_at).format("DD MMM YYYY")}
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-end"
              >
                {complaint?.status === "open" ? (
                  <>
                    <button
                      onClick={() => handleForwardToAdmin(complaint?._id)}
                      className="btn rounded-5 me-3"
                      style={{
                        background: "var(--color-primary)",
                        color: "white",
                        borderColor: "var(--color-primary)",
                        borderRadius: "25px",
                      }}
                    >
                      {loading1 ? "Loading..." : "Forward to Admin"}
                    </button>

                    <Button
                      onClick={() => handleShowModal(complaint?._id)}
                      className="me-3 rounded-5 shadow"
                      variant="outlined"
                      startIcon={<MdReplyAll />}
                    >
                      Reply
                    </Button>
                    <Chip
                      label="Open"
                      className="n-box-shadow"
                      style={{
                        color: "black",
                        borderColor: "yellow",
                        background: "yellow",
                      }}
                      color="primary"
                    />
                  </>
                ) : (
                  <Chip
                    label="Close"
                    className="n-box-shadow"
                    style={{
                      color: "white",
                      borderColor: "yellow",
                      background: "red",
                    }}
                    color="primary"
                  />
                )}
              </Col>
            </Col>
            <hr />
            <Col className="p-2">{complaint?.description}</Col>

            {complaint?.attachment_key && (
              <>
                <Col className="mt-3">
                  <h6>Attachment</h6>
                </Col>
                <Row>
                  <a href={complaint?.attachment_key} download="Attachment Key">
                    <Col md={3} style={{ padding: "10px" }}>
                      <div className="resorce-parent-item cursor-pointer">
                        <div className="name">
                          <FaFilePdf /> HC Verma
                        </div>
                        <MdOutlineFileDownload />
                      </div>
                    </Col>
                  </a>
                </Row>
              </>
            )}

            {complaint?.replies.length > 0 && (
              <>
                <Col className="mt-3">
                  <h4 className="mb-2">Replies</h4>

                  {complaint?.replies.map((reply, index) => (
                    <div key={index} className="px-3 mt-3 border-top pt-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>Replier Id: {reply?.replier_id}</h6>
                        <p className="m-0">
                          {moment(reply?.date_time).format("DD MMM YYYY")}
                        </p>
                      </div>
                      <p>{reply?.description}</p>

                      {reply?.attachment_key && (
                        <Row>
                          <a
                            href={reply?.attachment_key}
                            download="Attachment Key"
                          >
                            <Col md={3} style={{ padding: "10px" }}>
                              <div className="resorce-parent-item cursor-pointer">
                                <div className="name">
                                  <FaFilePdf /> Attachment
                                </div>
                                <MdOutlineFileDownload />
                              </div>
                            </Col>
                          </a>
                        </Row>
                      )}
                    </div>
                  ))}
                </Col>
              </>
            )}
          </Col>
        ))
      ) : (
        <h4 className="mt-4 text-center">No Complaints Found</h4>
      )}
    </OperationLayout>
  );
};

export default ComplaintPage;
