import React, { useEffect, useState } from "react";
import { IoMdTime } from "react-icons/io";
import { IoSchoolOutline, IoStar } from "react-icons/io5";
import { TiBook } from "react-icons/ti";
import { FiHeart } from "react-icons/fi";
import Navbar from "../components/Navbar";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { baseAwsUrl } from "../utils/MyAPI";

const CourcePage = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedCourseType, setSelectedCourseType] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/home/courses?page=&limit=&category=${selectedCategory}&subject=${selectedSubject}&grade=${selectedGrade}&board=${selectedBoard}&course_type=${selectedCourseType}&language=${selectedLanguage}&status=${selectedStatus}&min_price=${selectedMinPrice}&max_price=${selectedMaxPrice}`
      );

      if (res.data.res_type === "success") {
        setCourses(res.data.data.courses);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [
    selectedCategory,
    selectedSubject,
    selectedGrade,
    selectedBoard,
    selectedCourseType,
    selectedLanguage,
    selectedStatus,
    selectedMinPrice,
    selectedMaxPrice,
  ]);

  const handlePriceChange = (e, price) => {
    if (e.target.checked) {
      if (price === "Any") {
        setSelectedMinPrice("");
        setSelectedMaxPrice("");
      } else if (price === "₹834 or less") {
        setSelectedMinPrice(0);
        setSelectedMaxPrice(834);
      } else if (price === "₹1,668 or less") {
        setSelectedMinPrice(835);
        setSelectedMaxPrice(1668);
      } else if (price === "₹2,503 or less") {
        setSelectedMinPrice(1669);
        setSelectedMaxPrice(2503);
      } else if (price === "₹3,337 or less") {
        setSelectedMinPrice(2504);
        setSelectedMaxPrice(3337);
      } else if (price === "More than ₹3,337") {
        setSelectedMinPrice(3338);
        setSelectedMaxPrice("");
      }
    } else {
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
    }
  };

  const handleResetFilters = () => {
    setSelectedCategory("");
    setSelectedSubject("");
    setSelectedGrade("");
    setSelectedBoard("");
    setSelectedCourseType("");
    setSelectedLanguage("");
    setSelectedStatus("");
    setSelectedMinPrice("");
    setSelectedMaxPrice("");

    // Reset checkboxes
    const checkboxes = document.querySelectorAll(".filter_check");
    checkboxes.forEach((checkbox) => (checkbox.checked = false));
    const checkboxes2 = document.querySelectorAll(".filter_check2");
    checkboxes2.forEach((checkbox) => (checkbox.checked = false));
  };

  console.log(courses);

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <div style={{ overflowX: "hidden" }}>
        <Container
          fluid
          className="d-flex align-items-center"
          style={{
            height: "40vh",
            background: "linear-gradient(90deg, #f0ecfa, #f7f7f9, #f7ebf9)",
          }}
        >
          <div className="top_bar_help d-flex flex-column justify-content-center px-5">
            <h3 className="fw-bold">
              Online courses for academic <br /> growth
            </h3>
            <p>
              Home <FaChevronRight style={{ fontSize: 12 }} /> courses
            </p>
          </div>
        </Container>

        <Row className="mt-4 px-5 position-relative">
          <Col
            md={9}
            className="d-flex align-items-center gap-3 position-relative"
          >
            <Dropdown className="d-flex position-relative align-items-center">
              <Dropdown.Toggle
                style={{
                  border: "1px solid var(--color-primary)",
                  borderRadius: 100,
                  background: "transparent",
                  color: "var(--color-primary)",
                }}
              >
                Day or time
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 450, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-3 fw-semibold">Days of the week</h5>

                <div className="d-flex align-items-center gap-4">
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Monday</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Tuesday</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Wednesday</label>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 mt-3">
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Thursday</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Friday</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Saturday</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Sunday</label>
                  </div>
                </div>

                <h5 className="mb-3 mt-4 fw-semibold">
                  Time of day (India Standard Time)
                </h5>

                <div className="d-flex align-items-center gap-4">
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>12:00 AM - 6:00 AM</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>6:00 AM - 12:00 PM</label>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 mt-3">
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>12:00 PM - 6:00 PM</label>
                  </div>
                  <div>
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>6:00 PM - 12:00 AM</label>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <button
                    className="mt-4 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-4 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                style={{
                  border: "1px solid var(--color-primary)",
                  borderRadius: 100,
                  background: "transparent",
                  color: "var(--color-primary)",
                }}
              >
                Date
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 450, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Classes starting</h5>

                <div className="d-flex w-100 gap-5">
                  <div
                    className="pe-5"
                    style={{ borderRight: "1px solid #dadada" }}
                  >
                    <div className="d-flex align-items-center flex-shrink-0">
                      <input type="checkbox" className="me-2 filter_check" />
                      <label>Today</label>
                    </div>
                    <div className="d-flex align-items-center flex-shrink-0 mt-3">
                      <input type="checkbox" className="me-2 filter_check" />
                      <label>Next 7 days</label>
                    </div>
                    <div className="d-flex align-items-center flex-shrink-0 mt-3">
                      <input type="checkbox" className="me-2 filter_check" />
                      <label className="flex-shrink-0">Next 14 days</label>
                    </div>
                    <div className="d-flex align-items-center flex-shrink-0 mt-3">
                      <input type="checkbox" className="me-2 filter_check" />
                      <label>Summer</label>
                    </div>
                    <div className="d-flex align-items-center flex-shrink-0 mt-3">
                      <input type="checkbox" className="me-2 filter_check" />
                      <label>All dates</label>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <label className="fw-semibold">From</label>
                      <input type="date" className="form-control" />
                    </div>
                    <div>
                      <label className="fw-semibold mt-3">To</label>
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <button
                    className="mt-4 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-4 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                style={{
                  border: "1px solid var(--color-primary)",
                  borderRadius: 100,
                  background: "transparent",
                  color: "var(--color-primary)",
                }}
              >
                2+ learners
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Class size</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Any</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>1 learner</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>2 learners</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                style={{
                  border: "1px solid var(--color-primary)",
                  borderRadius: 100,
                  background: "transparent",
                  color: "var(--color-primary)",
                }}
              >
                Any price
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 330, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold"> Price per class (INR)</h5>

                {[
                  "Any",
                  "₹834 or less",
                  "₹1,668 or less",
                  "₹2,503 or less",
                  "₹3,337 or less",
                  "More than ₹3,337",
                ].map((price, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center flex-shrink-0 mt-3"
                  >
                    <input
                      type="checkbox"
                      className="me-2 filter_check"
                      onChange={(e) => handlePriceChange(e, price)}
                    />
                    <label>{price}</label>
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                style={{
                  border: "1px solid var(--color-primary)",
                  borderRadius: 100,
                  background: "transparent",
                  color: "var(--color-primary)",
                }}
              >
                More
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 330, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold"> Course Type</h5>

                {["Online", "Offline", "1 - on - 1"].map((type, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center flex-shrink-0 mt-3"
                  >
                    <input
                      type="checkbox"
                      className="me-2 filter_check"
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedCourseType(type)
                          : setSelectedCourseType("")
                      }
                    />
                    <label>{type}</label>
                  </div>
                ))}

                <h5 className="my-4 fw-semibold">Language</h5>

                {["English", "Hindi", "Spanish"].map((language, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center flex-shrink-0 mt-3"
                  >
                    <input
                      type="checkbox"
                      className="me-2 filter_check"
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedLanguage(language)
                          : setSelectedLanguage("")
                      }
                    />
                    <label>{language}</label>
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <p
              onClick={handleResetFilters}
              className="m-0 text-decoration-underline"
              style={{ color: "var(--color-primary)", cursor: "pointer" }}
            >
              reset all filters
            </p>
          </Col>
          <Col md={3} className="d-flex align-items-center justify-content-end">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                style={{
                  border: "1px solid var(--color-primary)",
                  borderRadius: 100,
                  background: "transparent",
                  color: "var(--color-primary)",
                }}
              >
                Filter by
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  style={{ background: "transparent" }}
                >
                  Newest
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  style={{ background: "transparent" }}
                >
                  Popular
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  style={{ background: "transparent" }}
                >
                  Price
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <Row className="mt-4 px-5">
          <Col
            md={3}
            style={{ height: "fit-content" }}
            className="mb-4 p-3 position-sticky top-0 left-0 rounded-3 bg-body-tertiary"
          >
            <h4 className="mb-3">Grade</h4>
            {["grade - 1", "grade - 2", "grade - 3"].map((grade, idx) => (
              <div className="d-flex align-items-center gap-3 mt-2" key={idx}>
                <input
                  type="checkbox"
                  className="filter_check2"
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedGrade(grade)
                      : setSelectedGrade("")
                  }
                />
                <p className="m-0">{grade}</p>
              </div>
            ))}

            <h4 className="mb-3 mt-4">Category</h4>

            {["Academics", "Business", "Development"].map((category, idx) => (
              <div className="d-flex align-items-center gap-3 mt-2" key={idx}>
                <input
                  type="checkbox"
                  className="filter_check2"
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedCategory(category)
                      : setSelectedCategory("")
                  }
                />
                <p className="m-0">{category}</p>
              </div>
            ))}

            <h4 className="mb-3 mt-4">Subject</h4>

            {["Maths", "Science", "English", "Hindi"].map((subject, idx) => (
              <div className="d-flex align-items-center gap-3 mt-2" key={idx}>
                <input
                  type="checkbox"
                  className="filter_check2"
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedSubject(subject)
                      : setSelectedSubject("")
                  }
                />
                <p className="m-0">{subject}</p>
              </div>
            ))}

            <h4 className="mb-3 mt-4">Board</h4>

            {["ICSE", "MP Board", "UP Board"].map((board, idx) => (
              <div className="d-flex align-items-center gap-3 mt-2" key={idx}>
                <input
                  type="checkbox"
                  className="filter_check2"
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedBoard(board)
                      : setSelectedBoard("")
                  }
                />
                <p className="m-0">{board}</p>
              </div>
            ))}
          </Col>
          <Col md={9} className="mb-4">
            <Row>
              {courses && courses.length > 0 ? (
                courses.map((course, idx) => (
                  <Col md={4} key={idx} className="mb-4">
                    <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
                      <img
                        className="cource_img rounded-3 px-3"
                        src={baseAwsUrl + course?.thumbnail_key}
                        alt=""
                        style={{ height: 200 }}
                      />
                      <div className="heart d-flex align-items-center justify-content-center shadow">
                        <FiHeart className="fs-5" />
                      </div>

                      <div className="d-flex px-3 align-items-center justify-content-between mt-3">
                        <button className="px-3">
                          {course?.course_tags[0]}
                        </button>
                        <h6 className="m-0 fw-semibold">Rs. {course?.price}</h6>
                      </div>

                      <h5
                        onClick={() =>
                          navigate("/course", {
                            state: { course },
                          })
                        }
                        style={{ cursor: "pointer" }}
                        className="mt-3 px-3"
                      >
                        {course?.title}
                      </h5>

                      <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3 pb-3">
                        <div className="user d-flex align-items-center gap-2">
                          <div className="user_img">
                            <img src={course?.teacher_id?.image_key} alt="" />
                          </div>
                        </div>
                        <p className="m-0 text-secondary">
                          {course?.teacher_id?.firstName +
                            " " +
                            course?.teacher_id?.lastName}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <IoStar className="star" /> (4 Reviews)
                        </div>
                      </div>

                      <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
                        <p className="m-0">
                          <TiBook className="fs-5 me-1 mb-1" /> 05
                        </p>
                        <p className="m-0">
                          <IoMdTime className="fs-5 me-1 mb-1" />{" "}
                          {course?.duration_time}
                        </p>
                        <p className="m-0">
                          <IoSchoolOutline className="fs-5 me-1 mb-1" />{" "}
                          {course?.students_count}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <h4 className="mt-5 text-center">No courses found</h4>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default CourcePage;
