import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Col, Row, Form } from "react-bootstrap";
import { FaAnglesRight, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Card2 from "../../ParentPages/parent/Card2";
import card2 from "../../assets/parent/card2.png";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner for loading indicator
import { useSelector } from "react-redux";

const BlogPage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchBlogs = async () => {
    setLoading(true); // Set loading to true before the request
    try {
      let res = await axios.get("/teacher/blog", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data);
      let { res_type, message, data } = res.data || res;
      if (res_type === "success") {
        setBlogs(data);
      } else {
        toast.error(message || "Server error please try again later.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false); // Set loading to false after the request
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [token]);

  function formatDate(dateString) {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    if (diffInHours < 24) return `${diffInHours} hours ago`;
    if (diffInDays < 7) return `${diffInDays} days ago`;
    if (diffInWeeks < 4)
      return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  }

  return (
    <TeacherLayout>
      <h3>Blog</h3>
      <StyledContainer>
        <Row className="mt-3">
          <Col md={12} className="d-flex justify-content-end">
            <Form.Control
              style={{ width: 200 }}
              type="text"
              placeholder="Search blogs"
              className="search_input me-4"
            />
            <button
              onClick={() => navigate("/teacher/dashboard/pages/blog/add")}
              className="add_new_blog py-2 text_yellow fw-bold px-4 rounded-5"
            >
              <FaPlus className=" fs-5 me-2" />
              Add Blog
            </button>
          </Col>
        </Row>

        <Row className="mt-4">
          {loading ? ( // Display loading spinner while fetching data
            <Col className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </Col>
          ) : blogs.length > 0 ? ( // Display blogs if available
            blogs.map((blog, index) => (
              <Col key={index} md={4}>
                <Card2
                  img={blog.thumbnail_key || card2}
                  head={blog.title}
                  review={"267 views"}
                  time={formatDate(blog.createdAt)}
                  blogIcon={<FaAnglesRight />}
                  blog={"View blog"}
                  deleteButton={blog._id}
                  fetchBlogsNew={fetchBlogs}
                  isFevourite={blog.isFav}
                  blogID={blog._id}
                />
              </Col>
            ))
          ) : (
            // Display no data found message if no blogs are available
            <Col className="d-flex justify-content-center">
              <p>No blogs found</p>
            </Col>
          )}
        </Row>
      </StyledContainer>
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .add_new_blog {
    border: 1.5px solid var(--color-primary);
    background: transparent;
  }
`;

export default BlogPage;
