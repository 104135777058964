/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { BsPinAngleFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa6";
import {
  FaCalendar,
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaComments,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

import Navbar from "../components/Navbar.js";
import "../assets/css/blogPage.css";
import Footer from "../components/Footer.js";
import { useNavigate } from "react-router-dom";
import { getAllBlogs } from "../Api/BlogApi/getAllBlogs.js";
import Moment from "react-moment";
const blogImg = require("../assets/blog-main-section-img.png");
const BlogCommentImg1 = require("../assets/blog-comment-01.png");
const BlogNotFound = require("../assets/log-not-found.png");

function BlogPage1() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  let navigate = useNavigate();

  const [suggestOpen, setSuggestOpen] = useState(true);
  const [suggestCommentOpen, setSuggestCommentOpen] = useState(true);
  const [suggestTagsOpen, setSuggestTagsOpen] = useState(true);

  const [AllBlogs, setAllBlogs] = useState([null]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const paginate = (array, page, pageSize) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return array.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {
    if (currentPage * pageSize < AllBlogs.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToEndPage = () => {
    const totalPages = Math.ceil(AllBlogs.length / pageSize);
    setCurrentPage(totalPages);
  };

  const currentItems = paginate(AllBlogs, currentPage, pageSize);

  useEffect(() => {
    setCurrentPage(1);
    getAllBlogs()
      .then((res) => {
        let { blogs, message } = res;
        if (blogs) {
          setAllBlogs(blogs);
          console.log(blogs);
        } else {
          console.log("blog res Error:- ", message);
        }
      })
      .catch((err) => {
        console.log("error blog :- ", err.message);
      });
  }, []);

  const handleSuggestArtical = () => {
    setSuggestOpen(!suggestOpen);
  };

  const handleSuggestComment = () => {
    setSuggestCommentOpen(!suggestCommentOpen);
  };

  const handleSuggestTags = () => {
    setSuggestTagsOpen(!suggestTagsOpen);
  };

  const handleOpenBlog = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <>
      {/* <div className="main blog-page"> */}
      <div className="">
        <Navbar />
        <br />
        <br />
        <Container fluid className="mt-5">
          <Row
            className="pt-3 "
            style={{
              background:
                "linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9)",
            }}
          >
            <Col md={7} className="pt-5 ps-5 d-flex">
              <Col md={12}>
                <h2 className="blog-artical-page-heading">Articles</h2>
                <Col>
                  Egestas sed tempus urna et pharetra. Leo integer malesuada
                  nunc vel. Libero id faucibus nisl tincidunt eget nullam non
                  nisi. Faucibus turpis in eu mi bibendum neque egestas Egestas
                  sed tempus urna et pharetra. Leo integer malesuada nunc vel.
                  Libero id faucibus nisl tincidunt eget nullam non nisi.
                  Faucibus turpis in eu mi bibendum neque egestas
                  <br />
                  Egestas sed tempus urna et pharetra. Leo integer malesuada
                  nunc vel. Libero id faucibus nisl tincidunt eget nullam non
                  nisi. Faucibus turpis in eu mi bibendum neque egestas
                  Egestas sed tempus urna et pharetra. Leo integer malesuada
                  nunc vel. Libero id faucibus nisl tincidunt eget nullam non
                  nisi. Faucibus turpis in eu mi bibendum neque egestas
                </Col>
                <Col
                  md={6}
                  className="mt-5 mb-3 d-flex"
                  style={{
                    borderRadius: "255px",
                    padding: "5px 6px",
                    border: "1px solid var(--yellow-color)",
                    overflow: "hidden",
                  }}
                >
                  <div className="bloge-page-1-input">
                    <input
                      type="text"
                      placeholder=" what do you want to lean ?"
                    />
                  </div>
                  <div className="ms-3 bloge-page-1-button">Search</div>
                </Col>
              </Col>
            </Col>
            <Col md={5}>
              <img
                src={blogImg}
                style={{ width: "100%", height: "90%", objectFit: "contain" }}
                alt=""
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="career-area mb-5 d-flex justify-content-between">
            <Col md={8} className="pt-2">
              <Row className="m-0 p-0 d-flex">
                {AllBlogs && AllBlogs.length > 0 ? (
                  currentItems.map((blog, index) => {
                    return (
                      <Col
                        onClick={() => handleOpenBlog(blog && blog._id)}
                        key={index}
                        className="ps-2 m-0 col-12 col-md-6 mt-3 blog-artical-item"
                        md={6}
                      >
                        <div className="blog-artical-area-label">
                          {blog && blog.board}
                        </div>
                        <Col className="blog-artican-image">
                          <img src={blog && blog.imageURL} alt="" />
                          <div class="image-border"></div>
                        </Col>
                        <Col className="items-menu mt-2 mb-2">
                          <div className="items-cares-sub-menu ">
                            <BsPinAngleFill /> Sticky Post
                          </div>
                          <div className="items-cares-sub-menu ">
                            <FaUser /> by {blog && blog.author.username}
                          </div>
                          <div className="items-cares-sub-menu ">
                            <FaCalendar />{" "}
                            <Moment format="MMM Do YYYY">
                              {blog && blog.datePublished}
                            </Moment>
                          </div>
                        </Col>
                        <Col style={{ width: "65%" }}>
                          <b>{blog && blog.title}</b>
                        </Col>
                        <Row
                          className="d-flex ps-3"
                          style={{ fontWeight: 600 }}
                        >
                          Read Articles
                        </Row>
                      </Col>
                    );
                  })
                ) : (
                  <Col>
                    <hr />
                    <center>
                      <Image src={BlogNotFound}></Image>
                      <h4>!! Blog Not Found !!</h4>
                    </center>
                  </Col>
                )}

                {AllBlogs && AllBlogs.length > 0 ? (
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    <span onClick={goToFirstPage} className="count-box">
                      <FaAngleDoubleLeft />
                    </span>
                    <span onClick={handlePrevPage} className="count-box">
                      <FaAngleLeft />
                    </span>
                    <span className="count-box blog-pagination-active">
                      {currentPage}
                    </span>
                    <span onClick={handleNextPage} className="count-box">
                      <FaAngleRight />
                    </span>
                    <span onClick={goToEndPage} className="count-box">
                      <FaAngleDoubleRight />
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
            {/* ---------------- left side -------------------- */}
            <Col md={4} className="pt-3">
              <Row>
                <Col
                  onClick={handleSuggestArtical}
                  className="col-10 bload_heading-a"
                >
                  Suggested Articles
                </Col>
                <Col
                  onClick={handleSuggestArtical}
                  className="col-2 bload_heading arrowUp_blog_icon"
                >
                  {suggestOpen ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </Col>
                {suggestOpen ? (
                  <>
                    <Row md className="col-12 mt-3">
                      <Col className="col-4">
                        <Col className="blog-suggest-artical-img">
                          <img src={BlogCommentImg1} alt="" />
                        </Col>
                      </Col>
                      <Col className="col-8 ">
                        <Col>
                          <FaUser /> by Corabelle Durrad
                        </Col>
                        <Col className="mt-2">
                          <b>See How Michaele Built a New Life and Career</b>
                        </Col>
                        <hr />
                        <Col style={{ opacity: ".5" }}> October 12, 2023</Col>
                      </Col>
                    </Row>
                    <Row md className="col-12 mt-3">
                      <Col className="col-4">
                        <Col className="blog-suggest-artical-img">
                          <img src={BlogCommentImg1} alt="" />
                        </Col>
                      </Col>
                      <Col className="col-8 ">
                        <Col>
                          <FaUser /> by Corabelle Durrad
                        </Col>
                        <Col className="mt-2">
                          <b>See How Michaele Built a New Life and Career</b>
                        </Col>
                        <hr />
                        <Col style={{ opacity: ".5" }}> October 12, 2023</Col>
                      </Col>
                    </Row>
                    <Row md className="col-12 mt-3">
                      <Col className="col-4">
                        <Col className="blog-suggest-artical-img">
                          <img src={BlogCommentImg1} alt="" />
                        </Col>
                      </Col>
                      <Col className="col-8 ">
                        <Col>
                          <FaUser /> by Corabelle Durrad
                        </Col>
                        <Col className="mt-2">
                          <b>See How Michaele Built a New Life and Career</b>
                        </Col>
                        <hr />
                        <Col style={{ opacity: ".5" }}> October 12, 2023</Col>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Row>
              <hr />
              <Row>
                <Col
                  onClick={handleSuggestComment}
                  className="col-10 bload_heading-a"
                >
                  Recent Comments
                </Col>
                <Col
                  onClick={handleSuggestComment}
                  className=" bload_heading arrowUp_blog_icon"
                >
                  {suggestCommentOpen ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </Col>
                {suggestCommentOpen ? (
                  <>
                    <Col md className="mt-3 col-md-12">
                      <FaComments /> <b>Irene Flores</b> felis eget velit
                      aliquet sagittis id consectetur.
                    </Col>
                    <Col md className="mt-3 col-md-12">
                      <FaComments /> <b>Anthony Patterson</b> felis eget velit
                      aliquet sagittis id consectetur.
                    </Col>
                    <Col className="mt-3 col-md-12">
                      <FaComments /> <b>Wendy Johnson</b> felis eget velit
                      aliquet sagittis id consectetur.
                    </Col>
                  </>
                ) : null}
              </Row>
              <hr />
              {/* <Row>
                <Col
                  onClick={handleSuggestTags}
                  md={10}
                  className="col-10 bload_heading-a"
                >
                  Tags
                </Col>
                <Col
                  onClick={handleSuggestTags}
                  md={2}
                  className="col-2 bload_heading arrowUp_blog_icon"
                >
                  {suggestTagsOpen ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </Col>
                {suggestTagsOpen ? (
                  <Col md aria-colcount={12} className="p-3 mt-3 blog_tag_area">
                    <span className="blog-tag"> Project Management </span>
                    <span className="blog-tag"> Engineer</span>
                    <span className="blog-tag">Network </span>
                    <span className="blog-tag"> Systems</span>
                    <span className="blog-tag"> Security</span>
                    <span className="blog-tag">IT & Software </span>
                    <span className="blog-tag"> Career</span>
                    <span className="blog-tag">IT & Software </span>
                    <span className="blog-tag">IT & Software </span>
                    <span className="blog-tag"> Hard Skills</span>
                  </Col>
                ) : null}
              </Row> */}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default BlogPage1;
