import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Col, Row, Spinner } from "react-bootstrap";
import ParentFooter from "../../components/ParentFooter";
import { MdCloudUpload } from "react-icons/md";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MyAPI } from "../../utils/MyAPI";

const AddHomeWorkPage = () => {
  const [loading, setLoading] = useState(false);
  const token = useSelector(state => state.token.value)
  const { coursesId } = useParams();
  const navigate = useNavigate()

  const [file, setFile] = useState(null); // To hold the uploaded file
  const [title, setTitle] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [points, setPoints] = useState("");
  const [instructions, setInstructions] = useState("");
  const [description, setDescription] = useState("");

  const maxFileSize = 50 * 1024 * 1024; // 50MB

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    maxSize: maxFileSize,
    multiple: false, // Only allow one file
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("File is too large or unsupported format. Max size is 50MB.")
      } else if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const handleRemoveFile = () => {
    setFile(null); // Remove the uploaded file
  };

  const handleSubmit = async () => {
    if (!title) {
      return toast.warn('Please Enter Title.')
    }
    if (!dueDate) {
      return toast.warn('Please Select Date.')
    }
    if (!points) {
      return toast.warn('Please Enter Points.')
    }
    if (!instructions) {
      return toast.warn('Please Enter Instructions.')
    }
    if (!description) {
      return toast.warn('Please Enter Description.')
    }
    if (!file) {
      return toast.warn('Please Select File.')
    }

    try {

      // Convert the dueDate to 'mm-dd-yyyy' format
      const date = new Date(dueDate);
      const formattedDate = `${date.getMonth() + 1 // Months are zero-based
        }-${date.getDate()}-${date.getFullYear()}`;

      //new formData
      const payload = new FormData();
      payload.append('course_id', coursesId);
      payload.append('title', title);
      payload.append('for_date', formattedDate); // mm-dd-yyyy
      payload.append('points', points);
      payload.append('instructions', instructions);
      payload.append('description', description);
      payload.append('attachment', file);

      setLoading(true);
      let res = await MyAPI.POST(`/teacher/homework`, payload, token)
      let { res_type, message, data: apiData } = res.data || res
      if (res_type === 'success') {
        toast.success(message || 'Home Work Added.')
        setTitle("");
        setDueDate("");
        setPoints("");
        setInstructions("");
        setDescription("");
        setFile(null);
        navigate('/teacher/HwandTest')
      } else {
        toast.error(message || 'Error Adding Home Work.')
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <TeacherLayout>
      <h3>Add Homework</h3>
      <StyledContainer>
        <Row className="mt-3">
          <Col md={12} className="mt-3">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>

          <Col md={6} className="mt-4">
            <input
              type="datetime-local"
              className="form-control shadow"
              placeholder="Due Date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </Col>
          <Col md={6} className="mt-4">
            <input
              type="number"
              className="form-control shadow"
              placeholder="Points"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
          </Col>

          <Col md={12} className="mt-4">
            <textarea
              className="form-control shadow"
              placeholder="Instructions"
              rows="3"
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
            ></textarea>
          </Col>

          <Col md={12} className="mt-4">
            <textarea
              className="form-control shadow"
              placeholder="Description"
              rows="5"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <h5>Or</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <StyledHwContainer>
              <div {...getRootProps()} className="upload_box">
                <input {...getInputProps()} />
                <MdCloudUpload className="text_yellow fs-1 mb-2" />
                <p className="m-0">Drag files or upload</p>
                <p className="m-0 mb-2">
                  Max file size: <b>50MB</b>
                </p>
                <p className="m-0 mb-2">
                  Supported file types: <b>PNG, JPG, PDF</b>
                </p>
              </div>

            </StyledHwContainer>
          </Col>
          <Col md={6}>
            {/* Show uploaded file preview */}
            {file && (
              <div className="px-3 d-flex align-items-center justify-content-between border rounded-3">
                {file.type.includes("image") ? (
                  <img
                    width={80}
                    height={80}
                    src={URL.createObjectURL(file)}
                    alt="Uploaded file"
                  />
                ) : (
                  <p className="py-1 mt-2">{file.name}</p>
                )}
                <button className="remove-btn px-2 py-1 bg-danger text-white rounded-3" onClick={handleRemoveFile}>
                  <AiOutlineClose />
                </button>
              </div>
            )}
          </Col>
        </Row>

        <Row className="mt-3 d-flex align-items-center justify-content-end">
          <button className="approval_btn" onClick={handleSubmit} disabled={loading}>
            {loading ? <Spinner animation="border" variant="light" size="sm" /> : "Submit"}
          </button>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .form-control {
    border: 1px solid var(--color-primary) !important;
    border-radius: 10px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .approval_btn {
    background-color: var(--color-primary);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
    line-height: 24px;
    padding: 10px 20px;
    border: none;
    color: white;
    display: flex;
    align-items: center;
  }

  .uploaded_file {
    position: relative;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    padding: 10px;
    text-align: center;

    .uploaded_img {
      max-width: 100px;
      max-height: 100px;
    }

    .remove-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      background: none;
      border: none;
      color: red;
      cursor: pointer;
    }
  }
`;

const StyledHwContainer = styled.div`
  .upload_box {
    width: 100%;
    height: 100%;
    border: 2px dashed #5d5d5d;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
  }
`;

export default AddHomeWorkPage;
