import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import { IoCalendarClearOutline, IoStarSharp } from "react-icons/io5";
import { Col, Container, Row } from "react-bootstrap";
import { Chip, IconButton } from "@mui/material";
import { VscLiveShare } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";
import { FaAngleRight, FaUsers } from "react-icons/fa6";
import { GoStopwatch } from "react-icons/go";
import { SlCalender } from "react-icons/sl";
import { BiLike, BiSolidDislike } from "react-icons/bi";
import { SiSinglestore } from "react-icons/si";
import {
  MdCastForEducation,
  MdOutlineStarBorderPurple500,
  MdOutlineTopic,
} from "react-icons/md";
import Navbar from "../components/Navbar";
import { Link, useLocation } from "react-router-dom";
import { baseAwsUrl } from "../utils/MyAPI";
import moment from "moment";

const screenShot = require("../assets/online-tutorials-concept_52683-37480.avif");
const BlogCommentImg1 = require("../assets/blog-comment-01.png");

const Course = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { state } = useLocation();

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      <Container style={{ overflowX: "hidden" }} className="p-0" fluid>
        <Row>
          <Col
            className="d-flex align-items-center justify-content-start"
            xs={12}
            style={{
              width: "",
              height: "150px",
              backgroundImage:
                "url('https://themegenix.com/demo/skillgro/assets/img/bg/breadcrumb_bg.jpg')",
            }}
          >
            <div
              className="cources-path-location d-flex align-items-center ps-5"
              style={{ gap: "10px" }}
            >
              Home <FaAngleRight />
              Cources <FaAngleRight />
              <span className="text-primary">
                Resolving Conflicts Between Designers And Engineers
              </span>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="p-3">
        <Row>
          <Col md={9}>
            <Col md={12}>
              <img
                width={"100%"}
                style={{ maxHeight: "50vh", objectFit: "cover" }}
                height={"100%"}
                className="rounded-3"
                src={baseAwsUrl + state?.course?.thumbnail_key}
                alt=""
              />
            </Col>
            <br />

            <Col xs={12} className="">
              <h3>{state?.course?.title}</h3>
            </Col>
            <br />
            <Row className="p-3">
              <Col md={12} className="d-flex align-items-center">
                <img
                  src={state?.course?.teacher_id?.image_key}
                  className="border border-warning"
                  width={"60px"}
                  height={"60px"}
                  style={{ borderRadius: "50%" }}
                  alt=""
                />
                <span className="teacher-name ms-3">
                  <b>
                    By{" "}
                    {state?.course?.teacher_id?.firstName +
                      " " +
                      state?.course?.teacher_id?.lastName}
                  </b>{" "}
                  <br />
                  <b>
                    <IoStarSharp className="text_yellow me-2 fs-6" /> 4.5
                  </b>{" "}
                  <br />
                  {/* <Link to="#"> */}
                  <span className="text-decoration-underline text-primary">
                    View Profile
                  </span>
                  {/* </Link> */}
                </span>
                <div className="d-block ms-auto">
                  <span className="class-room-dated-flex align-items-center justify-content-center ms-3">
                    <IoCalendarClearOutline size={22} /> &nbsp;{" "}
                    <span>
                      {moment(state?.course?.created_at).format("DD MMM YYYY")}
                    </span>
                  </span>
                  <span className="number-of-students align-items-center justify-content-center ms-3">
                    <MdCastForEducation size={22} /> &nbsp;{" "}
                    <span>
                      {state?.course?.students_count} Students Enrolled
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="ms-3 mb-3 new-button">Overview</button>
              </Col>
            </Row>
            <Container
              className="shadow rounded-3 px-5 py-4"
              style={{ border: "1px solid #EEEEEE" }}
            >
              <h5>Course Description</h5>
              <p>{state?.course?.description}</p>
              {/* <h5>What you'll learn in this course?</h5>
              <p>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan.
              </p> */}

              {/* <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>Work with color & Gradients & Grids</b>
              </Col>
              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>All the useful shortcuts</b>
              </Col>
              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>Be able to create Flyers, Brochures, Advertisements</b>
              </Col>
              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>How to work with Images & Text</b>
              </Col>
              <br />
              <p>
                Morem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan.Dorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magn.
              </p> */}
            </Container>
            <Col className="mt-4 mb-2">
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
            </Col>
            <Col className="d-flex  mb-4 align-items-center gap-1">
              <input
                type="text"
                placeholder="Comment"
                className="py-2 px-3 border-0"
                style={{ width: "100%", background: "rgb(229 229 229)" }}
              />
              <button
                className="py-2 px-3 text-white border-0"
                style={{ background: "var(--color-primary)" }}
              >
                Comment
              </button>
            </Col>
            <Col className="mt-5 bload_heading">2 Comments</Col>
            <Col className="p-2">
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Carolyn Wallace
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Marrion Willsoriam
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Carolyn Wallace
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
            </Col>
          </Col>
          <Col md={3}>
            <div
              className="border border-2 rounded-3 px-3 py-3"
              style={{
                overflow: "hidden",
                position: "sticky",
                top: "14vh",
                right: 0,
              }}
            >
              <div
                className="d-flex align-items-center rounded-3 justify-content-center"
                style={{
                  height: "15vh",
                  background: "linear-gradient(to right, #5751E0, #5751E1)",
                  flexDirection: "column",
                }}
              >
                <div>
                  <h5 className="font-popppins text-white">
                    This is Course fee
                  </h5>
                  <h5 className="text-white">
                    ₹ {state?.course?.price} <sup>weekly</sup>{" "}
                  </h5>
                </div>
              </div>
              <br />
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SlCalender size={22} /> &nbsp; <b>Subject</b>
                </span>{" "}
                <b>{state?.course?.subject}</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <MdOutlineTopic size={22} /> &nbsp; <b>Topic</b>
                </span>{" "}
                <b>{state?.course?.title}</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <GoStopwatch size={22} /> &nbsp; <b>Age group</b>
                </span>{" "}
                <b>14-17 years</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SlCalender size={22} /> &nbsp; <b>Grade</b>
                </span>{" "}
                <b>
                  <span>{state?.course?.grade}</span>
                </b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SiSinglestore size={22} /> &nbsp; <b>Type</b>
                </span>{" "}
                <b>{state?.course?.course_type}</b>
              </span>

              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <FaUsers size={22} /> &nbsp; <b>Students</b>
                </span>{" "}
                <b>{state?.course?.students_count}</b>
              </span>
              {/* all available time slots */}
              <div className="d-flex align-items-center justify-content-between border-bottom mt-3">
                <div className="left-box p-0">
                  <h6>
                    {moment(state?.course?.created_at).format("DD MMM YYYY")}
                  </h6>
                  <p>
                    Time: {moment(state?.course?.created_at).format("hh:mm A")}
                  </p>
                </div>
                <div className="right">
                  <Chip
                    style={{ cursor: "pointer" }}
                    label="Enroll"
                    variant="outlined"
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px" }}
              >
                <IconButton aria-label="delete">
                  <CiHeart size={32} />
                </IconButton>

                <IconButton aria-label="delete">
                  <VscLiveShare size={32} />
                </IconButton>
              </div>
              <button
                className="w-100 py-2 rounded-3 mt-2 fw-bold text-white border-0"
                style={{ background: "var(--color-primary)" }}
              >
                Enroll Now
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

const CourseStyledContainer = styled.div`
  .c_hero {
    background: linear-gradient(to right, var(--color-primary-light), #fcffd6);
    padding-top: 12vmax;
    padding-bottom: 2vmax;
    padding-left: 4vmax;
    padding-right: 4vmax;
    position: relative;

    .c_card {
      width: 250px;
      position: absolute;
      top: 40%;
      right: 10%;
      background: white;

      .img {
        position: relative;
        height: 45%;
        width: 100%;
        > img {
          width: 100%;
          height: 100%;
        }

        .c_play {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          border: 2px solid white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }
    }
  }

  .section2 {
    margin-top: 6vmax;
  }

  .c_dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: black;
    flex-shrink: 0;

    .c_dot_inner {
      height: 3px;
      width: 3px;
      border-radius: 50%;
      background: white;
    }
  }

  .dis_p {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background: black;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .btns {
    width: 200px;
  }

  @media (max-width: 768px) {
    .c_hero {
      padding-left: 2vmax;
      padding-right: 2vmax;
      padding-top: 15vmax;

      .c_card {
        position: relative;
        margin-top: 3vmax;
        margin-left: 5vmax;
      }
    }

    .section2 {
      margin-top: 4vmax;
    }
  }
`;

export default Course;
