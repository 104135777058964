import React, { useEffect, useState } from "react";
import img from "../../assets/parent/card3.png";

import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import "../../assets/css/parent/parent.css";
import slider_img_1 from "../../assets/parent/slider_img_1.png";
import { FaAngleLeft, FaAngleRight, FaAnglesRight } from "react-icons/fa6";
import card2 from "../../assets/parent/card2.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "styled-components";
// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";

import Card2 from "./Card2";

import { IoMdTime } from "react-icons/io";
import { Col, Row, Modal, Button, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AllCourses from "../../components/homepage/CourcesMain";
import BrainBits from "../../components/homepage/BrainBits2";
import Mentor from "../../components/homepage/Mentor";
import ParentFooter from "../../components/ParentFooter";
import { motion } from "framer-motion";
import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";
import { IoPlaySharp, IoSchoolOutline, IoStar } from "react-icons/io5";
import { TiBook } from "react-icons/ti";
import { FiHeart } from "react-icons/fi";
import VideoModal from "../transcript/VideoModal ";

import { GrUserWorker, GrWorkshop } from "react-icons/gr";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";

const ParentPage = (props) => {
  const [showModalVideo, setShowModalVideo] = useState(false);

  const handleVideoClose = () => setShowModalVideo(false);
  const handleVideoShow = () => setShowModalVideo(true);
  const { history } = props;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state) => state.token.value);
  const [loading, setLoading] = useState(false);

  const [banner, setBanner] = useState([]);

  const fetchBanners = async (token) => {
    try {
      setLoading(true);
      let res = await MyAPI.GET("/banner/show", token);
      let { res_type, message, data, error } = res.data || res;
      if (res_type === "success") {
        setBanner(data);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchBanners(token);
    }
  }, [token]);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <OftadehLayout>
      {/* manual demo model  */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "10vh" }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Manual Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mt-3" md={4}>
              <Form.Label>Child Name</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="John Doe"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Age</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="18 years"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>STD</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="123-122-1232"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="+91 1234567890"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="email"
                placeholder="example@email.com"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Select Courses</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="course1">Course 1</option>
                  <option value="course2">Course 2</option>
                  <option value="course3">Course 3</option>
                  <option value="course4">Course 4</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Courses Mode</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">Online</option>
                  <option value="Offline">Offline</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Courses Shift</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">Day</option>
                  <option value="Offline">Night</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Preferred Teacher</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">teacher 1</option>
                  <option value="Offline">teacher 2</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Select Child</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">child 1</option>
                  <option value="Offline">child 2</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Preferred Date</Form.Label>
              <Form.Control className="shadow rounded-5" type="date" />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Preferred Time</Form.Label>
              <Form.Control className="shadow rounded-5" type="time" />
            </Col>
            <Col className="mt-3" md={4}>
              <hr className="bg-transparent border-0" />
              {/* <Button className='shadow rounded-5 border-warning text-warning' variant="outlined">Book Demo</Button> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            className="me-3 bg-danger text-white border-danger"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          {/* You can add additional buttons or functionality here */}
          <Button
            variant="outlined"
            className="new-button rounded-5"
            onClick={handleCloseModal}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>

      <motion.div
        className="pe-5 overflow-hidden"
        style={{ fontFamily: "Poppins, sans-serif" }}
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {!loading &&
            banner &&
            banner.length > 0 &&
            banner?.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                <div className="d-md-flex justify-content-between align-items-center parent_slider rounded-4 py-4 px-4 overflow-hidden gap-3 border-0 bg-white border">
                  <div className="col-md-6 position-relative m-0 p-0">
                    <h3>{item.title ?? "Title Not Available."}</h3>
                    <p className="mt-4">
                      {item.description ?? "Description Not Available."}
                    </p>
                    {/* <p className="mt-3">
                      Invest in their future today and start a transformative <br />{" "}
                      learning journey now.
                    </p> */}
                  </div>
                  <div className="col-md-6 position-relative m-0 p-0 d-flex align-items-center justify-content-center">
                    <div className="parent_slider_img rounded-3 overflow-hidden">
                      <img src={item.image_key || slider_img_1} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {loading && (
            <SwiperSlide>
              {" "}
              <div className="d-md-flex justify-content-between align-items-center parent_slider rounded-4 py-4 px-4 overflow-hidden gap-3 border-0 bg-white border">
                <div className="col-md-6 position-relative m-0 p-0">
                  <h3>
                    Unlock the Power of Personalized Learning: Learnie's
                    One-on-One Academic Classes Tailored Specifically for Your
                    Child’s Needs
                  </h3>
                  <p className="mt-4">
                    Unlock your child's potential with personalized one-on-one
                    academic classes on our website. Choose budget-friendly
                    options and nurture brilliance with top faculty guidance.
                  </p>
                  <p className="mt-3">
                    Invest in their future today and start a transformative{" "}
                    <br /> learning journey now.
                  </p>
                </div>
                <div className="col-md-6 position-relative m-0 p-0 d-flex align-items-center justify-content-center">
                  <div className="parent_slider_img rounded-3 overflow-hidden">
                    <img src={slider_img_1} alt="" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </motion.div>

      <Row>
        <Col md={4} className="pe-5 mt-4">
          <Card
            onClick={() => navigate("/parent/HomeWork")}
            className="shadow cursor-pointer rounded-3 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
          >
            <div>
              <h4 className="m-0">108</h4>
              <p className="m-0">All Test</p>
            </div>
            <GrUserWorker className="text_yellow fs-4" />
          </Card>
        </Col>

        <Col md={4} className="pe-5 mt-4">
          <Card
            onClick={() => navigate("/parent/HomeWork")}
            className="shadow cursor-pointer rounded-3 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
          >
            <div>
              <h4 className="m-0">10</h4>
              <p className="m-0">Pending Test</p>
            </div>
            <GrUserWorker className="text_yellow fs-4" />
          </Card>
        </Col>
        <Col md={4} className="pe-5 mt-4">
          <Card
            onClick={() => navigate("/parent/HomeWork")}
            className="shadow cursor-pointer rounded-3 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
          >
            <div>
              <h4 className="m-0">10</h4>
              <p className="m-0">Completed Test</p>
            </div>
            <GrUserWorker className="text_yellow fs-4" />
          </Card>
        </Col>

        <Col md={4} className="pe-5 mt-4">
          <Card
            onClick={() => navigate("/parent/HomeWork")}
            className="shadow cursor-pointer rounded-3 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
          >
            <div>
              <h4 className="m-0">108</h4>
              <p className="m-0">All Home Work</p>
            </div>
            <GrWorkshop className="text_yellow fs-4" />
          </Card>
        </Col>

        <Col md={4} className="pe-5 mt-4">
          <Card
            onClick={() => navigate("/parent/HomeWork")}
            className="shadow cursor-pointer rounded-3 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
          >
            <div>
              <h4 className="m-0">10</h4>
              <p className="m-0">Pending Home Work</p>
            </div>
            <GrWorkshop className="text_yellow fs-4" />
          </Card>
        </Col>
        <Col md={4} className="pe-5 mt-4">
          <Card
            onClick={() => navigate("/parent/HomeWork")}
            className="shadow cursor-pointer rounded-3 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
          >
            <div>
              <h4 className="m-0">10</h4>
              <p className="m-0">Completed Home Work</p>
            </div>
            <GrWorkshop className="text_yellow fs-4" />
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h3>Live Teacher's</h3>
        </Col>
      </Row>
      <StyledContainer>
        <Row className="mt-3">
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">John Deo</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Topic </p>
                  <p className="m-0">13 June 2023</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p
                    className="m-0 text_yellow fw-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Join live <FaAngleRight />
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Akash Seth</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Topic</p>
                  <p className="m-0">13 June 2023</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p
                    className="m-0 text-secondary fw-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Join live <FaAngleRight />
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Today's classes</h3>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Mathematics</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Vishal seth </p>
                  <p className="m-0">13 June 2023</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p
                    className="m-0 text_yellow fw-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Join live <FaAngleRight />
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Mathematics</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Vishal seth </p>
                  <p className="m-0">13 June 2023</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p
                    className="m-0 text_yellow fw-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Join live <FaAngleRight />
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </StyledContainer>

      <div className="mt-5 position-relative pe-5 ps-3">
        <h3 className="fw-bol mb-4">Active courses </h3>

        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards
              img={c1}
              age="17+"
              type="1-on-1"
              type2="Academic"
              type3="Short course"
              board="CBSE"
              grade="A+"
              joinNow={true}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Cards
              img={c2}
              age="17+"
              type="1-on-1"
              type2="Academic"
              type3="Short course"
              board="CBSE"
              grade="A+"
              joinNow={true}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Cards
              img={c3}
              age="17+"
              type="1-on-1"
              type2="Academic"
              type3="Short course"
              board="CBSE"
              grade="A+"
              joinNow={true}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Cards
              img={c4}
              age="17+"
              type="1-on-1"
              type2="Academic"
              type3="Short course"
              board="CBSE"
              grade="A+"
              joinNow={true}
            />
          </SwiperSlide>
        </Swiper>

        <h6 className="fw-bold text_yellow text-end text-decoration-underline me-4">
          View More
        </h6>
      </div>
      <Row className="mt-3">
        <Col md={9}>
          <h3 className="mb-4">Demo's</h3>
        </Col>
        <Col
          md={3}
          className="d-flex align-items-center justify-content-end pe-5"
        >
          <button className="new-button" onClick={handleButtonClick}>
            Can't find the class
          </button>
        </Col>
      </Row>

      <Row className="position-relative pe-5">
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c2} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c3} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c4} />
          </SwiperSlide>
        </Swiper>
      </Row>
      <div className="mt-5 position-relative pe-5 ps-3">
        <h3 className="fw-bol mb-4">Suggested courses </h3>

        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c2} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c3} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c4} joinbtn={true} />
          </SwiperSlide>
        </Swiper>

        <h6 className="fw-bold text_yellow text-end mt-3 me-4">View More</h6>
      </div>

      <Mentor cardNum={2} />
      <BrainBits />
      <AllCourses />
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .card3 {
    width: 100%;
    height: 100%;

    .top {
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid var(--color-dark);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-primary);
        border: none;
        color: white;
      }
    }
  }
`;

export default ParentPage;

const Cards = ({
  img,
  badge,
  holdbtn,
  paybtn,
  completed,
  startCBtn,
  joinbtn,
  continuebtn,
  onCourseClick,
  demoBtn,
  isEnrollCourse,
  age,
  type,
  type2,
  type3,
  board,
  grade,
  demoFree,
  demoPaid,
  joinNow,
  joinNowDisabled,
  restartBtn,
}) => {
  const [showModalHold, setShowModalHold] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const handleButtonClickHold = () => {
    setShowModalHold(true);
  };

  const handleCloseModelHold = () => {
    setShowModalHold(false);
  };

  const HandleCancel = () => {
    setFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModal(false);
  };

  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          onClick={onCourseClick ? onCourseClick : null}
          className="cource_img cursor-pointer rounded-3 px-3"
          style={{ width: "90%" }}
          src={img}
          alt=""
        />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
        </div>

        <h5 className="mt-3 px-3">
          Learn Mandarin - Beginner <br /> Level
        </h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
          </div>
          <p className="m-0 text-black">Robert Fox</p>

          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        {badge && (
          <div className="d-flex align-items-center justify-content-between mt-3 px-3 pb-3">
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Online
            </span>
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Paid
            </span>

            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Fixed
            </span>
          </div>
        )}

        <div className="d-flex px-2 align-items-center  justify-content-between mt-2">
          <div className="d-flex flex-wrap gap-3">
            {age && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {age}
              </div>
            )}
            {type && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {type}
              </div>
            )}
            {type2 && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {type2}
              </div>
            )}
            {type3 && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {type3}
              </div>
            )}
            {grade && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {grade}
              </div>
            )}
            {board && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {board}
              </div>
            )}
          </div>
        </div>
        {completed && (
          <div className="w-100 d-flex justify-content-end ">
            <span
              className="badge text-black me-3"
              style={{
                background: "var(--yellow-color)",
                height: "fit-content",
              }}
            >
              36% Completed
            </span>
          </div>
        )}

        <div
          className={`${holdbtn ? "bottom" : ""} ${paybtn ? "bottom" : ""} ${
            startCBtn ? "bottom" : ""
          } mt-2 py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {holdbtn && (
            <button className="py-1 px-3" onClick={handleButtonClickHold}>
              hold
            </button>
          )}
          {paybtn && <button className="py-1 px-3">pay</button>}
          {demoBtn && <button className="py-1 px-3">Book Demo</button>}
          {startCBtn && <button className="py-1 px-3">Start Course</button>}
        </div>

        {joinbtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Join Now</button>
          </div>
        )}

        {continuebtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Continue</button>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
          </p>
        </div>

        {demoFree && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Take a Free Demo
            </button>
          </div>
        )}

        {restartBtn && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Restart Now
            </button>
          </div>
        )}

        {joinNow && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Join Now
            </button>
          </div>
        )}

        {joinNowDisabled && (
          <div className="h-100 w-100">
            <button
              disabled
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Join Now
            </button>
          </div>
        )}

        {/* {demoPaid && (
          <div className="h-100 border-top  px-3 py-2 w-100 d-flex align-items-center gap-2">
            <button className="py-1 px-2">Enrol Now</button>
            <button className="py-1 px-2" onClick={() => HandleCancel()}>
              Cancel
            </button>
          </div>
        )} */}

        {demoPaid && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Book a Demo (₹ 100 )
            </button>
          </div>
        )}
      </div>

      <Modal
        show={showModalHold}
        onHide={handleCloseModelHold}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hold Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to hold this class?</p>
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date
            </label>
            <input type="date" className="form-control" id="start-date" />
          </div>
          <div className="mb-3">
            <label htmlFor="end-date" className="form-label">
              End Date
            </label>
            <input type="date" className="form-control" id="end-date" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseModelHold}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseModelHold}>
            Hold
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={feedbackModal}
        onHide={handleCloseFeedbackModal}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Feedback</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseFeedbackModal}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseFeedbackModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
