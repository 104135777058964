import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { IoPricetagsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";
import Swal from 'sweetalert2';

const RatePage = (props) => {
    const { history } = props;
    const [selectedOption, setSelectedOption] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedTag, setSelectedTag] = useState('');
    const [tags, setTags] = useState([
        { id: 1, name: 'Tag 1' },
        { id: 2, name: 'Tag 2' },
        { id: 3, name: 'Tag 3' }
    ]);

    const token = useSelector(state => state.token.value)
    const [rate, setRate] = useState([]);
    const [allCourses, setAllCourses] = useState([])
    const [allQualification, setAllQualification] = useState([])
    const [allGrades, setAllGrades] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedQualification, setSelectedQualification] = useState('')
    const [selectedGrade, setSelectedGrade] = useState('')
    const [enteredAmount, setEnteredAmount] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false);
    const [editId, setEditId] = useState('')
    const [filteredRate, setFilteredRate] = useState([]);
    const [editStatus, setEditStatus] = useState('')

    useEffect(() => {
        setFilteredRate(rate)
    }, [rate])


    // Handle change in the select input to filter rates
    const handleOptionChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);

        if (value === 'active') {
            setFilteredRate(rate.filter((item) => item.status === 'active'));
        } else if (value === 'inactive') {
            setFilteredRate(rate.filter((item) => item.status === 'inactive'));
        } else {
            setFilteredRate(rate); // Show all
        }
    };

    const fetchAllRateRecords = async (token) => {
        try {
            setDataLoading(true)
            let res = await MyAPI.GET(`/common/price-suggestion`, token)
            let { res_type, message, data } = res.data || res
            if (res_type === 'success') {
                setRate(data)
            } else {
                toast.error(message || 'Error Fetching Records.')
            }
            console.log('records', res.data)
        } catch (error) {
            toast.error(error.message)
        } finally {
            setDataLoading(false)
        }
    }

    const fetchRecordsData = async (token, record) => {
        try {
            let res = await MyAPI.GET(`/common/records?record_type=${record}`, token)
            let { res_type, message, data } = res.data || res
            if (res_type === 'success') {
                if (record === 'course') {
                    setAllCourses(data)
                } else if (record === 'qualification') {
                    setAllQualification(data)
                } else if (record === 'grade') {
                    setAllGrades(data)
                }
            } else {
                toast.error(message || 'Error Fetching Records.')
            }
            console.log('records', res.data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        if (token) {
            fetchAllRateRecords(token)
            fetchRecordsData(token, 'course')
            fetchRecordsData(token, 'qualification')
            fetchRecordsData(token, 'grade')
        }
    }, [token])

    const [isEdit, setIsEdit] = useState(false);


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleTagClick = () => {
        // setSelectedTag(tag);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setIsEdit(false);
        setEnteredAmount('')
        setSelectedCourse(null)
        setSelectedGrade(null)
        setSelectedQualification(null)
        setShowModal(false);
    };

    const handleAddTag = () => {
        // Implement functionality to add tag to course or profile
        setShowModal(false);
    };

    const handleEditClick = (rate) => {
        setIsEdit(true);
        setSelectedCourse(rate.course)
        setSelectedGrade(rate.grade)
        setSelectedQualification(rate.qualification)
        setEnteredAmount(rate.amount)
        setEditId(rate._id)
        setEditStatus(rate.status)
        setShowModal(true);
    };

    const handleDeleteClick = async (id) => {
        // Show SweetAlert2 confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await MyAPI.DELETE(`/common/price-suggestion?id=${id}`, token);
                    const { res_type, message } = res.data || res;
                    if (res_type === 'success') {
                        toast.success('Your rate has been deleted.')
                        setRate((prevRates) => prevRates.filter((rate) => rate._id !== id)); // Remove from state
                    } else {
                        toast.error(message || "Error deleting rate.");
                    }
                } catch (error) {
                    toast.error(error.message || "Failed to delete rate.");
                }
            }
        });
    };

    const handleAddRate = async () => {
        try {
            // validate options
            if (!selectedCourse) {
                return toast.warn('Please select a course.')
            }
            if (!selectedGrade) {
                return toast.warn('Please select a grade.')
            }
            if (!selectedQualification) {
                return toast.warn('Please select a qualification.')
            }
            if (!enteredAmount) {
                return toast.warn('Please enter a amount.')
            }
            setBtnLoading(true)
            let payload = {
                "course": selectedCourse,
                "grade": selectedGrade,
                "qualification": selectedQualification,
                "amount": enteredAmount // number
            }
            if (isEdit) {
                payload.status = editStatus;
            }
            let res;
            if (isEdit) {
                res = await MyAPI.PUT(`/common/price-suggestion?id=${editId}`, payload, token)
            } else {
                res = await MyAPI.POST('/common/price-suggestion', payload, token)
            }
            let { res_type, message, data } = res.data || res
            if (res_type === 'success') {
                toast.success(message)
                // reset form
                setEnteredAmount('')
                setSelectedCourse(null)
                setSelectedGrade(null)
                setSelectedQualification(null)
                setIsEdit(false)
                handleCloseModal()
                fetchAllRateRecords(token)
            } else {
                toast.error(message || 'Error Adding Rate Calculator.')
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <OperationLayout>
            <h5>Manage Rate Calculator</h5>
            {/* <OperationLocation path={history} /> */}
            <Row className="justify-content-between">
                <Col md={3}>
                    <Form.Group controlId="selectOption">
                        <Form.Select
                            value={selectedOption}
                            onChange={handleOptionChange}
                            className="shadow rounded-3 mt-3"
                        >
                            <option value="all">All</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4} className='mt-3 d-flex align-items-center justify-content-end'>
                    <Button onClick={handleTagClick} className="border-primary text-primary" variant="outlined" startIcon={<IoPricetagsOutline />}>
                        Add Rate
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3 mb-3"><h6>Rate Calculator</h6></Row>
            <Table striped responsive bordered hover>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Course Name</th>
                        <th>Grade</th>
                        <th>Qualification</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th colSpan={2}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Sample Data Rows */}
                    {
                        filteredRate && !dataLoading && filteredRate.length > 0 && filteredRate.map((rate, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{rate.course}</td>
                                <td>{rate.grade}</td>
                                <td>{rate.qualification}</td>
                                <td>₹{rate.amount}</td>
                                <td>
                                    {rate.status === 'active' ? (
                                        <Badge bg="success">Active</Badge>
                                    ) : (
                                        <Badge bg="danger">Inactive</Badge>
                                    )}
                                </td>
                                <td>
                                    <Button onClick={() => handleEditClick(rate)} variant="primary" size="sm">Edit</Button>{' '}
                                </td>
                                <td>
                                    <Button onClick={() => handleDeleteClick(rate._id)} variant="danger" size="sm">Delete</Button>
                                </td>
                            </tr>
                        ))
                    }
                    {
                        dataLoading && <tr><td colSpan="7" className="text-center">Loading...</td></tr>
                    }
                    {
                        !dataLoading && filteredRate && filteredRate.length === 0 && (
                            <tr><td colSpan="7" className="text-center">No Data Found</td></tr>
                        )
                    }
                </tbody>
            </Table>

            {/* Modal for Adding Tags */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg" style={{ zIndex: '1111', marginTop: '20vh', marginLeft: '5vw' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit' : 'Add'} Rate Calculator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className="mt-3">
                            <Form.Group controlId="selectOption">
                                {/* <Form.Label>Select Grade</Form.Label> */}
                                <Form.Select value={selectedGrade} onChange={e => setSelectedGrade(e.target.value)}>
                                    <option value={'N/A'}>Select Grade</option>
                                    {
                                        allGrades && allGrades.length > 0 && allGrades.map((item, index) => (
                                            <option selected={selectedGrade === item.title} key={index} value={item.title}>{item.title}</option>
                                        ))
                                    }
                                    {
                                        allGrades && allGrades.length === 0 && (
                                            <option>No Data Found.</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mt-3">
                            <Form.Group controlId="selectOption">
                                {/* <Form.Label>Select Course</Form.Label> */}
                                <Form.Select value={selectedCourse} onChange={e => setSelectedCourse(e.target.value)}>
                                    <option value={'N/A'} >Select Course</option>
                                    {
                                        allCourses && allCourses.length > 0 && allCourses.map((item, index) => (
                                            <option selected={selectedCourse === item.title} key={index} value={item.title}>{item.title}</option>
                                        ))
                                    }
                                    {
                                        allCourses && allCourses.length === 0 && (
                                            <option>No Data Found.</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={6} className="mt-3">
                            <Form.Group controlId="selectOption">
                                {/* <Form.Label>Select Qualification</Form.Label> */}
                                <Form.Select value={selectedQualification} onChange={e => setSelectedQualification(e.target.value)} >
                                    <option value={'N/A'} >Select Qualification</option>
                                    {
                                        allQualification && allQualification.length > 0 && allQualification.map((item, index) => (
                                            <option selected={selectedQualification === item.title} key={index} value={item.title}>{item.title}</option>
                                        ))
                                    }
                                    {
                                        allQualification && allQualification.length === 0 && (
                                            <option>No Data Found.</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={6} className="mt-3">
                            <Form.Group controlId="selectOption">
                                <Form.Control value={enteredAmount} onChange={e => setEnteredAmount(e.target.value)} type="text" placeholder="Enter Amount" />
                            </Form.Group>
                        </Col>
                        {
                            isEdit && (
                                <Col md={6}>
                                    <Form.Group controlId="selectOption">
                                        <Form.Select
                                            value={editStatus}
                                            onChange={e => setEditStatus(e.target.value)}
                                            className="mt-3"
                                        >
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            )
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button disabled={btnLoading} onClick={handleAddRate} variant="primary">
                        {isEdit ? (btnLoading ? 'Updating...' : 'Update') : (btnLoading ? 'Adding...' : 'Add')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </OperationLayout>
    );
};

export default RatePage;
