import React, { useEffect, useRef, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Badge, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";
import styled from "styled-components";
import { Button } from "@mui/material";
import Chart from "chart.js/auto";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";

const EmployeePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [isAttandanceView, setAttandanceView] = useState(false);
  const [show2, setShow2] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [attendanceId, setAttendanceId] = useState("");
  const [role, setRole] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const token = useSelector((state) => state.token.value);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (emp) => {
    setCurrentEmployee(emp);
    setShow2(true);
  };
  const handleButtonClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleApplyFilter = async () => {
    try {
      const res = await axios.get(
        `/attendance?id=${attendanceId}&role=${role}&attendance_status=${attendanceStatus}&date_from=${dateFrom}&date_to=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.res_type === "success") {
        setEmployees(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("emp", employees);

  const handleAttendanceChange = (employeeId, status) => {
    setAttendance((prev) => ({
      ...prev,
      [employeeId]: prev[employeeId] === status ? "" : status,
    }));
  };

  const handleSubmit = async () => {
    const attendanceData = employees.map((employee) => ({
      id: employee?._id,
      role: employee?.role?.toUpperCase() || "TEACHER",
      status: attendance[employee.uid] || "absent",
    }));

    try {
      setLoading(true);
      const res = await axios.post(
        "/attendance",
        {
          user_list: attendanceData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (show2 && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      // Mock data for attendance, replace with actual data
      const attendanceData = [
        12, 15, 18, 20, 17, 16, 19, 20, 22, 23, 24, 21, 18, 19, 20, 21, 22, 23,
        24, 25, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ];
      const labels = Array.from(
        { length: attendanceData.length },
        (_, i) => i + 1
      );
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Attendance",
              data: attendanceData,
              backgroundColor: "rgba(192, 174, 75, 0.2)",
              borderColor: "#c0a14b",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              title: {
                display: true,
                text: "Day of the Month",
              },
            },
          },
        },
      });
    }
  }, [show2]);

  // console.log(employees);

  useEffect(() => {
    handleApplyFilter();
  }, [attendanceId, role, attendanceStatus, dateFrom, dateTo, token]);

  return (
    <OperationLayout>
      <h5>Employees</h5>
      <Row className="justify-content-between">
        <Col md={8}></Col>
        <Col
          md={4}
          className="mt-3 d-flex align-items-center justify-content-end"
        >
          <Button
            onClick={handleButtonClick}
            className="rounded-3 bg-warning text-white border-warning"
            variant="outlined"
          >
            Add Employee
          </Button>
          <Button
            onClick={() => setAttandanceView(!isAttandanceView)}
            className={
              isAttandanceView
                ? "rounded-3 bg-danger text-white border-danger ms-3"
                : "rounded-3 bg-warning text-white border-warning ms-3"
            }
            variant="outlined"
          >
            {" "}
            {isAttandanceView ? "Cancel Attendance" : "Take Attendance"}{" "}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by I'D"
              value={attendanceId}
              onChange={(e) => setAttendanceId(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by Name"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by Email"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select
              className="shadow rounded-3 mt-3"
              onChange={(e) => setRole(e.target.value)}
            >
              <option value={""}>select role</option>
              <option value={"ADMIN"}>Admin</option>
              <option value={"OPERATION"}>Operation</option>
              <option value={"ACCOUNT"}>Account</option>
              <option value={"TEACHER"}>Teacher</option>
              <option value={"PARENT"}>Parent</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select
              className="shadow rounded-3 mt-3"
              onChange={(e) => setAttendanceStatus(e.target.value)}
              value={attendanceStatus}
            >
              <option value={""}>select live status</option>
              <option value={"present"}>Present</option>
              <option value={"absent"}>Absent</option>
              <option value={"half_day"}>Half Day</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        style={{ zIndex: "1111", marginTop: "10vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Control type="text" placeholder="Enter First Name" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Control type="text" placeholder="Enter Last Name" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Control type="email" placeholder="Enter Email ID" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Control type="tel" placeholder="Enter Mobile" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Control type="text" placeholder="Enter Position" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Control type="text" placeholder="Enter Salary" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Label>Joining Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Email ID" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={6}>
              <Form.Group controlId="nameInput">
                <Form.Label>Upload document for verify</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={12}>
              <Form.Group controlId="textareaInput">
                <Form.Select className="rounded-3">
                  <option selected disabled>
                    Assign Role
                  </option>
                  <option value="1">Account handle</option>
                  <option value="2">Operation handle</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={12}>
              <Form.Group controlId="textareaInput">
                <Form.Control
                  className="rounded-3"
                  as="textarea"
                  rows={3}
                  placeholder="Enter Address..."
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white border-danger me-3"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button variant="outlined">Add</Button>
        </Modal.Footer>
      </Modal>

      {!isAttandanceView && (
        <>
          <Row className="mt-3 mb-3">
            {/* <h5>Search Result ( 3 )</h5> */}
            <h5>All Employees</h5>
          </Row>

          <StyleContainer>
            {employees.length > 0 ? (
              employees.map((employee, index) => (
                <div
                  key={index}
                  className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-4 `}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="review_user_image">
                        <img src={employee?.user_id?.image_key} alt="" />
                      </div>
                      <div className="ms-5">
                        <h6 className="m-0 fw-bold">
                          {employee?.firstName && employee?.lastName
                            ? `${employee?.user_id?.firstName} ${employee?.user_id?.lastName}`
                            : employee?.user_id?.name}
                        </h6>
                        <p>12 January 2023</p>
                      </div>
                      <Badge
                        className="ms-3"
                        bg={
                          employee?.user_id?.status === "active"
                            ? "success"
                            : employee?.user_id?.status === "pending"
                            ? "warning"
                            : "danger"
                        }
                      >
                        {employee?.user_id?.status}
                      </Badge>
                    </div>
                    <p
                      className="m-0 fw-bold text_yellow"
                      onClick={() => handleShow2(employee)}
                      style={{ cursor: "pointer" }}
                    >
                      view <FaAngleRight />
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <h5 className="my-5 text-center">No Employees Found</h5>
            )}
          </StyleContainer>
        </>
      )}

      {isAttandanceView && (
        <>
          <Row className="mt-3 mb-3">
            <h5>Employee Attendance</h5>
          </Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SN.</th>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Role</th>
                <th>Present full day</th>
                <th>Present half day</th>
              </tr>
            </thead>
            <tbody>
              {employees &&
                employees.map((employee, index) => (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{employee?.user_id?.uid}</td>
                    <td>
                      {employee?.user_id?.firstName &&
                      employee?.user_id?.lastName
                        ? `${employee?.user_id?.firstName} ${employee?.user_id?.lastName}`
                        : employee?.user_id?.name}
                    </td>
                    <td>
                      {employee?.user_id?.role?.toUpperCase() || "TEACHER"}
                    </td>
                    <td>
                      <Form.Check
                        size={32}
                        type="checkbox"
                        checked={
                          attendance[employee.user_id?.uid] === "present"
                        }
                        onChange={() =>
                          handleAttendanceChange(
                            employee.user_id?.uid,
                            "present"
                          )
                        }
                        disabled={
                          attendance[employee.user_id?.uid] === "half_day"
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        size={32}
                        type="checkbox"
                        checked={
                          attendance[employee.user_id?.uid] === "half_day"
                        }
                        onChange={() =>
                          handleAttendanceChange(
                            employee.user_id?.uid,
                            "half_day"
                          )
                        }
                        disabled={
                          attendance[employee.user_id?.uid] === "present"
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Button
            variant="outlined"
            className="rounded-3"
            onClick={handleSubmit}
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </>
      )}

      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        style={{ zIndex: 99999 }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <h6>Employee Name :</h6>
              <h6>Employee ID :</h6>
              <h6>Date of Joining :</h6>
              <h6>Phone :</h6>
              <h6>Email :</h6>
              <h6>Designation :</h6>
              <h6>Salary :</h6>
              <h6>Incentive :</h6>
              <h6>Address :</h6>
              <h6>Date of Birth :</h6>
              <h6>Role :</h6>
            </Col>
            <Col sm={6}>
              <h6>
                {currentEmployee?.firstName && currentEmployee?.lastName
                  ? `${currentEmployee?.firstName} ${currentEmployee?.lastName}`
                  : currentEmployee?.name}
              </h6>
              <h6>{currentEmployee?.uid}</h6>
              <h6>
                {currentEmployee?.joining_date
                  ? moment(currentEmployee?.joining_date).format("DD MMM YYYY")
                  : "N/A"}
              </h6>
              <h6>{currentEmployee?.phone || "N/A"}</h6>
              <h6>{currentEmployee?.email || "N/A"}</h6>
              <h6>{currentEmployee?.designation || "N/A"}</h6>
              <h6>{currentEmployee?.salary || "N/A"}</h6>
              <h6>{currentEmployee?.incentive || "N/A"}</h6>
              <h6>
                {currentEmployee?.Aaddress || currentEmployee?.address || "N/A"}
              </h6>
              <h6>{currentEmployee?.dob || "N/A"}</h6>
              <h6>{currentEmployee?.role?.toUpperCase() || "TEACHER"}</h6>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>Verified Documents</h6>
              {/* <span>Document A</span> <br />
              <span>Document B</span> */}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>Joining Latter</h6>
              {/* <span>Document A</span> */}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>CV</h6>
              {/* <span>Document A</span> */}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>Attendence</h6>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Present</th>
                    <th>Absent</th>
                    <th>Half Day</th>
                  </tr>
                </thead>

                <tbody>
                  {/* <tr>
                    <td>1</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>--</td>
                    <td>Yes</td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>--</td>
                    <td>--</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td>6</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <h6>Complaints</h6>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Complaint Id</th>
                    <th>Complaint</th>
                    <th>Complaint Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>1</td>
                    <td>123456</td>
                    <td>Complaint</td>
                    <td>12 January 2023</td>
                    <td>
                      <button
                        className="btn btn-sm rounded-5"
                        style={{
                          backgroundColor: "var(--color-primary)",
                          color: "white",
                        }}
                      >
                        Forward to Admin
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>123456</td>
                    <td>Complaint</td>
                    <td>12 January 2023</td>
                    <td>
                      <button
                        className="btn btn-sm rounded-5"
                        style={{
                          backgroundColor: "var(--color-primary)",
                          color: "white",
                        }}
                      >
                        Forward to Admin
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>123456</td>
                    <td>Complaint</td>
                    <td>12 January 2023</td>
                    <td>
                      <button
                        className="btn btn-sm rounded-5"
                        style={{
                          backgroundColor: "var(--color-primary)",
                          color: "white",
                        }}
                      >
                        Forward to Admin
                      </button>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose2}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </OperationLayout>
  );
};

const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default EmployeePage;
