import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import Dropzone from "./Dropzone.jsx";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "../../utils/axios.js";
import Swal from "sweetalert2";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout.jsx";
import { Row, Col, Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./ParentRegistration.css";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter.js";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import LearningDifficultiesInput from "./LearningDifficultiesInput.jsx";
import { useSelector } from "react-redux";
import { getCommonInfo } from "../../utils/MyAPI.js";
function ParentRegistration() {
  const [currentStep, setCurrentStep] = useState(1);
  const [HowToYouReferHere, setHowToYouReferHere] = useState("");
  const [AnySuggestion, setAnySuggestion] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleName, setVisibleName] = useState("");
  const [wpNumber, setWpNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [currency, setCurrency] = useState("");
  const [timezone, setTimezone] = useState("");
  const [communicationMode, setCommunicationMode] = useState("");
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    const fetchParentInfo = async () => {
      try {
        const { data } = await getCommonInfo(token);
        // console.log("Parent info: ", data);
        if (data.res_type === "success") {
          setUserData(data.data);
          setFirstName(data.data.firstName);
          setLastName(data.data.lastName);
          setEmail(data.data.email);
          setPhone(data.data.phone);

          if (data.data.profileState.isRegistered === true) {
            setCurrentStep(2);
          }
          if (data.data.profileState.hasChildren === true) {
            // navigate("/parent/dashboard");
            setCurrentStep(3);
          }
        }
      } catch (error) {
        console.log("Error fetching parent info: ", error);
      }
    };
    fetchParentInfo();
  }, [token]);

  // console.log("userData:", userData);

  useEffect(() => {
    if (userData?.profileState?.isRegistered) {
      setCurrentStep(2);
    }
    if (userData?.profileState?.hasChildren) {
      navigate("/parent/dashboard");
    }
  }, [userData, currentStep]);

  // useEffect(() => {
  //   if (currentStep === 3) {
  //     navigate("/parent/dashboard");
  //   }
  // }, [userData, currentStep]);

  const initialChildState = {
    firstName: "",
    lastName: "",
    age: "",
    selectedDate: null,
    gender: "",
    standard: "",
    board: "",
    difficulties: [],
    files: [],
    displayName: "",
    currentSchoolName: "",
    hobby: "",
    interest: "",
    favSubject: "",
    weekSubject: "",
  };

  const handleParentRegistration = async () => {
    try {
      switch (true) {
        case visibleName === "":
          toast.error("Visible name is required");
          return;
        case country === "":
          toast.error("Country is required");
          return;
        case pinCode === "":
          toast.error("Pin code is required");
          return;
        case city === "":
          toast.error("City is required");
          return;
        case address === "":
          toast.error("Address is required");
          return;
        case currency === "":
          toast.error("Currency is required");
          return;
        case communicationMode === "":
          toast.error("Communication mode is required");
          return;
        case timezone === "":
          toast.error("Timezone is required");
          return;
      }

      setLoading(true);
      const response = await axios.put("/parent/register", {
        id: userData._id,
        visibleName: visibleName,
        country: country,
        pincode: pinCode,
        city: city,
        address: address,
        currency: currency,
        communicationMode: communicationMode,
        timZone: timezone,
      });

      // console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
        nextStep();
      } else {
        toast.error("Parent registration failed. please try again.");
      }
    } catch (error) {
      console.error("Error registering parent:", error);
    } finally {
      setLoading(false);
    }
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const [children, setChildren] = useState([initialChildState]);

  const handleAddChild = () => {
    setChildren([...children, { ...initialChildState }]);
  };

  const handleNext = () => {
    nextStep();
  };

  const handleInputChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  const handleDateChange = (index, date) => {
    const updatedChildren = [...children];
    updatedChildren[index]["selectedDate"] = date;
    setChildren(updatedChildren);
  };

  //###===>> as due to added field in files the backend multer is not able to see it as binary
  const handleFileChange = (index, droppedFiles) => {
    const updatedChildren = [...children];
    // const processedFiles = droppedFiles.map((file) => ({
    //   ...file,
    //   preview: URL.createObjectURL(file),
    // }));
    // updatedChildren[index]["files"] = processedFiles;
    updatedChildren[index]["files"] = droppedFiles;
    setChildren(updatedChildren);
  };

  const handleAddDifficulty = (index, newDifficulties) => {
    const updatedChildren = [...children];
    updatedChildren[index]["difficulties"] = [...newDifficulties];
    setChildren(updatedChildren);
  };

  const handleRemoveDeficulties = (childIndex, difficultiesIndex) => {
    const updatedChildren = [...children];
    updatedChildren[childIndex]["difficulties"].splice(difficultiesIndex, 1);
    setChildren(updatedChildren);
  };

  const handleRegistration = () => {
    navigate("/parent/dashboard");
    // Show SweetAlert confirmation
    Swal.fire({
      icon: "success",
      title: "Registration Successful!",
      text: "Sawal: Have you received your confirmation email?",
    });
  };

  const handleAddChildren = async () => {
    const formData = new FormData();
    let data_arr = [];

    children.forEach((child, index) => {
      if (child.files && child.files.length > 0) {
        console.log(`Appending file for child ${index}:`, child.files[0]);
        formData.append("image", child.files[0]);
      } else {
        console.warn(`No file found for child ${index}`);
      }
      data_arr.push({
        firstName: child.firstName,
        lastName: child.lastName,
        dob: child.selectedDate,
        age: child.age,
        favoriteSubject: child.favSubject,
        weekSubject: child.weekSubject,
        gender: child.gender,
        standard: child.standard,
        board: child.board,
        learningDeficulties: "None",
        displayedName: child.displayName,
        currentSchool: child.currentSchoolName,
        hobby: child.hobby,
        interest: child.interest,
        courses: [],
      });
    });

    formData.append("childrenData", JSON.stringify(data_arr));

    // Log FormData content
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: `, pair[1]);
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `/parent/addChildren/${userData._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response.data);

      // console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
        setCurrentStep(currentStep + 1);
        navigate("/parent/dashboard");
        Swal.fire({
          icon: "success",
          title: "Registration Successful!",
          text: "Sawal: Have you received your confirmation email?",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding children");
      console.error("Error adding children:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OftadehLayout>
      <h1>Registration</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Col
        sm={12}
        md={12}
        className="mb-md-5 mb-3 d-flex align-items-center justify-content-between"
      >
        <Col sm={4} md={4} className="m-0 p-0 ps-2 step-container">
          <div
            className={
              currentStep >= 1
                ? "step-box registration-status-here"
                : "step-box"
            }
          >
            1
          </div>
          <div
            className={
              currentStep >= 2
                ? "progress-line registration-status-here"
                : "progress-line"
            }
          ></div>
        </Col>
        <Col sm={4} md={4} className="m-0 p-0 step-container">
          <div
            className={
              currentStep >= 2
                ? "step-box registration-status-here"
                : "step-box"
            }
          >
            2
          </div>
          <div
            className={
              currentStep >= 3
                ? "progress-line registration-status-here"
                : "progress-line"
            }
          ></div>
        </Col>
        <Col sm={4} md={4} className="m-0 p-0 step-container">
          <div
            className={
              currentStep >= 3
                ? "step-box registration-status-here"
                : "step-box"
            }
          >
            3
          </div>
          <div
            className={
              currentStep >= 4
                ? "progress-line registration-status-here"
                : "progress-line"
            }
          ></div>
        </Col>
      </Col>

      {currentStep === 1 && (
        <Row className="d-flex mt-3">
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Parent I'D</label>
            <input
              disabled
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              value={userData?.uid}
            />
          </div>
          <div class="form-group col-md-4 mt-sm-3">
            <label for="FirstName">first name</label>
            <input
              disabled
              value={firstName}
              type="text"
              class="form-control border-yellow rounded-5"
              id="FirstName"
              placeholder="First Name"
            />
          </div>
          <div class="form-group col-md-4 mt-sm-3">
            <label for="LastName">last name</label>
            <input
              disabled
              value={lastName}
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              placeholder="Last Name"
            />
          </div>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Visible Name</label>
            <input
              type="text"
              class="form-control border-yellow rounded-5"
              id="LastName"
              placeholder="Enter visible name"
              value={visibleName}
              onChange={(e) => setVisibleName(e.target.value)}
            />
          </div>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="inputEmail4">Email</label>
            <input
              disabled
              value={email}
              type="email"
              class="form-control  border-yellow rounded-5"
              id="inputEmail4"
              placeholder="Email"
            />
          </div>
          <Col md={4} className="mt-3 mb-3 ">
            <label for="phone">Phone</label>
            <div className="px-3 py-2 rounded-5 border-yellow">
              <PhoneInput
                international
                style={{ border: "none !important" }}
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={`${userData?.phone}`}
                disabled
              />
            </div>
          </Col>
          <Col md={4} className="mt-3 mb-3 ">
            <label for="phone">Whatsapp No.</label>
            <div className="px-3 py-2 rounded-5 border-yellow">
              <PhoneInput
                international
                style={{ border: "none !important" }}
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={`${userData?.whatsapp}`}
                onChange={setWpNumber}
              />
            </div>
          </Col>
          <Col md={4}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select Country</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                <option>Select</option>
                <option value="India">India</option>
                <option value="USA">USA</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Pin code</label>
            <input
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              placeholder="Enter pin code."
            />
          </div>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Enter City</label>
            <input
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              placeholder="Enter City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Enter Address</label>
            <input
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              placeholder="Enter Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <Col md={4} className="mt-md-3 mt-sm-3">
            <Form.Group controlId="selectOption">
              <Form.Label>Select currency</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setCurrency(e.target.value)}
                value={currency}
              >
                <option value="">Select</option>
                <option value="INR">INR</option>
                <option value="USD">USD</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="mt-md-3 mt-sm-3">
            <Form.Group controlId="selectOption">
              <Form.Label>Select Communication mode.</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setCommunicationMode(e.target.value)}
                value={communicationMode}
              >
                <option value="">Select</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
                <option value="Whatsapp">Whatsapp</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="mt-md-3 mt-sm-3">
            <Form.Group controlId="selectOption">
              <Form.Label>Select Timezone</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setTimezone(e.target.value)}
                value={timezone}
              >
                <option value="">Select</option>
                <option value="Asian">Asian / Calcutta</option>
                <option value="European">European / London</option>
                <option value="American">American / New York</option>
                <option value="Pacific">Pacific / Honolulu</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <div class="form-group mt-4 mb-3 mt-sm-3">
            <div class="form-check">&nbsp;</div>
          </div>
          <button
            onClick={handleParentRegistration}
            type="button"
            style={{ width: "fit-content" }}
            class="ms-2 btn teacher-button rounded-5 text-white border-0"
          >
            {loading ? "Loading..." : "Next"}
          </button>
        </Row>
      )}

      {currentStep === 2 && (
        <Form onSubmit={handleAddChildren}>
          {children.map((child, index) => (
            <Row className="mt-3" key={index}>
              <Col md={12}>
                {" "}
                <h4>Child {index + 1} Details</h4>
              </Col>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="FirstName">First name</label>
                <input
                  value={child.firstName}
                  onChange={(e) =>
                    handleInputChange(index, "firstName", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="FirstName"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="LastName">Last name</label>
                <input
                  value={child.lastName}
                  onChange={(e) =>
                    handleInputChange(index, "lastName", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="LastName"
                  placeholder="Last Name"
                  required
                />
              </div>
              <Col className="mt-sm-3" md={4}>
                <Form.Group controlId="nameInput">
                  <Form.Label>Date of birth</Form.Label>
                  <Form.Control
                    className="border-yellow rounded-5"
                    type="date"
                    value={child.selectedDate}
                    onChange={(e) => handleDateChange(index, e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Age">Age</label>
                <input
                  value={child.age}
                  onChange={(e) =>
                    handleInputChange(index, "age", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="Age"
                  placeholder="Age"
                  required
                />
              </div>
              <Col md={4} className="mt-3">
                <Form.Group controlId="selectOption">
                  <Form.Label>Favorite Subject</Form.Label>
                  <Form.Select
                    className="rounded-5 border-yellow"
                    value={child.favSubject}
                    onChange={(e) =>
                      handleInputChange(index, "favSubject", e.target.value)
                    }
                    required
                  >
                    <option value="">Select</option>
                    <option value="Hindi">Hindi</option>
                    <option value="English">English</option>
                    <option value="Maths">Maths</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="selectOption">
                  <Form.Label>Week Subject</Form.Label>
                  <Form.Select
                    className="rounded-5 border-yellow"
                    value={child.weekSubject}
                    onChange={(e) =>
                      handleInputChange(index, "weekSubject", e.target.value)
                    }
                    required
                  >
                    <option value="">Select</option>
                    <option value="Hindi">Hindi</option>
                    <option value="English">English</option>
                    <option value="Maths">Maths</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="gender">Gender</label>
                <select
                  value={child.gender}
                  onChange={(e) =>
                    handleInputChange(index, "gender", e.target.value)
                  }
                  className="form-control border-yellow rounded-5"
                  id="gender"
                  required
                >
                  <option value="" disabled>
                    Select gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="standard">Standard</label>
                <select
                  value={child.standard}
                  onChange={(e) =>
                    handleInputChange(index, "standard", e.target.value)
                  }
                  className="form-control border-yellow rounded-5"
                  id="standard"
                  required
                >
                  <option value="" disabled>
                    Select Standard
                  </option>
                  <option value="8th">8th</option>
                  <option value="7th">7th</option>
                  <option value="6th">6th</option>
                </select>
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Board">Board</label>
                <select
                  value={child.board}
                  onChange={(e) =>
                    handleInputChange(index, "board", e.target.value)
                  }
                  className="form-control border-yellow rounded-5"
                  id="Board"
                  required
                >
                  <option value="" disabled>
                    Select Board
                  </option>
                  <option value="CBSE">CBSE</option>
                  <option value="ICSE">ICSE</option>
                  <option value="State">State</option>
                </select>
              </div>

              {/* <div className="mt-2">
                <LearningDifficultiesInput
                  onAddDifficulty={(newDifficulties) =>
                    handleAddDifficulty(index, newDifficulties)
                  }
                  difficulties={child.difficulties}
                />
                <div className="d-flex gap-2 mt-2">
                  {child.difficulties.map((difficulty, key) => (
                    <span
                      className="px-2 py-1 rounded-3 border d-flex align-items-center"
                      key={key}
                    >
                      {difficulty}
                      <IoMdCloseCircle
                        onClick={() => handleRemoveDeficulties(index, key)}
                        color="red"
                        className="cursor-pointer"
                        size={22}
                      />
                    </span>
                  ))}
                </div>
              </div> */}

              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="DisplayName">Displayed name</label>
                <input
                  value={child.displayName}
                  onChange={(e) =>
                    handleInputChange(index, "displayName", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="DisplayName"
                  placeholder="Display Name"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="currentSchoolName">Current School</label>
                <input
                  value={child.currentSchoolName}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "currentSchoolName",
                      e.target.value
                    )
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="currentSchoolName"
                  placeholder="Current School Name"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Hobby">Hobby</label>
                <input
                  value={child.hobby}
                  onChange={(e) =>
                    handleInputChange(index, "hobby", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="Hobby"
                  placeholder="Hobby"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Interest">Interest</label>
                <input
                  value={child.interest}
                  onChange={(e) =>
                    handleInputChange(index, "interest", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="Interest"
                  placeholder="Interest"
                  required
                />
              </div>
              <Row className="mt-4">
                <span className="mb-2">Upload your child image</span>
                <br />
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleFileChange(index, acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  )}
                </Dropzone>

                <Col
                  md={6}
                  className="p-3"
                  style={{ minHeight: "20vh", overflowY: "auto" }}
                >
                  {child.files.length > 0 ? (
                    child.files.map((file) => (
                      <div className="pt-2 me-2" key={file.path}>
                        {file.preview && (
                          <img
                            className="rounded-2"
                            src={file.preview}
                            alt={file.path}
                            style={{ width: "50px", height: "50px" }}
                          />
                        )}
                        <span className="ms-2">{file.path}</span>
                      </div>
                    ))
                  ) : (
                    <p>No Files Uploaded.</p>
                  )}
                </Col>
              </Row>
            </Row>
          ))}
          <Row className="mt-3">
            <Button
              className="me-1 teacher-button text-white rounded-5 border-0 d-flex align-items-center justify-content-center"
              style={{ width: "fit-content" }}
              variant="primary"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              <GrCaretPrevious />
            </Button>
            <Button
              className="teacher-button rounded-5 border-0 text-white"
              style={{ width: "fit-content" }}
              variant="outline-primary"
              onClick={handleAddChild}
              disabled={children.length >= 4 ? true : false}
            >
              <IoAdd /> Add one more Child
            </Button>

            <Button
              className="teacher-button text-white rounded-5 ms-2 border-0"
              style={{ width: "fit-content" }}
              variant="primary"
              type="submit"
            >
              {loading ? "Loading..." : "Next"}
            </Button>
          </Row>
        </Form>
      )}

      {currentStep === 3 && (
        <Row className="mt-3">
          <Col md={12}>
            {" "}
            <h4>Additional Information</h4>
          </Col>
          <div className="form-group col-md-4 mt-sm-3">
            <label htmlFor="HowToYouReferHere">&nbsp;</label>
            <select
              value={HowToYouReferHere}
              id="HowToYouReferHere"
              onChange={(e) => setHowToYouReferHere(e.target.value)}
              className="form-control border-yellow rounded-5"
              style={{ width: "100%", height: "6vh" }}
            >
              <option value="" disabled selected>
                How did you hear about us.
              </option>
              <option value="good">Social Media</option>
              <option value="low">Offline Marketing</option>
              <option value="low">Online Marketing</option>
              <option value="poor">Friends</option>
              <option value="poor">Teachers</option>
              <option value="poor">Google Search</option>
              <option value="poor">Other</option>
            </select>
          </div>
          <Col className="mt-3" xs={12}>
            <textarea
              value={AnySuggestion}
              onChange={(e) => setAnySuggestion(e.target.value)}
              className="form-control border-yellow rounded-3"
              rows="4"
              placeholder="Enter any suggestion here..."
              style={{ width: "100%" }}
              required
            ></textarea>
          </Col>
          <div class="form-group mt-4 mb-3 mt-sm-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="termandservice"
                required
              />
              <label class="form-check-label" for="termandservice">
                accept term & service.
              </label>
            </div>
          </div>
          <Row className="ps-4 mt-3">
            <Button
              className="me-1 teacher-button text-white rounded-5 border-0 d-flex align-items-center justify-content-center"
              style={{ width: "fit-content" }}
              variant="primary"
              onClick={previousStep}
            >
              <GrCaretPrevious />
            </Button>
            <Button
              // onClick={handleSubmitData}
              onClick={handleRegistration}
              className="teacher-button text-white rounded-5 border-0"
              style={{ width: "fit-content" }}
              variant="primary"
            >
              Submit
            </Button>
          </Row>
        </Row>
      )}
      <ParentFooter />
    </OftadehLayout>
  );
}

export default ParentRegistration;
