import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { useNavigate, useParams } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import { Table, Row, Col, Form } from "react-bootstrap";
import { FaClipboardCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";

const AllSubjects = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const token = useSelector((state) => state.token.value);
    const [allHomeWork, setAllHomeWork] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [subject, setSubject] = useState("");
    const [status, setStatus] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const fetchAllHW = async (token, course_id) => {
        try {
            setDataLoading(true);
            let res = await MyAPI.GET(`/teacher/homework?course_id=${course_id}`, token);
            let { res_type, message, data: apiData } = res.data || res;
            if (res_type === "success") {
                setAllHomeWork(apiData);
            } else {
                toast.error(message || "Error fetching homework.");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        if (token && courseId) {
            fetchAllHW(token, courseId);
        }
    }, [token, courseId]);

    const handleSearch = () => {
        if (!subject || !status) {
            toast.error("Please select both subject and status.");
            return;
        }

        // You can perform search based on subject, status, and search term
        // If you need backend filtering, you can pass these params to the fetchAllHW function.
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options); // en-GB for day-month-year format
    };

    return (
        <TeacherLayout>
            <h3>Homework</h3>

            <Row className="mt-3">
                <Col md={3}>
                    <Form.Select
                        className="shadow rounded-5"
                        aria-label="Select subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    >
                        <option>Select Subject</option>
                        <option value="Mathematics">Mathematics</option>
                        <option value="History">History</option>
                        <option value="Physics">Physics</option>
                    </Form.Select>
                </Col>
                <Col md={3}>
                    <Form.Select
                        className="shadow rounded-5"
                        aria-label="Select status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option>Select Status</option>
                        <option value="Completed">Completed</option>
                        <option value="Pending">Pending</option>
                        <option value="Incomplete">Incomplete</option>
                    </Form.Select>
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                    <Form.Group controlId="searchBox">
                        <Form.Control
                            className="shadow rounded-5"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Table
                className="mt-4 mb-3 rounded-4"
                style={{ backgroundColor: "var(--color-primary)" }}
                striped
                responsive
                hover
            >
                <thead>
                    <tr className="text-center">
                        <th className="text-white">Title</th>
                        <th className="text-white">Subject</th>
                        <th className="text-white">Completed</th>
                        <th className="text-white">Pending</th>
                        <th className="text-white">Incomplete</th>
                        <th className="text-white">Deadline</th>
                        <th className="text-white">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {dataLoading ? (
                        <tr>
                            <td colSpan="7" className="text-center text-muted">
                                Loading...
                            </td>
                        </tr>
                    ) : allHomeWork && allHomeWork.length > 0 ? (
                        allHomeWork.map((course) => (
                            <tr className="text-center bg-light" key={course._id}>
                                <td>{course?.title}</td>
                                <td>{course?.course_id?.subject}</td>
                                <td>20 / 0</td>
                                <td>20 / 0</td>
                                <td>20 / 0</td>
                                <td>{formatDate(course.for_date)}</td> {/* Format the date here */}
                                <td>
                                    <FaClipboardCheck
                                        onClick={() =>
                                            navigate(`/teacher/HwandTest/${course.course_id._id}/${course._id}`)
                                        }
                                        className="cursor-pointer"
                                        size={22}
                                        color="green"
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center text-muted">
                                No Homework Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <ParentFooter />
        </TeacherLayout>
    );
};

export default AllSubjects;
