import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Button, IconButton } from "@mui/material";
import { TiBook } from "react-icons/ti";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import UserProfile from "../../assets/parent/profile-user.jpg";
import {
  CalendarToday,
  MyLocation,
  Star,
  StarHalfTwoTone,
  SupervisorAccount,
} from "@material-ui/icons";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import "./TeacherProfile.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SchoolIcon from "@mui/icons-material/School";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Card1 from "../../ParentPages/parent/Card1";
import card1 from "../../assets/parent/card1.png";
import {
  MdEdit,
  MdOutlineDeleteOutline,
  MdOutlineStarOutline,
  MdStarHalf,
} from "react-icons/md";
import {
  FaCalendarAlt,
  FaHeart,
  FaRegCalendarTimes,
  FaRegHeart,
  FaTrophy,
} from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { IoMdStar, IoMdTime } from "react-icons/io";
import ParentFooter from "../../components/ParentFooter";
import { useNavigate } from "react-router-dom";
import { IoSchoolOutline, IoStar, IoTimeSharp } from "react-icons/io5";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import axios from "../../utils/axios";
import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { GiPostOffice } from "react-icons/gi";
import { useSelector } from "react-redux";

const TeacherProfile = (props) => {
  const { history } = props;
  const baseUrl = "https://hariom-bucket.s3.ap-south-1.amazonaws.com/";

  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [subjectDetails, setSubjectDetails] = useState({});

  const handleShowSubjectModal = (grade) => {
    setShowSubjectModal(true);
    setSubjectDetails(grade);
  };
  const handleCloseSubjectModal = () => setShowSubjectModal(false);
  const [teacherData, setTeacherData] = useState({});
  const [coursesData, setCoursesData] = useState([]);
  const navigate = useNavigate();
  const [isAwardOpen, setIsAwardOpen] = useState(false);

  const token = useSelector((state) => state.token.value);

  // console.log(teacherData);

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const { data } = await axios.get("/teacher/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.res_type === "success") {
          setTeacherData(data.data.teacher);
          setCoursesData(data.data.courses);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeacherData();
  }, []);

  console.log(teacherData);

  return (
    <TeacherLayout>
      <h3>Teacher Profile</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row className="mt-3">
        <Col className="mt-3" md={6}>
          <Button
            className="border-0 rounded-5 n-box-shadow teacher-button"
            variant="contained"
          >
            Take my profile off grid
          </Button>
        </Col>
        <Col className="mt-3" md={6}>
          <Button
            className="border-0 rounded-5 n-box-shadow teacher-button"
            variant="contained"
          >
            View in User
          </Button>
          <Button
            className="border-0 rounded-5 n-box-shadow teacher-button ms-3"
            variant="contained"
            onClick={() => navigate("/teacher/TeacherProfile/edit")}
          >
            Edit
          </Button>
        </Col>
      </Row>
      <Container className="mt-3 p-3 n-box-shadow rounded-2 py-4">
        <Row>
          <Col md={6} className="d-flex flex-column ">
            <div className="d-flex gap-4 align-items-center flex-wrap">
              <div className="shadow rounded-pill">
                <img
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={teacherData?.image_key}
                  alt=""
                />
              </div>
              <div className="d-flex flex-column">
                <div className="like d-flex align-items-center flex-wrap">
                  <h5>
                    {teacherData?.firstName} {teacherData?.lastName}
                  </h5>
                  <IconButton className="m-0 p-0 ms-3" aria-label="delete">
                    <FaRegHeart />
                  </IconButton>
                  <sup className="ms-2">50+ </sup>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <Star style={{ color: "#FFD32D" }} />
                  <Star style={{ color: "#FFD32D" }} />
                  <Star style={{ color: "#FFD32D" }} />
                  <Star style={{ color: "#FFD32D" }} /> (4.5) Rating{" "}
                  <sup>250 +</sup>
                </div>
                <div className="d-flex align-items-center gap-2 mt-2">
                  <h5>
                    <Badge style={{ backgroundColor: "#3498db" }}>
                      {" "}
                      Recommended by learniee{" "}
                    </Badge>
                  </h5>
                  <h5>
                    <Badge style={{ backgroundColor: "#e74c3c" }} bg="danger">
                      {" "}
                      Top Teacher{" "}
                    </Badge>
                  </h5>
                </div>
                <div className="mt-2">
                  <DriveFileRenameOutlineIcon /> &nbsp; 39 reviews
                </div>
              </div>
            </div>
            <Row className="mt-5">
              <Col md={4}>
                <b>Top Qualification: </b>
              </Col>
              <Col md={8} className="d-flex align-items-center gap-2">
                {teacherData?.teacher_detail_id?.qualifications.map(
                  (qualification, index) => (
                    <p key={index} className="text-decoration-underline ">
                      {qualification.degree_name},{" "}
                    </p>
                  )
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={4}>
                <b>Schools They Teach: </b>
              </Col>
              <Col md={8} className="d-flex align-items-center gap-2">
                {teacherData?.teacher_detail_id?.pre_schools.map(
                  (schl, index) => (
                    <p key={index} className="text-decoration-underline ">
                      {schl.name},
                    </p>
                  )
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <b>Language : </b>
              </Col>
              <Col md={8} className="d-flex align-items-center gap-2">
                {teacherData?.teacher_detail_id?.languages.map(
                  (language, index) => (
                    <p key={index} className="text-decoration-underline ">
                      {language},
                    </p>
                  )
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <b>Subjects : </b>
              </Col>
              <Col md={8} className="d-flex gap-2 ">
                {teacherData?.teacher_detail_id?.grades.map((grade, index) => (
                  <p
                    className="cursor-pointer text-decoration-underline"
                    onClick={() => handleShowSubjectModal(grade)}
                  >
                    {grade.subject},
                  </p>
                ))}
              </Col>
            </Row>
            <Modal
              show={showSubjectModal}
              onHide={handleCloseSubjectModal}
              style={{ marginTop: "15vh" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>{subjectDetails?.subject}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <b>Board :</b> {subjectDetails?.board}
                </p>
                <p>
                  <b>Grade :</b> {subjectDetails?.grade}
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={handleCloseSubjectModal}
                  className="new-button"
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
            <Row className="mt-3">
              <Col md={4}>
                <b>Competitive Exams: </b>
              </Col>
              <Col md={8}>JEE, NEET</Col>
            </Row>
          </Col>
          <Col md={6} className="p-0 m-0">
            <div className="intro_video position-relative pe-3 rounded-3">
              <video
                autoPlay
                loop
                className="w-100 h-100 rounded-3"
                muted
                controls
                src={teacherData?.introduction_video_key}
              ></video>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <h5>About Me:</h5>
          <p>{teacherData?.about}</p>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <div className="teacher-profile-items-item mt-2">
              <GiPostOffice size={22} /> <span>Teacher Designation</span>
            </div>
            <div className="teacher-profile-items-item mt-2">
              <LocalLibraryIcon /> <span>100 Classes Completed</span>
            </div>
            <div className="teacher-profile-items-item mt-2">
              <MyLocation /> <span>Lives in {teacherData?.address}</span>
            </div>
            <div className="teacher-profile-items-item mt-2">
              <IoTimeSharp size={22} /> <span>120+ Hours of Classes</span>
            </div>
            <div className="teacher-profile-items-item mt-2">
              <CalendarToday />{" "}
              <span>
                Joined on {""}
                {new Date(teacherData?.createdAt).toLocaleDateString()}
              </span>
            </div>
            <div className="teacher-profile-items-item mt-2">
              <SupervisorAccount /> <span>10+ Years of Experience</span>
            </div>
            <div className="teacher-profile-items-item mt-2">
              <SchoolIcon /> <span>1000+ Students Taught</span>
            </div>
            <div
              className="teacher-profile-items-item mt-2 cursor-pointer"
              onClick={() => setIsAwardOpen(!isAwardOpen)}
            >
              <FaTrophy size={22} /> <span>Awards</span>
            </div>

            {isAwardOpen && (
              <div className="awards mt-3">
                {teacherData?.teacher_detail_id?.achievements.map(
                  (ach, index) => (
                    <div key={index}>
                      <div
                        className="line ms-2 rounded-5"
                        style={{ width: 3, height: 20, background: "#0000FF" }}
                      ></div>
                      <div
                        className="line ms-2"
                        style={{ width: 3, height: 20, background: "#0000FF" }}
                      ></div>
                      <div className="content-award ms-1 d-flex align-items-center">
                        <div
                          className="dot"
                          style={{
                            width: 10,
                            height: 10,
                            background: "#0000FF",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <div className="ms-3">
                          <div className="award-title">{ach?.title}</div>
                          <div className="award-desc"></div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            <div className="slide_video mt-4 mb-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 2 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                {teacherData?.teacher_detail_id?.certificates.map(
                  (cr, index) => (
                    <SwiperSlide>
                      <div className="slide_video_inner">
                        <img
                          height={200}
                          style={{ width: "100%", objectFit: "cover" }}
                          src={cr.certificate_key}
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </Col>
          <Col md={6}>
            <h4>Top Reviews</h4>
            <div className="teacter-profile-top-review-itme mt-3 shadow border border-2">
              <div className="teacter-profile-top-review-itme-header">
                <div className="left">
                  <div className="profile-image-for-review n-box-shadow">
                    <img src={UserProfile} alt="" />
                  </div>
                  <div className="">
                    <span className="name text-bold">Adam Shah</span>
                    <br />
                    <span className="time">5 min ago</span>
                    <br />
                  </div>
                </div>
                <div className="right">
                  <MoreVertIcon />
                </div>
              </div>
              <div className="profile-name-for-review ps-4">
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
              </div>
              <Col className="p-2 ">
                Lorem ipsum dolor sit amet consectetur. Est congue blandit
                sagittis condimentum vulputate fermentum aenean duis lacinia.
                Nisi eu at ac ac tellus facilisis.
              </Col>
            </div>
            <div className="teacter-profile-top-review-itme mt-3 shadow border border-2">
              <div className="teacter-profile-top-review-itme-header">
                <div className="left">
                  <div className="profile-image-for-review n-box-shadow">
                    <img src={UserProfile} alt="" />
                  </div>
                  <div className="">
                    <span className="name text-bold">Adam Shah</span>
                    <br />
                    <span className="time">5 min ago</span>
                    <br />
                  </div>
                </div>
                <div className="right">
                  <MoreVertIcon />
                </div>
              </div>
              <div className="profile-name-for-review ps-4">
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
                <span className="d-flex align-items-center justify-content-start this-is-review-star">
                  <b className="text-dark">Punctuality :</b> &nbsp; <Star />{" "}
                  <Star /> <Star /> <Star /> <Star />{" "}
                </span>
              </div>
              <Col className="p-2 ">
                Lorem ipsum dolor sit amet consectetur. Est congue blandit
                sagittis condimentum vulputate fermentum aenean duis lacinia.
                Nisi eu at ac ac tellus facilisis.
              </Col>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 mt-sm-3 ps-md-3">
          <h5>Qualification</h5>
        </Row>
        <Col className="p-2">
          {teacherData?.teacher_detail_id?.qualifications.map(
            (qualification, index) => (
              <Row>
                <b>
                  {index + 1}. {qualification.degree_name} from{" "}
                  {qualification.institute_name}
                </b>
              </Row>
            )
          )}
        </Col>
        <Row className="mt-2 mt-sm-3 ps-md-3">
          <h5>Special Acheivement:</h5>
        </Row>
        <Col className="p-2">
          <Row>
            {teacherData?.teacher_detail_id?.achievements.map(
              (achievement, index) => (
                <Col md={3} className="mt-3 position-relative" key={index}>
                  <p>{achievement.title}</p>
                  <img
                    style={{ height: 200, width: "100%", objectFit: "cover" }}
                    src={achievement?.certificate_key}
                    alt=""
                  />
                </Col>
              )
            )}
          </Row>
        </Col>

        <Row className="mt-5 mt-sm-5 ps-md-3">
          <Col md={6}>
            <h5>Courses</h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Form.Select aria-label="Default select example">
              <option>Filter With Subjects</option>
              {teacherData?.teacher_detail_id?.grades.map((grade, index) => (
                <option key={index}>{grade.subject}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="p-md-3 d-flex align-items-center">
          {coursesData.length > 0 ? (
            coursesData.map((course, index) => (
              <Col md={4} key={index}>
                <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
                  <img
                    onClick={() => navigate("/teacher/course/142512001")}
                    className="cource_img rounded-3 px-3 cursor-pointer"
                    style={{ width: "100%", borderRadius: "7px" }}
                    src={baseUrl + course?.thumbnail_key}
                    alt=""
                  />

                  <div className="d-flex px-3 align-items-center justify-content-between mt-3">
                    <button className="px-3">{course?.course_tags[0]}</button>
                  </div>
                  <h6
                    className="mt-3 px-3 cursor-pointer"
                    onClick={() => navigate("/teacher/course/142512001")}
                  >
                    course id : {course?._id}
                  </h6>
                  <h5 className="mt-3 px-3">{course?.title}</h5>

                  <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
                    <div className="user d-flex align-items-center gap-2">
                      <div className="user_img">
                        <img src={teacherData?.image_key} alt="" />
                      </div>
                    </div>
                    <p className="m-0 text-secondary">
                      {teacherData?.firstName} {teacherData?.lastName}
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <IoStar className="star" /> (4 Reviews)
                    </div>
                  </div>

                  <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between mt-3">
                    <p className="m-0">
                      <IoMdTime className="fs-5 me-1 mb-1" /> {course?.duration}
                    </p>
                    <h6 className="m-0 fw-semibold">Rs. {course?.price}</h6>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <h3 className="mt-4 text-center">No Courses Found</h3>
          )}
        </Row>
      </Container>

      <ParentFooter />
    </TeacherLayout>
  );
};

export default TeacherProfile;

const TeacherCourseCard = ({ img, isActions, isReschedule }) => {
  const [editShow, setEditShow] = React.useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };
  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          onClick={() => navigate("/teacher/course/142512001")}
          className="cource_img rounded-3 px-3 cursor-pointer"
          style={{ width: "100%", borderRadius: "7px" }}
          src={img}
          alt=""
        />

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          {/* <h6 className="m-0 fw-semibold">Rs. 1500.00</h6> */}
        </div>
        <h6
          className="mt-3 px-3 cursor-pointer"
          onClick={() => navigate("/teacher/course/142512001")}
        >
          course id : #00142512001
        </h6>
        <h5 className="mt-3 px-3">course name</h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
          </div>
          <p className="m-0 text-secondary">Robert Fox</p>
          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        {isActions && (
          <div className="py-2 px-3 mt-3 border border-0 border-top d-flex align-items-center justify-content-end">
            <div
              onClick={() =>
                navigate("/teacher/dashboard/pages/class/edit-class-details")
              }
              className="parent_accept  ms-4 d-flex align-items-center justify-content-center"
            >
              <MdEdit className="fs-4" />
            </div>
            {isReschedule && (
              <div
                onClick={handleShow}
                style={{ backgroundColor: "#7D88F4" }}
                className="parent_accept ms-4 d-flex align-items-center justify-content-center"
              >
                <FaCalendarAlt color="#fff" size={18} className="fs-4" />
              </div>
            )}
            <div className="parent_decline  ms-4 d-flex align-items-center justify-content-center">
              <MdOutlineDeleteOutline className="fs-4" />
            </div>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between mt-3">
          {/* <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p> */}
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          {/* <p className="m-0"> */}
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
          {/* </p> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Form onSubmit={HandleSubmit}>
          <Row className="p-3">
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Date:</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Time:</Form.Label>
                <Form.Control type="time" />
              </Form.Group>
            </Col>

            <Col
              md={12}
              className="mt-4 d-flex align-items-center justify-content-end"
            >
              <button className="cancel_btn" onClick={handleClose}>
                Cancel
              </button>
              <button className="reschedule_btn ms-4" type="submit">
                Reschedule
              </button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
