import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Alert, Badge, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import card1 from "../../assets/parent/card1.png";
import { IoMdClose, IoMdTime } from "react-icons/io";
import { IoHeart, IoSchoolOutline, IoStar } from "react-icons/io5";
import { MdEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import { TiBook, TiTick } from "react-icons/ti";
import { IoStarSharp } from "react-icons/io5";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation } from "swiper/modules";
import Card1 from "./Card1";
import { FaRegCalendarTimes } from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoIosHeart } from "react-icons/io";
import CorseSettingModel from "./CourseSettingModel";
import { Card } from "./Card";
import axios from "../../utils/axios";
import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseAwsUrl, MyAPI } from "../../utils/MyAPI";

const CourcesPage = () => {
  const [modelOpen, setModelOpen] = useState(false);
  const [corcesEditModel, setCorcesEditModel] = useState(false);
  const [courses, setCourses] = useState([]);
  const token = useSelector((state) => state.token.value);
  const [dataLoading, setDataLoading] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setSelectedCourse(null)
    setModelOpen(false);
  };

  const handleShowModal = (course) => {
    setSelectedCourse(course)
    setModelOpen(true);
  };

  const [AllActiveCourses, setAllActiveCourses] = useState([])
  const [AllNewCourses, setAllNewCourses] = useState([])
  const [AllDemoCourses, setAllDemoCourses] = useState([])
  const [AllCommunityMeetCourses, setAllCommunityMeetCourses] = useState([])
  const [AllOnHoldCourses, setAllOnHoldCourses] = useState([])
  const [AllStopedCourses, setAllStopedCourses] = useState([])
  const [AllComplitedCourses, setAllComplitedCourses] = useState([])
  const [AllSuggestedCourses, setAllSuggestedCourses] = useState([])
  const [AllEndedCourses, setAllEndedCourses] = useState([])
  const [AllRequestedCourses, setAllRequestedCourses] = useState([])

  // this is all filter states
  const [keyword, setKeyword] = useState('');
  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [board, setBoard] = useState('');
  const [experience, setExperience] = useState('');
  const [rating, setRating] = useState('');
  const [courseType, setCourseType] = useState('');
  const [days, setDays] = useState('');
  const [qualification, setQualification] = useState('');
  const [competitive, setCompetitive] = useState('');
  const [filters, setFilters] = useState('');

  const fetchCourses = async ({
    category = '',
    subject = '',
    grade = '',
    board = '',
    duration = '',
    course_type = '',
    language = '',
    keyword = '',
    modules = '',
    tags = '',
    status = ''
  }, token) => {
    try {
      // Construct the query string based on the passed parameters
      const queryParams = new URLSearchParams({
        category,
        subject,
        grade,
        board,
        duration,
        course_type,
        language,
        keyword,
        modules,
        tags,
        status
      }).toString();
      setDataLoading(true)

      const res = await MyAPI.GET(`/common/course-manage?${queryParams}`, token);
      let { res_type, message, data: apiData } = res.data || res;

      if (res_type === "success") {
        setAllActiveCourses(apiData.filter((course) => course.status === "active"))
        setAllNewCourses(apiData.filter((course) => course.status === "new-course"))
        setAllDemoCourses(apiData.filter((course) => course.status === "demo-classes"))
        setAllCommunityMeetCourses(apiData.filter((course) => course.status === "community-meet"))
        setAllOnHoldCourses(apiData.filter((course) => course.status === ("hold" || "on-hold")))
        setAllStopedCourses(apiData.filter((course) => course.status === "paused"))
        setAllComplitedCourses(apiData.filter((course) => course.status === "completed"))
        setAllSuggestedCourses(apiData.filter((course) => course.status === "active"))
        setAllEndedCourses(apiData.filter((course) => course.status === "ended"))
        setAllRequestedCourses(apiData.filter((course) => course.status === "pending"))
      } else {
        toast.error(message || 'Error Fetching Data.');
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setDataLoading(false)
    }
  };

  useEffect(() => {
    if (token) {
      fetchCourses(
        {
          subject,
          grade,
          board,
          duration: days,        // Set duration to days
          course_type: courseType, // Set course_type to courseType
          language: '',           // Default empty string
          keyword,
          modules: '',            // Default empty string
          tags: '',               // Default empty string
          status: filters         // Set status to filters
        },
        token
      );
    }
  }, [token, keyword, subject, grade, board, experience, rating, courseType, days, qualification, competitive, filters]);


  return (
    <OperationLayout>
      <h5>Manage courses</h5>
      {/* <OperationLocation path={history} /> */}
      <CorseSettingModel
        handleClose={handleCloseModal}
        course={selectedCourse}
        handleShowModal={modelOpen}
        fetchCourses={fetchCourses}
      />
      <StyledContainer>
        <div className="input_parent py-2 px-3 d-flex align-items-center">
          <input
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            type="text"
            placeholder="Search Course by Name, Subject, Grade, Board..."
            className="border-0"
          />
          <button className="d-flex text-white align-items-center justify-content-center">
            <CiSearch />
          </button>
        </div>

        <Row className="pe-5">
          <Col md={3} className="mt-4">
            <Form.Select
              value={subject}
              onChange={e => setSubject(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Subject
              </option>
              <option value="maths">Maths</option>
              <option value="science">Science</option>
              <option value="english">English</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={grade}
              onChange={e => setGrade(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Grade
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={board}
              onChange={e => setBoard(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Board
              </option>
              <option value="CBSE">CBSE</option>
              <option value="ICSE">ICSE</option>
              <option value="State Board">State Board</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={experience}
              onChange={e => setExperience(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Experience
              </option>
              <option value="1 Year">1 Year</option>
              <option value="2 Year">2 Year</option>
              <option value="3 Year">3 Year</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={rating}
              onChange={e => setRating(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Rating
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={courseType}
              onChange={e => setCourseType(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Type
              </option>
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
              <option value="Both">Both</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={days}
              onChange={e => setDays(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Days
              </option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={qualification}
              onChange={e => setQualification(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Qualification
              </option>
              <option value="B.Tech">B.Tech</option>
              <option value="M.Tech">M.Tech</option>
              <option value="Phd">PhD</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={competitive}
              onChange={e => setCompetitive(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Competitive
              </option>
              <option value="JEE">JEE</option>
              <option value="NEET">NEET</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              value={filters}
              onChange={e => setFilters(e.target.value)}
              className="rounded-5"
            >
              <option disabled value="">
                Filters
              </option>
              <option value="active">Active Courses</option>
              <option value="new-course">New Courses</option>
              <option value="demo-classes">Demo Classes</option>
              <option value="community-meet">Communities</option>
              <option value="hold">On Hold Courses</option>
              <option value="paused">Courses Stopped</option>
              <option value="completed">Completed Courses</option>
            </Form.Select>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">Active Courses</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllActiveCourses && AllActiveCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow1",
                  prevEl: ".courses_left_arrow1",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {
                  AllActiveCourses
                    .map((course) => (
                      <SwiperSlide>
                        <Card
                          isActions={true}
                          corcesEditModelClick={handleShowModal}
                          completed={true}
                          likes={true}
                          courseData={course}
                        />
                      </SwiperSlide>
                    ))}
              </Swiper>

              <div className="courses_left_arrow1">
                <FaAngleLeft className="text-white" />
              </div>
              <div className="courses_right_arrow1">
                <FaAngleRight className="text-white" />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllActiveCourses && AllActiveCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }


        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">New Courses</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllNewCourses && AllNewCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow2",
                  prevEl: ".courses_left_arrow2",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllNewCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow2">
                <FaAngleLeft className="text-white" />
              </div>
              <div className="courses_right_arrow2">
                <FaAngleRight className="text-white" />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllNewCourses && AllNewCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Demo class</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllDemoCourses && AllDemoCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow3",
                  prevEl: ".courses_left_arrow3",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllDemoCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow3">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow3">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllDemoCourses && AllDemoCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Community meet</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllCommunityMeetCourses && AllCommunityMeetCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow3",
                  prevEl: ".courses_left_arrow3",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllCommunityMeetCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow3">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow3">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllCommunityMeetCourses && AllCommunityMeetCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">On-hold Courses</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllOnHoldCourses && AllOnHoldCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow4",
                  prevEl: ".courses_left_arrow4",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllOnHoldCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow4">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow4">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllOnHoldCourses && AllOnHoldCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Courses stopped</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllStopedCourses && AllStopedCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow5",
                  prevEl: ".courses_left_arrow5",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllStopedCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow5">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow5">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllStopedCourses && AllStopedCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Completed Courses</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllComplitedCourses && AllComplitedCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow6",
                  prevEl: ".courses_left_arrow6",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllComplitedCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow6">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow6">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllComplitedCourses && AllComplitedCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Suggested Courses</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllSuggestedCourses && AllSuggestedCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow7",
                  prevEl: ".courses_left_arrow7",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllSuggestedCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow7">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow7">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllSuggestedCourses && AllSuggestedCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Courses ended</h3>
          </Col>
        </Row>
        {
          !dataLoading && AllEndedCourses && AllEndedCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow5",
                  prevEl: ".courses_left_arrow5",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllEndedCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      isActions={true}
                      corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow5">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow5">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllEndedCourses && AllEndedCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Courses Request's</h3>
          </Col>
        </Row>

        {
          !dataLoading && AllRequestedCourses && AllRequestedCourses.length > 0 && (
            <Row className="position-relative pe-5">
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                spaceBetween={20}
                navigation={{
                  nextEl: ".courses_right_arrow5",
                  prevEl: ".courses_left_arrow5",
                }}
                modules={[Navigation]}
                className="mySwiper"
                style={{
                  paddingRight: "3.8vmax",
                }}
              >
                {AllRequestedCourses.map((course) => (
                  <SwiperSlide>
                    <Card
                      // isActions={true}
                      // corcesEditModelClick={handleShowModal}
                      completed={true}
                      likes={true}
                      courseData={course}
                      isAcceptReject={true}
                      fetchCourses={fetchCourses}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="courses_left_arrow5">
                <FaAngleLeft />
              </div>
              <div className="courses_right_arrow5">
                <FaAngleRight />
              </div>
            </Row>
          )
        }

        {!dataLoading && AllRequestedCourses && AllRequestedCourses.length === 0 && (
          <Alert variant="warning">
            Courses not found.
          </Alert>
        )}

        {
          dataLoading && (
            <center>
              <Spinner size="sm" variant="primary" />
            </center>
          )
        }
      </StyledContainer>
    </OperationLayout>
  );
};

const StyledContainer = styled.div`
  .input_parent {
    border: 2px solid #e5e5e5;
    width: fit-content;
    border-radius: 100px;

    input {
      width: 350px;
      background: transparent;
    }

    button {
      background: var(--color-primary);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: none;
    }
  }
  .courses_right_arrow1,
  .courses_left_arrow1,
  .courses_right_arrow2,
  .courses_left_arrow2,
  .courses_right_arrow3,
  .courses_left_arrow3,
  .courses_right_arrow4,
  .courses_left_arrow4,
  .courses_right_arrow5,
  .courses_left_arrow5,
  .courses_right_arrow6,
  .courses_left_arrow6,
  .courses_right_arrow7,
  .courses_left_arrow7 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .courses_right_arrow1,
  .courses_right_arrow2,
  .courses_right_arrow3,
  .courses_right_arrow4,
  .courses_right_arrow5,
  .courses_right_arrow6,
  .courses_right_arrow7 {
    right: 5%;
  }
  .courses_left_arrow1,
  .courses_left_arrow2,
  .courses_left_arrow3,
  .courses_left_arrow4,
  .courses_left_arrow5,
  .courses_left_arrow6,
  .courses_left_arrow7 {
    left: -1%;
  }
`;

export default CourcesPage;
