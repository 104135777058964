import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Alert, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Button, Chip } from "@mui/material";
import { Add } from "@material-ui/icons";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { MdOutlineFileDownload, MdReplyAll } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formattedDate, MyAPI, truncateText } from "../../utils/MyAPI";
import { Link } from "react-router-dom";

const ProfilePic = require('../../assets/parent/profile-user.jpg');

const Sugessions = (props) => {
    const { history } = props;
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isNewComplet, setIsNewSuggestion] = useState(false);

    const handleShowModal = (newComplet) => {
        setShowModal(true);
        setIsNewSuggestion(newComplet)
    };
    const handleCloseModal = () => setShowModal(false);

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);

    const handleRaiseSuggestion = () => {
        console.log('Suggestion raised:', title, description);
        handleCloseModal();
    };


    const token = useSelector(state => state.token.value);
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState([])
    const [dataStatus, setDataStatus] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    const fetchSuggestions = async (token, keyword, status) => {
        try {
            setDataLoading(true)
            let res = await MyAPI.GET(`/suggestion?keyword=${keyword}&status=${status}`, token)
            let { res_type, message, data: apiData } = res.data || res;
            if (res_type === 'success') {
                setData(apiData)
            } else {
                toast.error(message || 'Error fetching Suggestions.')
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            setDataLoading(false)
        }
    }

    useEffect(() => {
        if (token) {
            fetchSuggestions(token, searchQuery, dataStatus)
        }
    }, [token, dataStatus, searchQuery])


    return (
        <OperationLayout>
            <h5>Suggestions</h5>
            {/* <OperationLocation path={history} /> */}

            <Col className='d-flex align-items-center justify-content-between'>
                <Col md={3}>
                    <Col>
                        <Form.Group controlId="selectOption">
                            <Form.Select className="shadow rounded-5 mt-3">
                                <option value="option1">Suggestion type</option>
                                <option value="">all</option>
                                <option value="pending">Active</option>
                                <option value="close">Close</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {/* <Button className="d-none" onClick={() => handleShowModal(true)} variant="outlined" style={{ borderColor: 'var(--color-primary)', borderRadius: '25px' }} endIcon={<Add />}> Raise Suggestion </Button> */}
                </Col>
                <Col className='d-flex align-items-center justify-content-end' md={6}>
                    <Form.Group controlId="searchInput">
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className="shadow rounded-5 border"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Col>

            <Modal show={showModal} onHide={handleCloseModal} style={{ zIndex: '1111', marginTop: '20vh' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isNewComplet && 'Raise Suggestion'}
                        {!isNewComplet && 'Reply Suggestion'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="SuggestionTitle">
                        <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="selectOption">
                        <Form.Select className="mt-3">
                            <option selected disabled value="option1">Select Status</option>
                            <option value="option2">Open</option>
                            <option value="option2">Pending</option>
                            <option value="option3">Close</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="SuggestionDescription" className='mt-3'>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter description"
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bg-danger text-white border-0' variant="outlined" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='ms-3' variant="outlined" onClick={handleRaiseSuggestion}>
                        {isNewComplet && 'Raise Suggestion'}
                        {!isNewComplet && 'Reply Suggestion'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {
                !dataLoading && data && data.length > 0 && data.map((item, index) => (
                    <Col key={index} className='mt-3 rounded-2 p-2 m-0 n-box-shadow'>
                        <Row className='p-2 m-0'>
                            <Col md={4} className='d-flex align-items-center justify-content-start p-0 m-0' style={{ gap: '10px' }}> <div className="parent-complain-image"><img src={ProfilePic} alt="userImg" /> </div> <div>
                                {
                                    <>
                                        <h5 >{item?.user_id?.visibleName || item?.user_id?.firstName || 'N/A'}</h5>
                                        <p>{item?.user_id?.uid}</p>
                                    </>
                                }
                            </div> </Col>
                            <Col md={8} className='d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive' style={{ fontSize: '18px' }}>Subject: Introduction to Thermodynamics</Col>
                        </Row>
                        <Col className='d-flex align-items-center justify-content-between p-2'>
                            <Col md={6} > {formattedDate(item?.createdAt || '2024-10-05T05:31:58.693Z')} <br /> <h5 className="m-0 p-0">Rohit Kumar</h5> teacher#142512001 </Col>
                            <Col md={6} className='d-flex align-items-center justify-content-end' >
                                {
                                    item.status === 'pending' && (
                                        <Button onClick={() => handleShowModal(false)} className="me-3 rounded-5 shadow" variant="outlined" startIcon={<MdReplyAll />}>
                                            Reply
                                        </Button>
                                    )
                                }
                                <Chip label={item.status === 'pending' ? `Open` : `Close`} className='n-box-shadow' style={{ color: item.status === 'pending' ? `black` : `white`, borderColor: 'yellow', background: item.status === 'pending' ? `yellow` : `red` }} color="primary" />
                            </Col>
                        </Col>
                        <hr />
                        <Col className='p-2'>
                            {
                                truncateText(item?.content, 100)
                            }
                        </Col>
                        {
                            item.attachment_key && (
                                <>
                                    <Col className="mt-3"><h6>Attachment</h6></Col>
                                    <Row className="ps-3">
                                        <Col md={3} style={{ padding: '10px' }}>
                                            <div className="resorce-parent-item cursor-pointer">
                                                <div className="name"><FaFilePdf /> File</div>
                                                <Link target="_blank" to={item.attachment_key}>
                                                    <MdOutlineFileDownload />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </Col>
                ))
            }

            {
                !dataLoading && data && data.length === 0 && (
                    //show react bootstrap warning no data fround.
                    <Alert variant="warning" className="text-center mt-5">
                        No Data Found.
                    </Alert>
                )
            }

            {
                dataLoading && (
                    <center className="mt-5">
                        <Spinner size="lg" variant="primary" />
                    </center>
                )
            }


            {/* <Col className='mt-3 rounded-2 p-2 m-0 n-box-shadow'>
                <Row className='p-2 m-0'>
                    <Col md={4} className='d-flex align-items-center justify-content-start p-0 m-0' style={{ gap: '10px' }}> <div className="parent-complain-image"><img src={ProfilePic} alt="userImg" /> </div> <div>
                        <h5 >Cody Fisher</h5>
                        <p>help#90818mnjn</p>
                    </div> </Col>
                    <Col md={8} className='d-flex align-items-center justify-content-end' style={{ fontSize: '18px' }}>Subject: Introduction to Thermodynamics</Col>
                </Row>
                <Col className='d-flex align-items-center justify-content-between p-2'>
                    <Col md={6} > 5 Jun 2023</Col>
                    <Col md={6} className='d-flex align-items-center justify-content-end' >
                        <Button onClick={() => handleShowModal(false)} className="me-3 rounded-5 shadow" variant="outlined" startIcon={<MdReplyAll />}>
                            Reply
                        </Button>
                        <Chip label="Open" className='n-box-shadow' style={{ color: 'black', borderColor: 'yellow', background: 'yellow' }} color="primary" />
                    </Col>
                </Col>
                <hr />
                <Col className='p-2'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias atque impedit officia porro maiores illum quae eveniet, odio ad non expedita eius earum reiciendis sequi assumenda optio dignissimos debitis quis similique praesentium. Corporis debitis molestiae officiis nesciunt, minima temporibus possimus nisi quisquam, in perferendis ipsam, hic ea quidem dolorem veniam assumenda aspernatur maiores. Repudiandae reiciendis aspernatur omnis nobis adipisci totam.
                </Col>
            </Col> */}
        </OperationLayout>
    );
};

export default Sugessions;
