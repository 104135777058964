import React, { useState } from 'react'
import OftadehLayout from '../../components/parentComponents/OftadehLayout/OftadehLayout'
import OftadehBreadcrumbs from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import { Row, Col, Container, Modal, Form } from 'react-bootstrap'
import { Button } from '@mui/material';
import { Search } from '@material-ui/icons';
import ParentFooter from '../../components/ParentFooter';

function OnlineTest(props) {
    const { history } = props;
    const [showResult, setShowResult] = useState(false);
    const [studentAnswers, setStudentAnswers] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState([]);

    const questions = [
        {
            id: 4,
            instruction: "This is student test question 4",
            options: ["Option A", "Option B", "Option C", "Option D"],
            name: "q4",
            correctAnswerIndex: 0, // Index of the correct answer
            marks: 2 // Marks for the question
        },
        {
            id: 5,
            instruction: "This is student test question 5",
            options: ["Option A", "Option B", "Option C", "Option D"],
            name: "q5",
            correctAnswerIndex: 1, // Index of the correct answer
            marks: 2 // Marks for the question
        }
    ];

    const handleSubmitTest = () => {
        let correct = [];
        let studentAnswersList = [];
        questions.forEach((question, index) => {
            const selectedAnswer = document.querySelector(`input[name=${question.name}]:checked`);
            if (selectedAnswer) {
                const selectedOption = parseInt(selectedAnswer.value.split('-')[1]);
                studentAnswersList.push(selectedOption);
                if (selectedOption === question.correctAnswerIndex) {
                    correct.push(index);
                }
            } else {
                studentAnswersList.push(null);
            }
        });
        setStudentAnswers(studentAnswersList);
        setCorrectAnswers(correct);
        setShowResult(true);
    };

    return (
        <OftadehLayout>
            <h3>Online Test Screen</h3>
            {/* <OftadehBreadcrumbs path={history} /> */}

            <Container className='mt-3 mb-3 shadow rounded-3 p-3 pt-5 border-yellow'>
            <center><h4>Online Test 1: This Is Online Test Title</h4></center>
            <center>
                <p className='text-center' style={{width:'80%'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda earum deserunt cumque non illo omnis alias debitis eaque qui recusandae.</p>
            </center>
            <hr />
            <ol>
                {questions.map(question => (
                    <li className='number-list mt-3 mb-3' style={{fontSize:'22px'}} key={question.id}>
                        <h5>{question.instruction}</h5>
                        <ul>
                            {question.options.map((option, index) => (
                                <li style={{fontSize:'16px'}} key={index}>
                                    <input type="radio" name={question.name} value={`option-${index}`} /> {option}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ol>
            <hr />
            <Row>
                <Col>
                    <Button className='border-danger bg-danger text-white' variant="outlined">Cancel</Button>
                    <Button className='ms-3' variant="outlined">Submit Test</Button>
                </Col>
            </Row>

            {/* Modal for showing results */}
            <Modal show={showResult} onHide={() => setShowResult(false)} style={{marginTop:'20vh', zIndex:'1111'}}>
                <Modal.Header closeButton>
                    <Modal.Title>Test Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {questions.map((question, index) => (
                            <Form.Group key={index}>
                                <Form.Label>{question.instruction}</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={studentAnswers[index] === question.correctAnswerIndex ? question.marks : 0}
                                    style={{ color: studentAnswers[index] === question.correctAnswerIndex ? 'green' : 'red' }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Correct Answer: {question.options[question.correctAnswerIndex]}
                                </Form.Control.Feedback>
                            </Form.Group>
                        ))}
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
        <ParentFooter />
        </OftadehLayout>
    )
}

export default OnlineTest