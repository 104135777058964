import React, { useState } from 'react'
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Badge, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { CiEdit } from 'react-icons/ci';
function CoursesRequests() {
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState('pending');
    const [reason, setReason] = useState('');

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can implement the logic to handle the submission
        console.log('New Status:', status);
        console.log('Reason:', reason);
        // Close the modal after submission
        handleClose();
    };
    return (
        <OperationLayout>
            <h5>Courses Requests</h5>
            <Row className='justify-content-between'>
                <Col md={3}>
                    <Form.Group>
                        <Form.Select className='rounded-5 shadow mt-3 mb-3'>
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Control
                            className='shadow rounded-5 mt-3 mb-3'
                            type="text"
                            name="search"
                            placeholder="Search..."
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Table className='mt-3' responsive striped style={{ borderRadius: '25px', backgroundColor: 'var(--color-primary)' }}>
                <thead>
                    <tr className="text-center">
                        <th className="text-white">Unique I'D</th>
                        <th className="text-white">Name</th>
                        <th className="text-white">Title</th>
                        <th className="text-white">Status</th>
                        <th className="text-white" colSpan={2}>Action</th>
                        <th className="text-white">Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-light text-center">
                        <td>ID#01112562376</td>
                        <td>John Doe</td>
                        <td>Course title - 1</td>
                        <td><Badge bg="success">Completed</Badge></td>
                        <td>
                            <CiEdit onClick={handleShow} size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>
                            <IoChatbubbleEllipsesOutline size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>12 January 2023 10:30 AM</td>
                    </tr>
                    <tr className="bg-light text-center">
                        <td>ID#01112562376</td>
                        <td>John Doe</td>
                        <td>Course title - 1</td>
                        <td><Badge bg="warning">Pending</Badge></td>
                        <td>
                            <CiEdit onClick={handleShow} size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>
                            <IoChatbubbleEllipsesOutline size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>12 January 2023 10:30 AM</td>
                    </tr>
                    <tr className="bg-light text-center">
                        <td>ID#01112562376</td>
                        <td>John Doe</td>
                        <td>Course title - 1</td>
                        <td><Badge bg="success">Completed</Badge></td>
                        <td>
                            <CiEdit onClick={handleShow} size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>
                            <IoChatbubbleEllipsesOutline size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>12 January 2023 10:30 AM</td>
                    </tr>
                    <tr className="bg-light text-center">
                        <td>ID#01112562376</td>
                        <td>John Doe</td>
                        <td>Course title - 1</td>
                        <td><Badge bg="warning">Pending</Badge></td>
                        <td>
                            <CiEdit onClick={handleShow} size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>
                            <IoChatbubbleEllipsesOutline size={22} color={'blue'} className='cursor-pointer' />
                        </td>
                        <td>12 January 2023 10:30 AM</td>
                    </tr>

                </tbody>
            </Table>

            <Modal show={showModal} onHide={handleClose} style={{ marginTop: '10vh' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="statusSelect">
                            <Form.Label>Select Status</Form.Label>
                            <Form.Select value={status} onChange={handleStatusChange}>
                                <option value="pending">Pending</option>
                                <option value="completed">Completed</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="reasonInput">
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter reason for status change"
                                value={reason}
                                onChange={handleReasonChange}
                            />
                        </Form.Group>
                        <Button className='mt-3' variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </OperationLayout>
    )
}

export default CoursesRequests