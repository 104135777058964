import axios from "./axios";
import moment from "moment";

export const MyAPI = {
  GET: async (url, token = null) => {
    try {
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      return {
        status: response.status,
        data: response.data,
        message: response.data.message || response.message,
      };
    } catch (error) {
      let errorMessage = "An error occurred while processing your request.";
      if (error.response) {
        // The request was made and the server responded with a status code
        errorMessage = error.response.data.message || errorMessage;
        return {
          status: error.response.status,
          message: errorMessage,
          data: error.response.data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message || errorMessage;
      }
      return {
        status: 500,
        message: errorMessage,
      };
    }
  },
  POST: async (url, data, token = null) => {
    try {
      // Determine if the data is a FormData object
      const isFormData = data instanceof FormData;
      const response = await axios.post(`${url}`, data, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": isFormData
            ? "multipart/form-data"
            : "application/json",
        },
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      let errorMessage = "An error occurred while processing your request.";
      if (error.response) {
        // The request was made and the server responded with a status code
        errorMessage = error.response.data.message || errorMessage;
        return {
          status: error.response.status,
          message: errorMessage,
          data: error.response.data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message || errorMessage;
      }
      return {
        status: 500,
        message: errorMessage,
      };
    }
  },
  PUT: async (url, data, token = null) => {
    try {
      // Determine if the data is a FormData object
      const isFormData = data instanceof FormData;

      const response = await axios.put(`${url}`, data, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": isFormData
            ? "multipart/form-data"
            : "application/json",
        },
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      let errorMessage = "An error occurred while processing your request.";
      if (error.response) {
        // The request was made and the server responded with a status code
        errorMessage = error.response.data.message || errorMessage;
        return {
          status: error.response.status,
          message: errorMessage,
          data: error.response.data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message || errorMessage;
      }
      return {
        status: 500,
        message: errorMessage,
      };
    }
  },
  PATCH: async (url, data, token = null) => {
    try {
      // Determine if the data is a FormData object
      const isFormData = data instanceof FormData;

      const response = await axios.patch(`${url}`, data, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": isFormData
            ? "multipart/form-data"
            : "application/json",
        },
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      let errorMessage = "An error occurred while processing your request.";
      if (error.response) {
        // The request was made and the server responded with a status code
        errorMessage = error.response.data.message || errorMessage;
        return {
          status: error.response.status,
          message: errorMessage,
          data: error.response.data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message || errorMessage;
      }
      return {
        status: 500,
        message: errorMessage,
      };
    }
  },
  DELETE: async (url, token = null) => {
    try {
      const response = await axios.delete(`${url}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "application/json",
        },
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      let errorMessage = "An error occurred while processing your request.";
      if (error.response) {
        // The request was made and the server responded with a status code
        errorMessage = error.response.data.message || errorMessage;
        return {
          status: error.response.status,
          message: errorMessage,
          data: error.response.data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message || errorMessage;
      }
      return {
        status: 500,
        message: errorMessage,
      };
    }
  },
};

export const formattedDate = ({ date }) => {
  // Format the date as "12 Jan 2024"
  const myDate = moment(date).format("DD MMM YYYY");

  return myDate;
};


export const truncateText = (text, wordCount) => {
  // Split the text into an array of words
  const words = text.split(" ");
  
  // Check if the text has more words than the specified count
  if (words.length > wordCount) {
      // Join the first 'wordCount' words and add ellipsis
      return words.slice(0, wordCount).join(" ") + "...";
  }
  
  // Return the original text if it's within the word limit
  return text;
};

export const getCommonInfo = async (token) => {
  try {
    const response = await axios.get("/common/info", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      status: 500,
      message: "An error occurred while processing your request.",
    };
  }
};

export const baseAwsUrl = "https://hariom-bucket.s3.ap-south-1.amazonaws.com/";
