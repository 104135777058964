import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Col, Row, Button, Form, Container, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-number-input";
import axios from "../utils/axios.js";

const TeacherSignUp = () => {
  const [number, setNumber] = useState();
  const [otp, setOtp] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [email, setEmail] = useState("");
  // const [otpSent, setOtpSent] = useState(false);
  const [otpSentEmail, setOtpSentEmail] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate();
  const otpLength = 6; // Expected OTP length

  // const handleSendOtp = () => {
  //   if (!number) {
  //     toast.error("Please enter a phone number");
  //     return;
  //   }

  //   setLoading1(true);
  //   axios
  //     .post("/parent/otp", {
  //       type: "phone",
  //       value: number,
  //     })
  //     .then((response) => {
  //       if (response.data.res_type === "success") {
  //         toast.success(response.data.message);
  //         setOtpSent(true);
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.message);
  //       console.error("Error sending OTP:", error);
  //     })
  //     .finally(() => {
  //       setLoading1(false);
  //     });
  // };

  const handleSendOtp1 = () => {
    // validate email with regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }

    setLoading2(true);
    axios
      .post("/parent/otp", {
        type: "email",
        value: email,
      })
      .then((response) => {
        if (response.data.res_type === "success") {
          toast.success(response.data.message);
          setOtpSentEmail(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error("Error sending OTP:", error);
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const handleDashboard = async () => {
    if (
      fName === "" ||
      lName === "" ||
      email === "" ||
      number === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (!checked) {
      toast.error("Please agree to the terms and conditions");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // if (otp.length !== otpLength) {
    //   toast.error("Invalid phone OTP");
    //   return;
    // }

    if (otpEmail.length !== otpLength) {
      toast.error("Invalid Email OTP");
      return;
    }

    try {
      setLoading(true);
      // const isOtpVerified = await handleVerifyOtp();
      const isOtpVerified1 = await handleVerifyOtp1();

      if (isOtpVerified1) {
        axios
          .post("/teacher/sign-up", {
            email: email,
            phone: number,
            password: password,
            firstName: fName,
            lastName: lName,
            referralCode: referralCode,
          })
          .then((response) => {
            if (response.data.res_type === "success") {
              toast.success(response.data.message);
              navigate("/parent/sign-in");
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
            console.error("Error creating account:", error);
          });
      } else {
        toast.error("OTP not verified");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleVerifyOtp = async () => {
  //   try {
  //     const response = await axios.put("/parent/otp", {
  //       type: "phone",
  //       value: number,
  //       otp: otp,
  //     });

  //     if (response.data.data.isVerified) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     // toast.error("Something went wrong");
  //     console.error("Error verifying OTP:", error);
  //     return false;
  //   }
  // };

  const handleVerifyOtp1 = async () => {
    try {
      const response = await axios.put("/parent/otp", {
        type: "email",
        value: email,
        otp: otpEmail,
      });

      if (response.data.data.isVerified) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // toast.error("Something went wrong");
      console.error("Error verifying OTP:", error);
      return false;
    }
  };

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div
        className="main admin-main parent-main-container"
        style={{ overflowY: "hidden" }}
      >
        <Navbar />
        <br />
        <br />
        <Container className="mt-5 d-flex align-items-center justify-content-center p-2">
          <Row>
            <Col
              md={6}
              className="login-container-semi-p mx-auto rounded-4 shadow mt-3 p-5 pt-4 pb-2 border-0"
              style={{ overflowY: "hidden" }}
            >
              <Row>
                <Col md={12}>
                  <center>
                    <h2>Teacher Sign-Up</h2>
                  </center>
                </Col>
                <Col md={6} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="text"
                      placeholder="First Name"
                      value={fName}
                      onChange={(e) => setFName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="text"
                      placeholder="Last Name"
                      value={lName}
                      onChange={(e) => setLName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3 mb-3">
                  <Form.Group
                    controlId="nameInput"
                    className="position-relative"
                  >
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="email"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text
                      onClick={handleSendOtp1}
                      className="send-otp-text-parent-reg"
                    >
                      {loading2 ? "Sending..." : "Send OTP"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                {otpSentEmail && (
                  <Col md={12} className="mt-3 mb-3">
                    <Form.Group controlId="otpInput">
                      <Form.Control
                        className="shadow rounded-2 border-2"
                        type="text"
                        placeholder="Enter OTP"
                        value={otpEmail}
                        onChange={(e) => setOtpEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col md={12} className="mt-3 position-relative mb-3 ">
                  <div className="shadow px-3 py-2 border position-relative border-2 rounded-3">
                    <PhoneInput
                      international
                      style={{ border: "none !important" }}
                      defaultCountry="IN"
                      placeholder="Enter phone number"
                      countryCodeEditable={true}
                      value={number}
                      onChange={setNumber}
                    />
                    {/* <button
                      style={{
                        background: "#f4f4f4",
                        color: "#0d6efd",
                        border: "none",
                        padding: "5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: 100,
                        marginTop: 3,
                      }}
                      onClick={handleSendOtp}
                      className="send-otp-text-parent-reg"
                    >
                      {loading1 ? "Sending..." : "Send OTP"}
                    </button> */}
                  </div>
                </Col>
                {/* {otpSent && (
                  <Col md={12} className="mt-3 mb-3">
                    <Form.Group controlId="otpInput">
                      <Form.Control
                        className="shadow rounded-2 border-2"
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                )} */}
                <Col md={12} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="password"
                      placeholder="Enter Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="text"
                      placeholder="Enter Referral code (optional)"
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="text-start mb-2">
                  Already have a account?{" "}
                  <Link className="text-primary" to={"/parent/sign-in"}>
                    Sign-In
                  </Link>{" "}
                </Col>
                {/* <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="login as teacher + join as parent"
                    />
                  </Form.Group>
                </Col> */}
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="I agree to the terms and conditions"
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
                {loading ? (
                  <Button
                    onClick={handleDashboard}
                    className="p-2 w-100 border-0 new-button rounded-5"
                  >
                    <Spinner animation="border" variant="light" />
                  </Button>
                ) : (
                  <Button
                    onClick={handleDashboard}
                    className="p-2 w-100 border-0 new-button rounded-5"
                  >
                    Sign Up
                  </Button>
                )}
              </Row>
              <Col className="text-start">
                Join as a{" "}
                <Link className="text-primary" to={"/parent/sign-up"}>
                  Parent
                </Link>{" "}
              </Col>
              <Col className="text-start">
                Join as a{" "}
                <Link className="text-primary" to={"/career"}>
                  employee
                </Link>{" "}
              </Col>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default TeacherSignUp;
