import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "../../ParentPages/courses/FeedbackModal";
import { Col, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { IoSchoolOutline, IoStar } from "react-icons/io5";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Chip from "@mui/material/Chip";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { IoMdTime } from "react-icons/io";
import { Button } from "@mui/material";
import { FiHeart } from "react-icons/fi";
import { TiBook } from "react-icons/ti";

import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";
import ParentFooter from "../../components/ParentFooter";
import Dropdown from "../../ParentPages/courses/Dropdown";
import { MdEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { FaCalendarAlt, FaPlus } from "react-icons/fa";
import "../../assets/css/homePage.css";

const CourcesPage = () => {
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownChange = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  const [searchValue, setSearchValue] = useState("");

  const [selectedOption, setSelectedOption] = useState("4");

  const handleSearch = () => {
    navigate(`/teacher/courses/search/${searchValue}`);
  };

  return (
    <TeacherLayout>
      <h3>Course </h3>

      <StyledContainer>
        <Row>
          <Col md={3}>
            <Form.Group controlId="nameInput">
              <Form.Control
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="shadow rounded-5"
                type="text"
                placeholder="Search Course by Name, Subject, Grade, Board..."
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Select
                className="shadow rounded-5 border"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                {/* <option value="1">Published Courses</option> */}
                <option value="4">Active Courses</option>
                <option value="2">Approved Courses</option>
                <option value="2">Cancelled Courses</option>
                <option value="2">Yet to approved Courses</option>
                {/* <option value="5">New Courses</option> */}
                {/* <option value="6">Demo Classes</option> */}
                {/* <option value="7">On Hold Courses</option> */}
                <option value="8">Removed Courses</option>
                <option value="3">Active Classes</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={1}>
            <button onClick={handleSearch} className="new-button">
              Search
            </button>
          </Col>
        </Row>

        {/* <h5 className="mt-3">Upcoming Live Classes</h5>
        <Row className="p-4 shadow rounded-3 border border-warning">
          <Col md={6}>
            <span>http://link.com/join?123445</span>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Chip label="Join" variant="outlined" />
          </Col>
        </Row> */}

        {selectedOption === "1" && (
          <>
            <Row className="mt-5">
              <Col>
                <h3 className="mb-4">Published Courses</h3>
              </Col>
              <Col md={3} className="d-flex justify-content-end pe-5">
                <button
                  style={{
                    maxHeight: "6vh",
                    borderColor: "#5E36F7",
                    color: "#5E36F7",
                  }}
                  onClick={() =>
                    navigate(
                      "/teacher/dashboard/pages/course-management/add-course"
                    )
                  }
                  className="py-1 fw-bold px-4 rounded-5"
                >
                  <FaPlus color="#5E36F7" className=" fs-5 me-2" />
                  Add Course
                </button>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} isActions={true} isReschedule={true} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} isActions={true} isReschedule={true} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} isActions={true} isReschedule={true} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} isActions={true} isReschedule={true} />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}

        {selectedOption === "2" && (
          <>
            <Row className="mt-5">
              <Col>
                <h3 className="mb-4">Approved Courses</h3>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} isActions={true} isReschedule={true} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} isActions={true} isReschedule={true} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} isActions={true} isReschedule={true} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} isActions={true} isReschedule={true} />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}

        {selectedOption === "3" && (
          <>
            <Row className="mt-5">
              <Col>
                <h3 className="mb-4">Active Classes</h3>
              </Col>
              <Col md={3} className="d-flex justify-content-end pe-5">
                <button
                  style={{
                    maxHeight: "6vh",
                    borderColor: "#5E36F7",
                    color: "#5E36F7",
                  }}
                  onClick={() =>
                    navigate(
                      "/teacher/dashboard/pages/course-management/add-subject"
                    )
                  }
                  className="py-1 fw-bold px-4 rounded-5"
                >
                  <FaPlus color="#5E36F7" className=" fs-5 me-2" />
                  New Subjects
                </button>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} isActions={true} classType="1-on-1" />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} isActions={true} classType="Group" />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} isActions={true} classType="1-on-1" />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} isActions={true} classType="Group" />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}

        {selectedOption === "4" && (
          <>
            <Row className="mt-5">
              <Col>
                <h3 className="mb-4">Active Courses</h3>
              </Col>
              <Col md={3} className="d-flex justify-content-end pe-5">
                <button
                  style={{
                    maxHeight: "6vh",
                    borderColor: "#5E36F7",
                    color: "#5E36F7",
                  }}
                  onClick={() =>
                    navigate(
                      "/teacher/dashboard/pages/course-management/add-course"
                    )
                  }
                  className="py-1 fw-bold px-4 rounded-5"
                >
                  <FaPlus color="#5E36F7" className=" fs-5 me-2" />
                  Add New
                </button>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} />
                </SwiperSlide>
              </Swiper>
              <Col
                md={12}
                className="d-flex align-items-center justify-content-end text-primary cursor-pointer"
              >
                <h6 className="text-bold text-decoration-underline">
                  View More
                </h6>
              </Col>
            </Row>
          </>
        )}

        {selectedOption === "5" && (
          <>
            <Row className="mt-4">
              <Col>
                <h3 className="mb-4">New Courses</h3>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}

        {selectedOption === "6" && (
          <>
            <Row className="mt-4">
              <Col>
                <h3 className="mb-4">Demo class</h3>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}

        {selectedOption === "7" && (
          <>
            <Row className="mt-4">
              <Col>
                <h3 className="mb-4">On-hold Courses</h3>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}

        {selectedOption === "8" && (
          <>
            <Row className="mt-4">
              <Col>
                <h3 className="mb-4">Removed Courses</h3>
              </Col>
            </Row>

            <Row className="position-relative pe-5">
              <Swiper
                slidesPerView={window.innerWidth > 768 ? 3 : 1}
                spaceBetween={20}
                modules={[Navigation, Autoplay, Pagination]}
                grabCursor={true}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                className="mySwiper pt-4 pb-5"
              >
                <SwiperSlide>
                  <Cards img={c1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Cards img={c4} />
                </SwiperSlide>
              </Swiper>
            </Row>
          </>
        )}
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .input_parent {
    border: 2px solid #e5e5e5;
    width: fit-content;
    border-radius: 100px;

    input {
      width: 350px;
      background: transparent;
    }

    button {
      background: var(--color-primary);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: none;
    }
  }
`;

export default CourcesPage;

const Cards = ({ img, isActions, isReschedule, classType }) => {
  const [editShow, setEditShow] = React.useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };
  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          onClick={() =>
            navigate(
              classType
                ? classType === "1-on-1"
                  ? "/teacher/course/142512001/1-on-1"
                  : "/teacher/course/142512001/group"
                : "/teacher/course/142512001"
            )
          }
          className="cource_img rounded-3 px-3"
          style={{ width: "90%" }}
          src={img}
          alt=""
        />

        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        {/* <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div> */}

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          {/* <h6 className="m-0 fw-semibold">Rs. 1500.00</h6> */}
        </div>
        {/* <h6 className="mt-3 px-3" onClick={() => navigate('/teacher/course/142512001')}>course id : #00142512001</h6> */}
        <h5 className="mt-3 px-3">Course Name</h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
          </div>
          <p className="m-0 text-black">Robert Fox</p>

          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        <div className="d-flex px-2 align-items-center  justify-content-between mt-2">
          <div className="d-flex flex-wrap gap-3">
            {/* {age && ( */}
            <div
              className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
              style={{ width: "fit-content" }}
            >
              {/* {age} */} 17+
            </div>
            {/* )} */}
            {/* {type && ( */}
            <div
              className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
              style={{ width: "fit-content" }}
            >
              {classType ? classType : "1-on-1"}
            </div>
            {/* )} */}
            {/* {type2 && ( */}
            <div
              className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
              style={{ width: "fit-content" }}
            >
              {/* {type2} */} Academic
            </div>
            {/* )} */}
            {/* {type3 && ( */}
            <div
              className="px-3 py-1 border rounded-5 cursor-pointer"
              style={{ width: "fit-content" }}
            >
              {/* {type3} */} short course
            </div>
            {/* )}
            {board && ( */}
            <div
              className="px-3 py-1 border rounded-5 cursor-pointer"
              style={{ width: "fit-content" }}
            >
              {/* {board} */}CBSE
            </div>
            {/* )} */}
            {/* {grade && ( */}
            <div
              className="px-3 py-1 border rounded-5 cursor-pointer"
              style={{ width: "fit-content" }}
            >
              {/* {grade} */} A+
            </div>
            {/* )} */}

            <div
              className="px-3 py-1 border rounded-5 cursor-pointer"
              style={{ width: "fit-content" }}
            >
              {/* {grade} */} 250+ students
            </div>
          </div>
        </div>

        {isActions && (
          <div className="py-2 px-3 mt-3 border border-0 border-top d-flex align-items-center justify-content-end">
            <div
              onClick={() =>
                navigate("/teacher/dashboard/pages/class/edit-class-details")
              }
              className="parent_accept  ms-4 d-flex align-items-center justify-content-center"
            >
              <MdEdit className="fs-4" />
            </div>
            {isReschedule && (
              <div
                onClick={handleShow}
                style={{ backgroundColor: "#7D88F4" }}
                className="parent_accept ms-4 d-flex align-items-center justify-content-center"
              >
                <FaCalendarAlt color="#fff" size={18} className="fs-4" />
              </div>
            )}
            <div className="parent_decline  ms-4 d-flex align-items-center justify-content-center">
              <MdOutlineDeleteOutline className="fs-4" />
            </div>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          {/* <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p> */}
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          {/* <p className="m-0"> */}
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
          {/* </p> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Form onSubmit={HandleSubmit}>
          <Row className="p-3">
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Date:</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Time:</Form.Label>
                <Form.Control type="time" />
              </Form.Group>
            </Col>

            <Col
              md={12}
              className="mt-4 d-flex align-items-center justify-content-end"
            >
              <button className="cancel_btn" onClick={handleClose}>
                Cancel
              </button>
              <button className="reschedule_btn ms-4" type="submit">
                Reschedule
              </button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
