import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Form, Row, Col, Dropdown, Button, Modal } from "react-bootstrap";

const EditNext = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const [qualifications, setQualifications] = useState([
    { level: "", degreeName: "", passingYear: "", instituteName: "" },
  ]);

  const handleAddQualification = () => {
    setQualifications([
      ...qualifications,
      { level: "", degreeName: "", passingYear: "", instituteName: "" },
    ]);
  };

  const handleRemoveQualification = (index) => {
    const newQualifications = qualifications.filter((_, i) => i !== index);
    setQualifications(newQualifications);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newQualifications = qualifications.map((qualification, i) =>
      i === index ? { ...qualification, [name]: value } : qualification
    );
    setQualifications(newQualifications);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission logic here
  //   console.log('Submitted qualifications:', qualifications);
  // };

  const [subjects, setSubjects] = useState([
    { grade: "", board: "", subject: "" },
  ]);

  const [achievements, setAchievements] = useState([
    { achievement: "", certificate: null },
  ]);

  const [certificates, setCertificates] = useState([{ certificate: null }]);

  const handleAddSubject = () => {
    setSubjects([...subjects, { grade: "", board: "", subject: "" }]);
  };

  const handleAddCertificate = () => {
    setCertificates([...certificates, { certificate: null }]);
  };

  const handleAddAchievement = () => {
    setAchievements([...achievements, { achievement: "", certificate: null }]);
  };

  const handleRemoveSubject = (index) => {
    const newSubjects = subjects.filter((_, i) => i !== index);
    setSubjects(newSubjects);
  };

  const handleRemoveCertificate = (index) => {
    const newCertificates = certificates.filter((_, i) => i !== index);
    setCertificates(newCertificates);
  };

  const handleRemoveAchievement = (index) => {
    const newAchievements = achievements.filter((_, i) => i !== index);
    setAchievements(newAchievements);
  };

  const handleChangeSubject = (index, event) => {
    const { name, value } = event.target;
    const newSubjects = subjects.map((subject, i) =>
      i === index ? { ...subject, [name]: value } : subject
    );
    setSubjects(newSubjects);
  };

  const [schools, setSchools] = useState([
    {
      schoolName: "",
      subject: "",
      grade: "",
      board: "",
      year: "",
      designation: "",
      certificate: null,
    },
  ]);

  const handleAddSchool = () => {
    setSchools([
      ...schools,
      {
        schoolName: "",
        subject: "",
        grade: "",
        board: "",
        year: "",
        designation: "",
        certificate: null,
      },
    ]);
  };

  const handleRemoveSchool = (index) => {
    const newSchools = schools.filter((_, i) => i !== index);
    setSchools(newSchools);
  };

  const handleChangeSchool = (index, event) => {
    const { name, value, files } = event.target;
    const newSchools = schools.map((school, i) =>
      i === index ? { ...school, [name]: files ? files[0] : value } : school
    );
    setSchools(newSchools);
  };

  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [viewButtonVisible, setViewButtonVisible] = useState(false);

  const handlePreviewImage = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setViewButtonVisible(true);
    }
  };
  return (
    <TeacherLayout>
      <h3>Edit Profile</h3>

      <div className="container mt-4 bg-white rounded-5 shadow py-4 px-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {qualifications.map((qualification, index) => (
            <div key={index} className="mb-4 position-relative">
              <h6>Qualification</h6>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId={`qualification-level-${index}`}>
                    <Form.Label>Level</Form.Label>
                    <Form.Control
                      as="select"
                      name="level"
                      value={qualification.level}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="">Select Level</option>
                      <option value="Masters">Masters</option>
                      <option value="Masters">Bachelors</option>
                      <option value="Diploma">Diploma</option>
                      <option value="PhD">PhD</option>
                      <option value="PhD">Degree</option>
                      <option value="PhD">HSC</option>
                      <option value="PhD">SSC</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`degree-name-${index}`}>
                    <Form.Label>Degree Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="degreeName"
                      value={qualification.degreeName}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId={`passing-year-${index}`}>
                    <Form.Label>Passing Year</Form.Label>
                    <Form.Control
                      type="date"
                      name="passingYear"
                      value={qualification.passingYear}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`institute-name-${index}`}>
                    <Form.Label>Institute Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="instituteName"
                      value={qualification.instituteName}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="fw-semibold">Percentage </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Percentage"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label className="fw-semibold">
                      Upload Certificate{" "}
                    </Form.Label>

                    <Form.Control type="file" required />
                    <Form.Label
                      onClick={handlePreviewImage}
                      className="text-primary cursor-pointer mt-2"
                    >
                      view Image
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <button
                onClick={() => handleRemoveQualification(index)}
                className="btn btn-sm btn-danger"
                type="button"
              >
                Remove
              </button>
            </div>
          ))}

          <Button size="sm" variant="primary" onClick={handleAddQualification}>
            Add Qualification
          </Button>
          <hr />
          {subjects.map((subject, index) => (
            <div key={index} className="mb-4">
              <h6>Grades You Can Teach</h6>
              <Row className="align-items-center mb-3">
                <Col>
                  <Form.Group controlId={`grade-${index}`}>
                    <Form.Label>Grade</Form.Label>
                    <Form.Control
                      as="select"
                      name="grade"
                      value={subject.grade}
                      onChange={(e) => handleChangeSubject(index, e)}
                    >
                      <option value="">Select Grade</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="F">F</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`board-${index}`}>
                    <Form.Label>Board</Form.Label>
                    <Form.Control
                      as="select"
                      name="board"
                      value={subject.board}
                      onChange={(e) => handleChangeSubject(index, e)}
                    >
                      <option value="">Select Board</option>
                      <option value="CBSE">CBSE</option>
                      <option value="ICSE">ICSE</option>
                      <option value="State Board">State Board</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`subject-${index}`}>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      name="subject"
                      value={subject.subject}
                      onChange={(e) => handleChangeSubject(index, e)}
                    >
                      <option value="">Select Subject</option>
                      <option value="Math">Math</option>
                      <option value="Science">Science</option>
                      <option value="History">History</option>
                      <option value="Geography">Geography</option>
                      <option value="English">English</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    className="mt-4"
                    variant="danger"
                    onClick={() => handleRemoveSubject(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button size="sm" variant="primary" onClick={handleAddSubject}>
            Add Subject
          </Button>

          <hr />

          {schools.map((school, index) => (
            <div key={index} className="mb-4">
              <h6>Schools you have taught</h6>
              <Row className="align-items-center mb-3 mt-3">
                <Col md={4}>
                  <Form.Group controlId={`school-name-${index}`}>
                    <Form.Label>School Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="schoolName"
                      placeholder="Enter School Name..."
                      value={school.schoolName}
                      onChange={(e) => handleChangeSchool(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`school-name-${index}`}>
                    <Form.Label>Your Designation</Form.Label>
                    <Form.Control
                      type="text"
                      name="designation"
                      placeholder="Enter designation..."
                      value={school.designation}
                      onChange={(e) => handleChangeSchool(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`subject-${index}`}>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      name="subject"
                      value={school.subject}
                      onChange={(e) => handleChangeSchool(index, e)}
                    >
                      <option value="">Select Subject</option>
                      <option value="Math">Math</option>
                      <option value="Science">Science</option>
                      <option value="History">History</option>
                      <option value="Geography">Geography</option>
                      <option value="English">English</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col>
                  <Form.Group controlId={`grade-${index}`}>
                    <Form.Label>Grade</Form.Label>
                    <Form.Control
                      as="select"
                      name="grade"
                      value={school.grade}
                      onChange={(e) => handleChangeSchool(index, e)}
                    >
                      <option value="">Select Grade</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="F">F</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`board-${index}`}>
                    <Form.Label>Board</Form.Label>
                    <Form.Control
                      as="select"
                      name="board"
                      value={school.board}
                      onChange={(e) => handleChangeSchool(index, e)}
                    >
                      <option value="">Select Board</option>
                      <option value="CBSE">CBSE</option>
                      <option value="ICSE">ICSE</option>
                      <option value="State Board">State Board</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col md={4}>
                  <Form.Group controlId={`year-${index}`}>
                    <Form.Label>Start Year</Form.Label>
                    <Form.Control
                      type="text"
                      name="year"
                      placeholder="2020-2024"
                      value={school.year}
                      onChange={(e) => handleChangeSchool(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-4">
                  <Form.Group controlId={`year-${index}`}>
                    <Form.Label>End Year</Form.Label>
                    <Form.Control
                      type="text"
                      name="year"
                      placeholder="2020-2024"
                      value={school.year}
                      onChange={(e) => handleChangeSchool(index, e)}
                    />

                    {/* currently working */}
                    <Form.Check
                      className="mt-1"
                      type="checkbox"
                      label="Currently working"
                      name="year"
                      value={school.year}
                      onChange={(e) => handleChangeSchool(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`certificate-${index}`}>
                    <Form.Label>Certificate</Form.Label>
                    <Form.Control
                      type="file"
                      name="certificate"
                      onChange={handleImageChange}
                    />
                    <Form.Label
                      onClick={handlePreviewImage}
                      className="text-primary cursor-pointer mt-2"
                    >
                      view Image
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    className="mt-4"
                    variant="danger"
                    onClick={() => handleRemoveSchool(index)}
                  >
                    Remove
                  </Button>
                </Col>
                <Col xs="auto">
                  {viewButtonVisible && (
                    <Button
                      className="btn btn-sm btn-primary mt-4"
                      onClick={handlePreviewImage}
                    >
                      View
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ))}
          <Button size="sm" variant="primary" onClick={handleAddSchool}>
            Add School
          </Button>

          <hr />

          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="12"
              className="mt-3"
              controlId="validationCustom03"
            >
              <Form.Label className="fw-semibold">
                {" "}
                Languages you are comfortable with
              </Form.Label>
              <Dropdown className="d-flex align-items-center">
                <Dropdown.Toggle
                  style={{
                    background: "transparent",
                    color: "black",
                  }}
                  className="w-100 border text-start d-flex justify-content-between align-items-center"
                >
                  Select
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ width: 230, position: "absolute" }}
                  className="px-3"
                >
                  <div className="d-flex align-items-center flex-shrink-0">
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>Hindi</label>
                  </div>
                  <div className="d-flex align-items-center flex-shrink-0 mt-3">
                    <input type="checkbox" className="me-2 filter_check" />
                    <label>English</label>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Row>

          <>
            {achievements.map((achievement, index) => (
              <Row key={index} className="mb-3">
                <Form.Group
                  as={Col}
                  md="6"
                  className="mt-3"
                  controlId={`validationCustom03_${index}`}
                >
                  <Form.Label className="fw-semibold">
                    Awards/Special achievement {index + 1}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Awards/Special achievement"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  className="mt-3"
                  controlId={`validationCustom03_certificate_${index}`}
                >
                  <Form.Label className="fw-semibold">
                    Certificate For Awards/Special achievement {index + 1}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Awards/Special achievement"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid input.
                  </Form.Control.Feedback>
                </Form.Group>
                {index > 0 && (
                  <Col md={3} className="d-flex mt-3 align-items-center ">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRemoveAchievement(index)}
                    >
                      Remove Achievement
                    </button>
                  </Col>
                )}
              </Row>
            ))}
            <Col md={4} className="d-flex mt-2 align-items-center ">
              <button
                className="btn btn-sm btn-primary"
                onClick={handleAddAchievement}
              >
                Add One More Achievement
              </button>
            </Col>
          </>

          <Row className="mb-3">
            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Facebook link</Form.Label>
              <Form.Control type="text" placeholder="Facebook link" />
            </Form.Group>

            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Linkedin link</Form.Label>
              <Form.Control type="text" placeholder="Linkedin link" />
            </Form.Group>

            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Youtube link</Form.Label>
              <Form.Control type="text" placeholder="Youtube link" />
            </Form.Group>

            <Form.Group as={Col} md="3" className="mt-3">
              <Form.Label className="fw-semibold">Instagram link</Form.Label>
              <Form.Control type="text" placeholder="Instagram link" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {certificates.map((certificate, index) => (
              <>
                <Col md={6} key={index + certificates.length}>
                  <Form.Group className="mt-3 w-100">
                    <Form.Label className="fw-semibold">
                      Upload Certificate {index + 1}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Certificate Title"
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  key={index}
                  className="d-flex align-items-center justify-content-between"
                >
                  <Form.Group className="mt-3 w-100">
                    <Form.Label className="fw-semibold">
                      Upload Certificate {index + 1}
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>

                  {index > 0 && (
                    <button
                      className="btn btn-sm btn-danger ms-2 mt-5"
                      onClick={() => handleRemoveCertificate(index)}
                    >
                      Remove
                    </button>
                  )}
                </Col>
              </>
            ))}

            <Col md={4}>
              <button
                className="btn btn-sm btn-primary mt-5"
                onClick={handleAddCertificate}
              >
                Add One More Certificate
              </button>
            </Col>
          </Row>

          <button
            className="border-0 bg-yellow px-4 py-2 rounded-3 mt-3 text-white"
            type="submit"
          >
            Submit
          </button>
        </Form>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              zIndex: 10,
              background: "#000",
              color: "#fff",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            Edit
          </button>
          <img
            src="https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
            alt="preview"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </TeacherLayout>
  );
};

export default EditNext;
